import React from 'react';
import translate from '../../../utils/translate';
import '../cart.scss';

const CartWarningModal = ({ onClearCart }) => (
  <div
    className="modal fade cartModal"
    id="exampleModal"
    tabIndex="1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h3
            className="modal-title"
            id="exampleModalLabel">
            {translate(
              'All items from the shopping list will be deleted, do you want to continue?'
            )}
          </h3>
        </div>
        <div className="modal-content--buttons">
          <button
            type="button"
            className="btn aic-small-btn-outline"
            data-bs-dismiss="modal">
            {translate('Back to shopping list')}
          </button>
          <button
            data-testid="clearCart2"
            type="button"
            className="btn aic-small-btn-outline clear-cart"
            data-bs-dismiss="modal"
            onClick={onClearCart}>
            {translate('Clear shopping list')}
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default CartWarningModal;
