import './privacyPolicy.scss';

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <h1>
        Privacy Policy <span className="text-orange">aicON</span>
      </h1>
      <p>
        As we care about your privacy and security of the personal data, that
        you decided to entrust us, we would like to encourage you to read the
        following information.
      </p>
      <p>
        This Privacy Policy pertains to personal data processing associated with
        using aicON system.
      </p>

      <section>
        <p>
          Access to aicON system may be obtained via both aicON application and
          the account created on the website:{' '}
          <a className="text-orange" href="https://aicon.myaic.eu">
            aicon.myaic.eu
          </a>
          . To learn more about terms and conditions of using aicON system,
          please go to settings either in aicON application or your account on
          the website:{' '}
          <a className="text-orange" href="https://aicon.myaic.eu">
            aicon.myaic.eu
          </a>
          .
        </p>
      </section>

      <section>
        <h2>About us</h2>
        <p>
          AIC Europe B.V. with a registered office in Weert, Netherlands is
          responsible for aicON system operation and is a data controller of all
          of the personal data processed via aicON system or in connection with
          aicON system.
        </p>
        <p>AIC Europe B.V. is a part of AIC global group of companies.</p>
        <p>
          In this document words such as: we, our or us shall refer to AIC
          Europe B.V., unless explicitly stated otherwise.
        </p>
      </section>

      <section>
        <h3>Contact Details</h3>
        <p>You may contact us using the below contact details: </p>
        <address>
          AIC Europe B.V.
          <br />
          Graafschap Hornelaan 163A
          <br />
          NL-6001 AC Weert, The Netherlands
          <br />
          E-mail:{' '}
          <a className="text-orange" href="mailto:info@myaic.eu">
            info@myaic.eu
          </a>
        </address>
      </section>

      <section>
        <h2>
          The purposes of the processing and legal basis for the processing
        </h2>
        <p>
          We process your personal data only if we have legal grounds for doing
          so in accordance with European data protection law. The personal data
          will be used:
        </p>
        <ul>
          <li>
            <b>with your consent</b> - in some cases, the processing of your
            personal data will require your consent, e.g. if you provide your
            telephone number to speed up contact in connection with the removal
            of a defect in the AIC device or in case of installing cookies and
            similar technologies via website: aicon.myaic.eu. If we process your
            personal data based on consent, you have the right to withdraw
            consent to the processing of your data at any time. Withdrawal of
            consent does not, however, affect the lawfulness of processing based
            on consent obtained prior to its withdrawal.
          </li>
          <li>
            <b>
              to the extent necessary to conclude and perform contracts with you
            </b>{' '}
            for the provision of electronic services via the aicON application
            or the website aicon.myaic.eu consisting of enabling account
            registration and the use of functionalities of the aicON system.
          </li>
          <li>
            <b>
              for purposes arising from our or someone else's legitimate
              interests.
            </b>{' '}
            We rely on this basis to manage and improve our relationships with
            you, including primarily to communicate with you on matters related
            to the aicON system, as well as to provide support for AIC devices,
            including problem solving, repairs, maintenance, etc., to protect
            our or others rights, and for the purpose of detecting, preventing
            or otherwise resolving fraud issues, and to ensure data security.
          </li>
        </ul>
        <p>
          <u>Right to object: </u>
          When we process your data based on our or someone else's legitimate
          interest, subject to applicable laws, you have the right to object at
          any time to the processing of your personal data for reasons related
          to your particular situation.
        </p>
      </section>

      <section>
        <h2>Personal data in materials provided by you via the aicON system</h2>
        <p>
          Please note that you shall not provide us with more information and
          data than is necessary to use of the aicON system, including that you
          shall not provide the image of yourself or another person on
          photographs documenting defects or their removal in the AIC device.
        </p>
      </section>

      <section>
        <h2>Data transfer and sharing</h2>
        <p>Data transfer and sharing</p>
        <ul>
          <li>
            these data are reasonably required for the purpose for which you
            provided them, i.e. primarily to enable the use of the functionality
            of the aicON system and contact you, i.e. until the contract for the
            provision of electronic services through the aicON system is
            terminated
          </li>
          <li>
            these data is reasonably required to protect our rights (as a rule,
            this will be the length of the limitation period for claims or
            liability)
          </li>
          <li>
            your consent will be withdrawn if we rely on such consent when
            processing your data, unless we can process the same data on a
            different legal basis for other purposes.
          </li>
        </ul>
        <p>
          In the event that personal data is used for more than one purpose, it
          will be stored as long as required to achieve the purpose requiring
          the longest storage period.
        </p>
      </section>

      <section>
        <h2>Push notifications</h2>
        <p>
          The aicON application can send users the following push notifications:
        </p>
        <ol type="a">
          <li>
            General information about the application sent to all users who have
            downloaded the application, including in particular the operation of
            the application and possible disruptions in its operation
          </li>
          <li>
            Messages dedicated to users, in particular regarding AIC devices
            assigned to users, e.g. about the date of the next warranty
            inspection of the AIC device{' '}
          </li>
          <li>Changes in application’s functionalities</li>
          <li>
            Changes in the content of the aicON system Terms and Conditions and
            this Privacy Policy
          </li>
          <li>
            Communication regarding suspension of access to the application and
            termination of the contract for the provision of electronic services
            through the application.
          </li>
        </ol>
        <p>
          Users can select in the aicON application settings whether they want
          to receive push notifications. To disable push notifications sent via
          the aicON application, the user can use the mobile device's
          application management tools. Additional information can be found in
          the user's manual for the mobile device.
        </p>
      </section>

      <section>
        <h2>Cookies and other technologies used by aicon.myaic.eu</h2>
        <p>
          If you agree, we will install the following cookies or similar
          technologies:
        </p>

        <h4>Authentication Cookies</h4>
        <p>
          When you select the "Authentication" option in the settings for
          cookies and similar technologies on our website and confirm by
          clicking “Confirm and close” or when logging into the aicON system you
          check the "Remember me" box, you will not have to log into the aicON
          system every time you close your browser. File persistence is 12
          hours.
        </p>

        <h4>Preferential Cookies</h4>
        <p>
          When you select the "Preferences" option in the settings for cookies
          and similar technologies on our website and confirm by clicking
          “Confirm and close”, local storage files (files similar to cookies)
          will enable aicon.myaic.eu to remember your preferences regarding page
          settings, including primarily language settings and settings for the
          selected theme. These files will be stored on your device until you
          decide to clear your browsing data in your browser settings.
        </p>

        <p>
          The services of external providers are not used to use cookies and
          similar technologies.
        </p>

        <p>
          To change cookie settings, you can enter cookie settings and similar
          technologies on our website at any time, change these settings and
          confirm them by clicking "Confirm and close". You may need to refresh
          the page for the changes to take effect.
        </p>

        <p>
          You can also change the settings of cookies and similar technologies
          by changing the settings of your browser. For more information on
          cookies, we suggest visiting{' '}
          <a href="http://www.aboutcookies.org">www.aboutcookies.org</a> or{' '}
          <a href="http://www.allaboutcookies.org">www.allaboutcookies.org</a>.
        </p>
      </section>

      <section>
        <h2>Your rights related to processing of your personal data</h2>
        <p>
          You have the right to request access to the content of personal data,
          request a copy thereof, as well as the right to request rectification,
          if necessary. You have, in certain circumstances, the right to request
          restriction of processing and the right to request their removal. When
          we process your personal data based on our or someone else's
          legitimate interest, you have the right to object to the processing of
          personal data due to your particular situation. In certain
          circumstances (in the event that we process your data on the basis of
          consent or contract) you may also request your portability right to be
          exercised.
        </p>
        <p>
          If you consent to use of your personal data by us, you have the right
          to withdraw your consent at any time. Withdrawal of consent does not
          affect the lawfulness of the processing which was carried out on the
          basis of consent obtained before its withdrawal.
        </p>
        <p>
          You also have the right to lodge a complaint with the relevant
          supervisory authority. If you have reservations about the way we
          process data, we hope that you will first contact us to clarify the
          situation.
        </p>
        <p>
          If you have any questions or concerns regarding the processing of
          personal data by us or you would like to exercise any of your rights
          related to personal data, please contact us using the contact details
          in the section "About us".
        </p>
      </section>

      <section>
        <h2>
          Information on the voluntary nature / necessity to provide data and
          the consequences of not providing it
        </h2>
        <p>
          Information that is required to register an account in the aicON
          system and use its functionalities has been marked in a special way,
          e.g. with an asterisk. Failure to provide such information will result
          in the inability to register the account in the aicON system and use
          its functionalities. Providing other information is voluntary.
        </p>
      </section>

      <section>
        <h2>Updating this Privacy Policy</h2>
        <p>
          We reserve the right to update and make changes to the content of this
          Privacy Policy. In the event of changes that will have a significant
          impact on the nature of the processing of collected personal data, we
          will notify you in advance.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
