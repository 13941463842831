import React from 'react';

const WarrantyInspection = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.8767 7.64044L15.1273 5.89106C15.5323 5.32013 15.75 4.63725 15.75 3.9375C15.75 2.08631 14.2267 0.5625 12.375 0.5625C10.5238 0.5625 9 2.08631 9 3.9375C9 5.78925 10.5238 7.3125 12.375 7.3125C13.0747 7.3125 13.7576 7.09481 14.3286 6.68981L16.0779 8.43919L16.8767 7.64044ZM12.3767 6.18919C11.1426 6.18919 10.1267 5.17331 10.1267 3.93919C10.1267 2.70506 11.1426 1.68919 12.3767 1.68919C13.6108 1.68919 14.6267 2.70506 14.6267 3.93919C14.6267 5.17331 13.6108 6.18919 12.3767 6.18919Z"
        fill="#32343A"
      />
      <path
        d="M12.4121 8.43592H11.1043C10.3826 9.46417 9.189 10.1167 7.88063 10.1167C5.72231 10.1167 3.9465 8.34086 3.9465 6.18254C3.9465 4.02423 5.72231 2.24842 7.88063 2.24842C7.992 2.24842 8.10338 2.25292 8.21419 2.26248V1.13242C8.09944 1.12454 7.98469 1.12061 7.86938 1.12061C5.09569 1.12061 2.8125 3.40323 2.8125 6.17748C2.8125 7.61692 3.42731 8.98942 4.50056 9.94848V16.8734L7.87556 14.6234L11.2506 16.8734V9.95917C11.7338 9.52829 12.1275 9.01023 12.4121 8.43592ZM10.1256 14.7714L7.87556 13.2712L5.62556 14.7714V10.714C7.03969 11.4245 8.71031 11.4262 10.1256 10.7174V14.7714Z"
        fill="#32343A"
      />
    </svg>
  );
};

export default WarrantyInspection;
