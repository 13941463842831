import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ShelterMap from './MapWithMarker';
import {
  changeColumnVisibility,
  getActionList,
  getDepartments,
  getWarehouses,
  mapHeightSize,
  mapLoaded,
  mapPosition,
  saveFilterAndGetProducts
} from 'actions/actions.jsx';
import './mapstyles.scss';
import translate from 'utils/translate';
import DashboardProductRow from './DashboardProductRow.jsx';

import 'react-virtualized/styles.css';
import { AutoSizer, Grid } from 'react-virtualized';
import DashboardTooltip from './dashboardTooltip';

// spinner
import Spinner from '../../../utils/Spinner/Spinner';

// svg
import { ReactComponent as SettingsProducts } from '../../../assets/svg/settingsProducts.svg';
import { ReactComponent as SettingsMap } from '../../../assets/svg/settingsMap.svg';
import { ReactComponent as SettingsEye } from '../../../assets/svg/settingsEye.svg';
import exportDataToXLSX from '../../../utils/exportDataToXLSX';

const MyProductsMap = () => {
  // useState
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [scrollToIndex, setscrollToIndex] = useState(0);
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const [openFilterName, setOpenFilterName] = useState(''); //filterRow.longName
  const [mapPreviousSize, setMapPreviousSize] = useState(1); // map previous size when open filter
  const [dashboardProducts, setDashboardProducts] = useState();

  // redux state
  const dispatch = useDispatch();
  const dashboardProductsWithTestProducts = useSelector(
    (state) => state.dashboard.dashboardProducts.allProducts
  );
  const dashboardColumns = useSelector(
    (state) => state.dashboard.dashboardColumns
  );
  const getMapPosition = useSelector((state) => state.dashboard.mapPosition);
  const isMapLoaded = useSelector((state) => state.dashboard.mapLoaded);
  const getMapHeightSize = useSelector(
    (state) => state.dashboard.mapHeightSize
  );
  const loadingDashboardProducts = useSelector(
    (state) => state.dashboard.loadingDashboardProducts
  );

  // remove test products
  useEffect(() => {
    const allProductsWithoutTestProducts =
      dashboardProductsWithTestProducts?.filter((product) =>
        JSON.parse(localStorage.getItem('isTestProductsShown'))
          ? true
          : product.test === false
      );
    setDashboardProducts(allProductsWithoutTestProducts);
  }, [dashboardProductsWithTestProducts]);

  // mount component
  useEffect(() => {
    if (!dashboardProductsWithTestProducts) saveFilterAndGetProducts({});
    getDepartments();
    getActionList();
    getWarehouses();
    setInitialColumnVisibility();
    return () => mapLoaded(false); // set map loaded false to redux when dismount component
  }, []);

  // set initial column visibility from local storage to redux
  const setInitialColumnVisibility = () => {
    let columnVisibility = localStorage.getItem('columnVisibility');
    let parsedData;
    try {
      parsedData = JSON.parse(columnVisibility);
    } catch {
      parsedData = null;
    }

    if (!columnVisibility || !parsedData) {
      // changed format in which we save visible columns in local storage. second check checks if it's the correct format, if not it should override the old one
      columnVisibility = dashboardColumns.map((i) => {
        return { name: i.longName, visible: i.isVisible };
      });
      localStorage.setItem(
        'columnVisibility',
        JSON.stringify(columnVisibility)
      );
    } else {
      parsedData.forEach((i) => {
        changeColumnVisibility({
          name: i.name,
          isChecked: i.visible
        });
      });
    }
  };

  // set map position
  useEffect(() => {
    isMapLoaded &&
      getMapPosition &&
      mapChild.current.centerOnMap(getMapPosition); // go to product on map
  }, [getMapPosition, isMapLoaded]);

  // scroll to product if click pin
  useEffect(() => {
    if (getMapPosition && dashboardProducts) {
      for (let i = 0; i < dashboardProducts.length; i++) {
        if (
          dashboardProducts[i].serial_number === getMapPosition.serial_number
        ) {
          // in list are DUPLICATES
          setscrollToIndex(i);
          return;
        }
      }
    }
  }, [getMapPosition, dashboardProducts, isMapLoaded]);

  // refs
  const mapChild = useRef();
  const mapContainer = useRef();
  const filterContainer = useRef();

  // call when click check all or none products
  const toggleAllProducts = (flag) =>
    flag ? setSelectedProducts(dashboardProducts) : setSelectedProducts([]);

  // ------------------ topPart ------------------

  // map resize height
  useEffect(() => {
    const navBarHeight =
      document.querySelector('.navbar-container').offsetHeight ?? 80;
    const topPartSettingsCloseHeight =
      document.querySelector('.topPartSettings__containerButtons')
        .offsetHeight ?? 64;
    const minimumMiddlePartProductsFiltersHeight =
      document.querySelector('.th').offsetHeight ?? 64;
    const minimumMiddlePartProductsHeight =
      document.querySelector('.tr').offsetHeight ?? 64;
    const bottomPartHeight =
      document.querySelector('.bottomPart').offsetHeight ?? 70;
    const countMaxheightForMapDevidedBy3 =
      (window.innerHeight -
        navBarHeight -
        topPartSettingsCloseHeight -
        minimumMiddlePartProductsFiltersHeight -
        minimumMiddlePartProductsHeight -
        bottomPartHeight) /
      3;
    mapContainer.current.style.height =
      getMapHeightSize * countMaxheightForMapDevidedBy3 + 'px'; //set map height
  }, [getMapHeightSize]);

  // call when click marker => in list go to product
  const handlePinClick = (marker, event) => {
    if (marker) {
      mapPosition(marker);
    } else {
      mapPosition(undefined);
      setscrollToIndex(1);
    }
  };

  // settings filter visibility
  const setIsSettingsVisibleHandler = () => {
    // close filters
    if (isSettingsVisible) {
      setIsSettingsVisible(false); // close middle filter if open
      mapHeightSize(mapPreviousSize); // set Map size like before open filter

      // open filters
    } else {
      // set current map size before change
      if (!openFilterName) {
        setMapPreviousSize(getMapHeightSize);
      }

      // check map size and resize if needed
      if (getMapHeightSize > 0) {
        mapHeightSize(0);
      }

      setOpenFilterName(''); // close product filter
      setIsSettingsVisible(true);
    }
  };

  // call when check column filter
  const filterColumnHandler = (item, isChecked) => {
    // set in redux
    changeColumnVisibility({ name: item.longName, isChecked: isChecked });

    //set in local storage
    const columnVisibility = localStorage.getItem('columnVisibility');
    const parsedColumnVisibility = JSON.parse(columnVisibility);
    const indexOfCol = parsedColumnVisibility.findIndex(
      (col) => col.name === item.longName
    );
    parsedColumnVisibility[indexOfCol].visible = isChecked;
    localStorage.setItem(
      'columnVisibility',
      JSON.stringify(parsedColumnVisibility)
    );
  };

  // ------------------ middlePart ------------------

  // one row of list
  const renderRow = ({ columnIndex, key, rowIndex, style }) => {
    let clearedProducts = selectedProducts.length === 0;
    let selectedAllProducts =
      selectedProducts.length === dashboardProducts.length;
    return (
      <div key={key} style={style}>
        <DashboardProductRow
          product={dashboardProducts[rowIndex]}
          clearedProductsList={clearedProducts}
          selectedAllProducts={selectedAllProducts}
          selectedProduct={getMapPosition}
          selectProduct={(product) => dispatch(mapPosition(product))} // call when click product
          handleSelectProduct={handleSelectProduct}
        />
      </div>
    );
  };

  // call when check product
  const handleSelectProduct = (product, flag) => {
    let copySelectedProducts = [...selectedProducts];
    flag
      ? copySelectedProducts.push(product)
      : copySelectedProducts.pop(product);
    setSelectedProducts(copySelectedProducts);
  };

  // min-width of product table count
  const [minWidthOfProductsList, setMinWidthOfProductsList] =
    useState('1384px');
  useEffect(() => {
    let minWidth = 0;

    dashboardColumns.forEach((i) => {
      if (i.isVisible) {
        minWidth = minWidth + +i.minWidth.substring(0, i.minWidth.length - 2);
      }
    });
    setMinWidthOfProductsList(minWidth ? minWidth + 'px' : '1384px');
  }, [dashboardColumns]);

  // set filters window width according to view
  const [filterWindowWidth, setFilterWindowWidth] = useState(1384);
  const [filterWindowOffsetLeft, setFilterWindowOffsetLeft] = useState(0);
  useEffect(() => {
    setFilterWindowWidth(filterContainer.current.offsetWidth); //initial width
    window.addEventListener('resize', handleResizeWidth); // event listener for resize window

    const instance = filterContainer.current;
    instance.addEventListener('scroll', handleScrollWidth); // event listener for scroll window
    return () => {
      window.removeEventListener('resize', handleResizeWidth); // remove event listener for resize window
      instance.removeEventListener('scroll', handleScrollWidth); // remove event listener for scroll window
    };
  }, []);
  const handleResizeWidth = () =>
    setFilterWindowWidth(filterContainer.current.offsetWidth);
  const handleScrollWidth = () =>
    setFilterWindowOffsetLeft(filterContainer.current.scrollLeft);

  // set product filter visibility
  const setIsProductFilterOpenHandler = (data) => {
    // if product filter is close
    if (!openFilterName) {
      if (!isSettingsVisible) {
        setMapPreviousSize(getMapHeightSize); // set current map size before change
      }

      // resize map if needed
      if (getMapHeightSize > 0) {
        mapHeightSize(0);
      }
      setIsSettingsVisible(false); // close settings
    }

    setOpenFilterName(data);
  };

  // ------------------ bottomPart ------------------

  const exportToXLSX = () => {
    const header = [];
    dashboardColumns.forEach(
      (i) =>
        i.isVisible &&
        i.type !== 'checkbox' &&
        header.push(translate(i.longName))
    );

    const dataRows = selectedProducts.map((product) => {
      const productRow = [];
      dashboardColumns.forEach((column, index) => {
        if (column.isVisible) {
          switch (index) {
            case 1:
              productRow.push(product.status.name);
              break;
            case 2:
              productRow.push(
                product.product_type.family +
                  ' ' +
                  (product.product_type.subfamily ?? '')
              );
              break;
            case 3:
              productRow.push(product.product_type.value);
              break;
            case 4:
              productRow.push(product.serial_number);
              break;
            case 5:
              productRow.push(product.product_type.product_index);
              break;
            case 6:
              productRow.push(product.location);
              break;
            case 7:
              productRow.push(product.warranty_info.warranty_start_date);
              break;
            case 8:
              productRow.push(product.warranty_info.warranty_end_date);
              break;
            case 9:
              productRow.push(product.date_installed);
              break;
            case 10:
              productRow.push(product.date_launched);
              break;
            case 11:
              productRow.push(product.warranty_info.next_inspection_date);
              break;
            case 12:
              productRow.push(
                (product.service_support?.first_name ?? '') +
                  ' ' +
                  (product.service_support?.last_name ?? '')
              );
              break;
            case 13:
              productRow.push(product.last_action.action_date);
              break;
            case 14:
              productRow.push(product.supporting_department_code);
              break;
            default:
              return ' ';
          }
        }
      });

      console.log(productRow);
      return productRow;
    });

    const data = {
      headers: header,
      rows: dataRows
    };
    exportDataToXLSX(data, 'aicON_dashboard_products.xlsx');
  };

  return (
    <div className="myProductsMap">
      {/* ------------------ topPart => column visibility ------------------ */}
      <div className="topPartSettings">
        <div className="topPartSettings__containerButtons">
          <div className="topPartSettings__containerButtonsMap">
            <div
              onClick={() => mapHeightSize(1)}
              className={`topPartSettings__svgContainer ${
                getMapHeightSize && 'backgroundDark'
              }`}
            >
              <SettingsMap />
            </div>
            <div
              onClick={() => mapHeightSize(0)}
              className={`topPartSettings__svgContainerSmall ${
                getMapHeightSize === 0 && 'backgroundDark'
              }`}
            >
              <SettingsEye />
            </div>
            <div
              onClick={() => mapHeightSize(1)}
              className={`topPartSettings__svgContainerSmall ${
                getMapHeightSize === 1 && 'backgroundDark'
              }`}
            >
              1
            </div>
            <div
              onClick={() => mapHeightSize(2)}
              className={`topPartSettings__svgContainerSmall ${
                getMapHeightSize === 2 && 'backgroundDark'
              }`}
            >
              2
            </div>
            <div
              onClick={() => mapHeightSize(3)}
              className={`topPartSettings__svgContainerSmall ${
                getMapHeightSize === 3 && 'backgroundDark'
              }`}
            >
              3
            </div>
          </div>
          <div>
            <div
              onClick={() => setIsSettingsVisibleHandler()}
              className={`topPartSettings__svgContainer ${
                isSettingsVisible && 'backgroundDark'
              }`}
            >
              <SettingsProducts />
            </div>
          </div>
        </div>

        <div
          className={`settings__filtersList ${
            isSettingsVisible && 'settings__filtersList_visible'
          }`}
        >
          <div
            className="settings__filtersList_container"
            data-cy="list_container-setting"
          >
            <p className="settings__filtersList_header ">
              {translate('Display and arrange data')}
            </p>
            <div className="hr">
              <hr />
            </div>
            <div className="settings__filtersList_container_items">
              {dashboardColumns.map((i) => {
                return (
                  <label
                    key={i.id}
                    className="settings__filtersList_label"
                    data-cy={i.longName}
                  >
                    <input
                      checked={i.isVisible}
                      onChange={(e) => filterColumnHandler(i, e.target.checked)}
                      className="settings__filtersList_input"
                      type="checkbox"
                      disabled={i.isDisabled ? 'disabled' : null}
                    />
                    {translate(i.longName)}
                  </label>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* ------------------ topPart => Map ------------------ */}
      <div className="topPartMap">
        <div ref={mapContainer} className="topPartMap__container">
          <ShelterMap
            pins={dashboardProducts || []}
            handlePinClick={handlePinClick}
            ref={mapChild}
          />
        </div>
      </div>

      {/* ------------------ middlePart => products ------------------ */}
      <div ref={filterContainer} className="middlePart">
        <div
          className="middlePart__container"
          style={{ minWidth: minWidthOfProductsList }}
        >
          {/* products header  */}
          <div className="middlePart__grid">
            <div className="tr th">
              {dashboardColumns.map(
                (i) =>
                  i.isVisible && (
                    <DashboardTooltip
                      key={i.id}
                      filterRow={i}
                      checked={selectedProducts}
                      handleChange={(e) => toggleAllProducts(e.target.checked)}
                      openFilterName={openFilterName}
                      setOpenFilterName={(data) =>
                        setIsProductFilterOpenHandler(data)
                      }
                      filterWindowWidth={filterWindowWidth}
                      filterWindowOffsetLeft={filterWindowOffsetLeft}
                      mapPreviousSize={mapPreviousSize}
                      allProducts={dashboardProducts}
                    />
                  )
              )}
            </div>
          </div>

          {/* products list  */}
          {dashboardProducts && !loadingDashboardProducts ? (
            <AutoSizer>
              {({ width, height }) => (
                <Grid
                  height={height - 64}
                  width={width}
                  columnWidth={width}
                  columnCount={1}
                  className="middlePart__grid"
                  overscanColumnCount={0}
                  scrollToRow={scrollToIndex}
                  overscanRowCount={10}
                  cellRenderer={(columnIndex, key, rowIndex, style) =>
                    renderRow(columnIndex, key, rowIndex, style)
                  }
                  rowHeight={64}
                  rowCount={dashboardProducts.length}
                  noRowsRenderer={() => <Spinner />}
                />
              )}
            </AutoSizer>
          ) : (
            <Spinner />
          )}
        </div>
      </div>

      {/* ------------------ bottomPart ------------------ */}
      <div className="bottomPart">
        <div className="bottomPart__right">
          <div>
            <button
              className="bottomPart__right_btn bottomPart__right_btn_dark"
              onClick={exportToXLSX}
            >{`${translate('Export')} ${selectedProducts.length} ${translate(
              'items'
            )}`}</button>
          </div>
          <div>
            <button
              className="bottomPart__right_btn"
              onClick={() => {
                toggleAllProducts(false);
              }}
            >
              {translate('Clear all selections')}
            </button>
          </div>
        </div>
        <p className="bottomPart__left">
          {dashboardProducts &&
            `${dashboardProducts.length} ${translate('items listed')}`}{' '}
        </p>
      </div>
    </div>
  );
};

export default MyProductsMap;
