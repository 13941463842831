import React, { useEffect, useState } from 'react';
import store from 'store';
import axios from 'axios';
import translate from 'utils/translate';
import './profile.scss';
import { DebounceInput } from 'react-debounce-input';
import PasswordDifficultyBar from '../PasswordDifficultyBar/PasswordDifficultyBar';
import { showError, togglePreferencesModal } from '../../actions/actions';

const ChangePassword = () => {
  const [passwordData, setPasswordData] = useState({
    ConfirmNewPassword: '',
    NewPassword: '',
    CurrentPassword: ''
  });
  const [currentPasswordEmpty, setCurrentPasswordEmpty] = useState(false);
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [newPasswordMatch, setNewPasswordMatch] = useState(true);
  const [newPasswordEmpty, setNewPasswordEmpty] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const handleChange = (event) => {
    setPasswordData({
      ...passwordData,
      [event.target.id]: event.target.value
    });
    setCurrentPasswordEmpty(false);
    setNewPasswordMatch(true);
    setNewPasswordEmpty(false);
    setIncorrectPassword(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!passwordData.CurrentPassword) {
      setCurrentPasswordEmpty(true);
    }

    if (passwordData.NewPassword !== passwordData.ConfirmNewPassword) {
      setNewPasswordMatch(false);
    }

    if (!passwordData.NewPassword) {
      setNewPasswordEmpty(true);
    }

    const formData = new FormData();
    for (let key in passwordData) {
      formData.append(key, passwordData[key]);
    }

    axios
      .post('/api/user/change_password/', formData)
      .then((response) => {
        togglePreferencesModal(false);
        store.dispatch({
          type: 'showMsg',
          value: { msg: translate('Saved'), type: 'success' }
        });
      })
      .catch((error) => {
        showError(error);
        if (error.response.status) {
          error.response.data.message === 'Wrong password'
            ? setIncorrectPassword(true)
            : setIncorrectPassword(false);
        }
      });
  };

  useEffect(() => {
    let timer = setTimeout(() => setIsVisible(true), 100);
    return () => clearTimeout(timer);
  });

  return (
    <form
      className={`apperance-form ${isVisible && 'apperance-form-show'}`}
      onSubmit={handleSubmit}>
      <div className="preferences-container-fieldset-password">
        <div>
          <fieldset className="preferences-fieldset preferences-fieldset-width">
            <legend className="preferences-section-title">
              {translate('Current password')}
            </legend>
            <input
              type="password"
              className="form-control my-input"
              value={passwordData.CurrentPassword}
              onChange={handleChange}
              id="CurrentPassword"
              placeholder={translate('Current password')}
              aria-label="current password"
              aria-describedby="button-addon2"
              autoComplete="off"
            />
          </fieldset>
          {currentPasswordEmpty && (
            <div className="preferences-container-fieldset-password--wrong-password">
              {translate('Enter current password.')}
            </div>
          )}
          {incorrectPassword && (
            <div className="preferences-container-fieldset-password--wrong-password">
              {translate('Enter correct password.')}
            </div>
          )}
        </div>

        <div>
          <fieldset className="preferences-fieldset preferences-fieldset-width">
            <legend className="preferences-section-title">
              {translate('New Password')}
            </legend>
            <DebounceInput
              type="password"
              className="form-control my-input"
              value={passwordData.NewPassword}
              onChange={handleChange}
              id="NewPassword"
              placeholder={translate('New Password')}
              aria-label="new password"
              aria-describedby="button-addon2"
              autoComplete="off"
            />
          </fieldset>
          <div className="px-2">
            <PasswordDifficultyBar pass={passwordData.NewPassword} />
          </div>
          {!newPasswordMatch && (
            <div className="preferences-container-fieldset-password--wrong-password">
              {translate('Passwords has to be identical.')}
            </div>
          )}
          {newPasswordEmpty && (
            <div className="preferences-container-fieldset-password--wrong-password">
              {translate('This field cannot be empty. ')}
            </div>
          )}
        </div>

        <div>
          <fieldset className="preferences-fieldset preferences-fieldset-width">
            <legend className="preferences-section-title">
              {translate('Confirm New Password')}
            </legend>
            <input
              type="password"
              className="form-control my-input"
              value={passwordData.ConfirmNewPassword}
              onChange={handleChange}
              id="ConfirmNewPassword"
              placeholder={translate('Confirm New Password')}
              aria-label="confirm new password"
              aria-describedby="button-addon2"
              autoComplete="off"
            />
          </fieldset>
        </div>
        <button
          className="preferences-btn"
          id=""
          type="submit">
          {translate('Change password')}
        </button>
      </div>
    </form>
  );
};

export default ChangePassword;
