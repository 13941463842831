import React from 'react';

const OkIcon = () => {
  return (
    <svg
      className="custom-aic-icon-stroke"
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.76489 4.47559L6.76489 8.47559L14.2649 0.975586"
        stroke="#32343A"
        strokeWidth="2"
      />
    </svg>
  );
};

export default OkIcon;
