import React, { Component } from 'react';
import history from './../../history';
import { connect } from 'react-redux';
import './newlogin.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sparepartsImage from 'assets/img/sparepartsBG.png';
import logotype from './logosquare.svg';
import {
  changeLanguageAndGetLegalDocuments,
  getDepartments,
  getLanguages,
  logout
} from '../../actions/actions';
import NewLogin from './NewLogin';
import NewResetPassword from './NewResetPassword';
import NewSetNewPassword from './NewSetNewPassword';
import { Route, Switch } from 'react-router-dom';
import ChangePinToPassword from './ChangePinToPassword';
import Register from './Register';

class LoginSwitch extends Component {
  constructor(props) {
    super(props);
    getDepartments();
    getLanguages();

    this.state = {
      title: window.location.pathname.includes('spareparts')
        ? 'Spareparts'
        : 'product_info',
      step: 'login-step'
    };
  }

  changeLanguage(language) {
    changeLanguageAndGetLegalDocuments(language);
  }

  onLoginImageClick() {
    if (this.props.user) {
      if (this.props.user.need_to_change_password) {
        logout();
        history.push('/dashboard/login');
      } else {
        history.push('/dashboard');
      }
    } else {
      history.push('/dashboard');
    }
  }

  render() {
    return (
      <div className="new-login-container-with-nav">
        <div className="new-login-navbar">
          <div
            className="square-logotype"
            style={{ backgroundImage: `url(${logotype})` }}
            onClick={() => this.onLoginImageClick()}
          />
          <div className="controls-left">
            <div className="btn-group">
              <div
                type="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                <FontAwesomeIcon
                  icon="globe"
                  size="1x"
                  color="gray"
                  className="button-icon"
                />
              </div>
              <div className="dropdown-menu">
                {Object.keys(this.props.languages).map((key, index) => {
                  return (
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => this.changeLanguage(key)}
                      key={index}>
                      {this.props.languages[key]}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="new-login-container">
          <div className="login-form-side">
            <Switch>
              <Route
                path="/:module/login"
                component={NewLogin}
              />
              <Route
                path="/:module/register"
                component={Register}
              />
              <Route
                path="/:module/change_password"
                component={ChangePinToPassword}
              />
              <Route
                path="/:module/reset_password/:token"
                component={NewSetNewPassword}
              />
              <Route
                path="/:module/reset_password"
                component={NewResetPassword}
              />
              <Route
                path="/:module/set_new_password"
                component={NewSetNewPassword}
              />
            </Switch>
          </div>
          <div className="login-photo-side-container">
            <div
              className="login-photo-side"
              style={{
                backgroundImage: `url(${sparepartsImage})`
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    language: state.appState.preferences.language,
    legalDocuments: state.appState.legalDocuments,
    departments: state.appState.departments,
    translations: state.appState.translations,
    languages: state.appState.languages
  };
};

export default connect(mapStateToProps, null)(LoginSwitch);
