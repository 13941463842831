import React from 'react';
import { timelineItemHiddenKeys } from 'utils/const';
import moment from 'moment';
import translate from 'utils/translate';
import CheckboxDone from '../assets/svg/productActions/checkboxDone';
import CheckBoxMiss from '../assets/svg/productActions/checkboxMiss';
import MapMarkerSvg from '../assets/svg/map';
import CheckedTrue from '../assets/svg/productActions/Checked';
import { v4 as uuidv4 } from 'uuid';
import TravellerPhoto from '../components/TravellerPhoto/TravellerPhoto';

export function isObject(val) {
  if (val === null) {
    return false;
  }
  if (val === undefined) {
    return false;
  }
  return typeof val === 'function' || typeof val === 'object';
}

export function parseObjectToDOM(obj) {
  const checkListShow = (obj) =>
    Object.keys(obj).map((key) => {
      return (
        <div
          className="checkbox-element"
          key={uuidv4()}>
          {obj[key].value ? (
            <div style={{ display: 'flex' }}>
              <CheckboxDone />
            </div>
          ) : (
            <div style={{ display: 'flex' }}>
              <CheckBoxMiss />
            </div>
          )}
          <p
            key={uuidv4()}
            className="history-action-value">
            {translate(key)}
          </p>
        </div>
      );
    });

  const insideObjectProperties = (obj, key) => {
    if (key === 'current_warehouse') {
      return parseWarehouseInfoToDom(obj);
    } else if (key === 'tracking_info') {
      return parseTrackingInfoToDom(obj);
    }
  };

  let params = [];
  Object.keys(obj).forEach((key, i) => {
    if (obj.hasOwnProperty(key)) {
      if (!timelineItemHiddenKeys.includes(key)) {
        if (isObject(obj[key]) && key !== 'checklist' && key !== 'files') {
          let nested_node = (
            <div
              className="history-action"
              key={uuidv4()}>
              <p className="history-action-name">{translate(key)}:</p>
              <div>
                {obj[key] !== null &&
                  obj[key] !== undefined &&
                  insideObjectProperties(obj[key], key)}
              </div>
            </div>
          );
          params.push(nested_node);
        } else if (key === 'checklist' && obj[key] !== null) {
          const checklistNode = (
            <div key={i}>
              <span className="history-action-name">{translate(key)}:</span>
              <div style={{ paddingBottom: '24px' }}>
                {obj[key] !== null &&
                  obj[key] !== undefined &&
                  checkListShow(obj[key])}
              </div>
            </div>
          );
          params.push(checklistNode);
        } else if (key === 'hidden') {

        } else {
          if ((obj[key] && obj[key] !== '') || key === 'files') {
            params.push(parseNodeFromVal(obj[key], key, obj));
          } else if (key === 'cascade' || key === 'plate_hex') {
            params.push(parseWarrantyAndCascadeRepairToDOM(key, obj[key]));
          }
        }
      }
    }
  });
  return params.map((param) => {
    return <React.Fragment key={uuidv4()}>{param}</React.Fragment>;
  });
}

export const parseNodeFromVal = (val, key, obj) => {
  if (key === 'files')
    return (
      <TravellerPhoto
        files={val}
        actionInfo={obj}
      />
    );

  if (val !== null && val !== undefined) {
    if (key.includes('date')) {
      val = moment(val).local().format('YYYY-MM-DD HH:mm');
    }
    if (key === 'warranty_repair' || key === 'cascade' || key === 'plate_hex') {
      return parseWarrantyAndCascadeRepairToDOM(key, val, obj);
    }

    if (key === 'target_location' || key === 'end_user_location') {
      return parseLocationDataToDOM(key, val);
    }

    if (key === 'file_url' || key === 'file_url_upload_enabled') return null;

    return (
      <div className="history-action">
        <p
          key={uuidv4()}
          className="history-action-name">
          {translate(key)}:{' '}
        </p>
        <p
          key={uuidv4()}
          className="history-action-value">
          {val}
        </p>
      </div>
    );
  }
};

const parseWarehouseInfoToDom = (obj) => {
  return (
    <>
      {obj.name && (
        <p
          key={uuidv4()}
          className="history-action-value">
          {translate('name')}: {obj.name}
        </p>
      )}
      {obj.address && (
        <a
          className="history-action-value"
          href={`https://maps.google.com/?ll=${obj.latitude},${obj.longitude}`}
          rel="noopener noreferrer"
          target="_blank"
          key={obj.value}>
          {translate('address')}: {obj.address}
        </a>
      )}
    </>
  );
};

const parseTrackingInfoToDom = (obj) => {
  const traking_info = [];
  Object.keys(obj).map((key) => {
    if (obj[key]) {
      traking_info.push({ name: key, value: obj[key] });
    }
  });
  const trakingInfo = traking_info.map((obj) => {
    if (obj.name === 'tracking_url') {
      return (
        <a
          href={`${obj.value}`}
          className="history-action-value"
          rel="noopener noreferrer"
          target="_blank">
          {translate(obj.name)}: {obj.value}
        </a>
      );
    } else {
      return (
        <p
          key={uuidv4()}
          className="history-action-value">
          {translate(obj.name)}: {translate(obj.value)}
        </p>
      );
    }
  });
  if (trakingInfo.length) {
    return trakingInfo;
  }
  return <p className="history-action-value">{translate('not found')}</p>;
};

const parseLocationDataToDOM = (key, val) => {
  return (
    <div className="history-action">
      <a
        className="history-action-name"
        href={`https://maps.google.com/?q=${val}`}
        rel="noopener noreferrer"
        target="_blank">
        <div style={{ display: 'flex' }}>
          <MapMarkerSvg />
          {translate(key)}
        </div>
      </a>
    </div>
  );
};
const parseWarrantyAndCascadeRepairToDOM = (key, val) => {
  return (
    <div
      className="history-action"
      style={{ display: 'flex' }}>
      {!val ? (
        <div style={{ display: 'flex' }}>
          <CheckBoxMiss />
        </div>
      ) : (
        <div style={{ display: 'flex' }}>
          <CheckedTrue />
        </div>
      )}
      <p className="history-action-value">{translate(key)}</p>
    </div>
  );
};
