import React from 'react';
import './svgstyles.scss';

const ExternalIcon = (c) => {
  let cls = `custom-aic-icon-small  ${c === {} ? '' : c.className}`;
  return (
    <svg
      className={cls}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8958 5.33334L15.8856 20.3432C14.8442 21.3846 14.8442 23.073 15.8855 24.1144C16.9269 25.1558 18.6153 25.1558 19.6567 24.1144L34.6669 9.10457V14.6667C34.6669 16.1394 35.8607 17.3333 37.3334 17.3333C38.8061 17.3333 40 16.1394 40 14.6667L40 2.66667C40 1.19391 38.8061 0 37.3334 0H25.3339C23.8612 0 22.6673 1.19391 22.6673 2.66667C22.6673 4.13943 23.8612 5.33334 25.3339 5.33334L30.8958 5.33334Z"
        fill="#32343A"
      />
      <path
        d="M13.1717 5.65657H5.86869C4.64697 5.65657 3.65657 6.64697 3.65657 7.86869V34.1313C3.65657 35.353 4.64697 36.3434 5.86869 36.3434H32.1313C33.353 36.3434 34.3434 35.353 34.3434 34.1313V26.8283C34.3434 25.8185 35.162 25 36.1717 25C37.1814 25 38 25.8185 38 26.8283V34.1313C38 37.3725 35.3725 40 32.1313 40H5.86869C2.6275 40 0 37.3725 0 34.1313V7.86869C0 4.6275 2.6275 2 5.86869 2H13.1717C14.1815 2 15 2.81855 15 3.82828C15 4.83802 14.1815 5.65657 13.1717 5.65657Z"
        fill="#32343A"
      />
    </svg>
  );
};

export default ExternalIcon;
