import React, { Component } from 'react';
import axios from 'axios';
import history from './../../history';
import store from 'store';
import './newlogin.scss';
import translate from 'utils/translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { validateEmail } from '../../utils/validations';
import { showError } from '../../actions/actions';

class NewResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Email: ''
    };
  }

  handleResetSubmit = (event) => {
    event.preventDefault();

    const isValid = this.state.Email && validateEmail(this.state.Email);

    if (isValid) {
      const config = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      };

      const formData = new FormData();
      formData.append('Email', this.state.Email);

      axios
        .post('/api/auth/reset_password_request', formData, config)
        .then((response) => {
          store.dispatch({
            type: 'showMsg',
            value: {
              msg: translate('Reset Password Email sent.'),
              type: 'success'
            }
          });
          this.setState({ requestedEmailSent: true });
        })
        .catch((error) => showError(error));
    } else {
      if (!this.state.Email) {
        this.setState({ isEmailEmpty: true });
      }
      if (!validateEmail(this.state.Email)) {
        this.setState({ isEmailValid: false });
      }
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  render() {
    return (
      <>
        {this.state.requestedEmailSent && (
          <div className="login-step">
            <div className="login-step-title">
              {translate('Password Recovery')}
            </div>
            <div className="login-welcome-text">
              {translate(
                'Your reset password email has been sent. Please check it and follow the instructions inside.'
              )}
            </div>
            <div className="login-welcome-buttons">
              <button
                className="contact-btn aic-btn"
                onClick={() =>
                  history.push(`/${this.props.match.params.module}/login`)
                }>
                {translate('Back to log in')}
              </button>
            </div>
          </div>
        )}

        {!this.state.requestedEmailSent && (
          <div className="login-step">
            <div className="login-step-title">
              {translate('Password Recovery')}
            </div>
            <div className="login-step-login-form">
              <div className="login-welcome-text">
                {translate(
                  'If you forgot your password you can set up a new one. Enter your registration email and we will send you link for password change.'
                )}
              </div>
              <form
                onSubmit={this.handleResetSubmit}
                autoComplete="off">
                <div className="custom-input">
                  <input
                    type="text"
                    className="inputText"
                    required
                    value={this.state.Email}
                    onChange={this.handleChange}
                    id="Email"
                  />
                  <FontAwesomeIcon
                    icon="user"
                    size="sm"
                    color="gray"
                    className="input-icon"
                  />
                  <span className="floating-label">{translate('Email')}</span>
                </div>

                <div className="login-welcome-buttons">
                  <button
                    className="contact-btn aic-btn"
                    type="submit">
                    {translate('Reset password')}
                  </button>
                </div>

                <div className="already-a-member">
                  <span
                    className="span-link"
                    onClick={() => history.push(`/dashboard/login`)}>
                    {translate('Back to log in')}
                  </span>
                </div>
              </form>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default NewResetPassword;
