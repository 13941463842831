import React from 'react';
import InfoBoldIcon from '../../assets/svg/infobold';
import translate from '../../utils/translate';
import { Link } from 'react-router-dom';

const SparepartReplaces = ({ sparepart: { replaces } }) => {
  return (
    <div className="replaces-container">
      <div className="in-circle">
        <InfoBoldIcon />
      </div>
      <div className="replaces-content">
        <div className="title"> {translate('Replaces')}</div>
        {replaces.map(({ index, description }, i) => {
          return (
            <div
              className="replaces-item"
              key={i}>
              <Link
                key={i}
                className="replaced-by-link"
                to={`/spareparts/details/${index}`}>
                {description}
              </Link>
              ({index})
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SparepartReplaces;
