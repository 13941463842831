import React from 'react';

const HideProductHistory = (z) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.94726 12.6619L3.75164 11.8631C2.87088 11.0723 2.17887 10.0939 1.72664 9C2.86851 6.14812 6.01851 3.9375 8.99976 3.9375C9.76695 3.94763 10.5272 4.08447 11.2498 4.3425L12.1216 3.465C11.1331 3.04724 10.0728 2.82563 8.99976 2.8125C7.1663 2.88145 5.39323 3.48612 3.89969 4.5518C2.40614 5.61747 1.2576 7.09743 0.596011 8.80875C0.55133 8.93234 0.55133 9.06766 0.596011 9.19125C1.09565 10.5171 1.90125 11.7062 2.94726 12.6619Z"
        fill="#32343A"
      />
      <path
        d="M6.74827 8.84808C6.78739 8.30904 7.01922 7.8021 7.40138 7.41994C7.78354 7.03778 8.29049 6.80595 8.82952 6.76683L9.84765 5.74308C9.27714 5.59286 8.67722 5.59481 8.1077 5.74874C7.53818 5.90266 7.01896 6.20319 6.6018 6.62035C6.18463 7.03751 5.88411 7.55674 5.73018 8.12626C5.57626 8.69578 5.57431 9.2957 5.72452 9.86621L6.74827 8.84808Z"
        fill="#32343A"
      />
      <path
        d="M17.4037 8.80875C16.7587 7.12868 15.6364 5.67395 14.175 4.62375L16.875 1.91813L16.0819 1.125L1.125 16.0819L1.91813 16.875L4.78688 14.0062C6.06593 14.7566 7.51727 15.1635 9 15.1875C10.8335 15.1186 12.6065 14.5139 14.1001 13.4482C15.5936 12.3825 16.7422 10.9026 17.4037 9.19125C17.4484 9.06766 17.4484 8.93234 17.4037 8.80875ZM11.25 9C11.2476 9.39381 11.1419 9.7801 10.9435 10.1203C10.7451 10.4604 10.4608 10.7425 10.1192 10.9384C9.77751 11.1343 9.39044 11.2371 8.99662 11.2365C8.60281 11.2359 8.21605 11.1319 7.875 10.935L10.935 7.875C11.1372 8.21572 11.2459 8.60381 11.25 9ZM9 14.0625C7.81991 14.0419 6.66219 13.737 5.625 13.1737L7.05375 11.745C7.70363 12.1959 8.49124 12.4043 9.27907 12.3336C10.0669 12.263 10.8049 11.9179 11.3642 11.3586C11.9235 10.7993 12.2686 10.0613 12.3393 9.27345C12.4099 8.48561 12.2015 7.698 11.7506 7.04812L13.365 5.43375C14.6557 6.31968 15.665 7.55743 16.2731 9C15.1313 11.8519 11.9812 14.0625 9 14.0625Z"
        fill="#32343A"
      />
    </svg>
  );
};

export default HideProductHistory;

export const HideProductHistoryCardSVG = (z) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="hidden-card-svg">
      <path
        d="M2.94726 12.6619L3.75164 11.8631C2.87088 11.0723 2.17887 10.0939 1.72664 9C2.86851 6.14812 6.01851 3.9375 8.99976 3.9375C9.76695 3.94763 10.5272 4.08447 11.2498 4.3425L12.1216 3.465C11.1331 3.04724 10.0728 2.82563 8.99976 2.8125C7.1663 2.88145 5.39323 3.48612 3.89969 4.5518C2.40614 5.61747 1.2576 7.09743 0.596011 8.80875C0.55133 8.93234 0.55133 9.06766 0.596011 9.19125C1.09565 10.5171 1.90125 11.7062 2.94726 12.6619Z"
        fill="#32343A"
      />
      <path
        d="M6.74827 8.84808C6.78739 8.30904 7.01922 7.8021 7.40138 7.41994C7.78354 7.03778 8.29049 6.80595 8.82952 6.76683L9.84765 5.74308C9.27714 5.59286 8.67722 5.59481 8.1077 5.74874C7.53818 5.90266 7.01896 6.20319 6.6018 6.62035C6.18463 7.03751 5.88411 7.55674 5.73018 8.12626C5.57626 8.69578 5.57431 9.2957 5.72452 9.86621L6.74827 8.84808Z"
        fill="#32343A"
      />
      <path
        d="M17.4037 8.80875C16.7587 7.12868 15.6364 5.67395 14.175 4.62375L16.875 1.91813L16.0819 1.125L1.125 16.0819L1.91813 16.875L4.78688 14.0062C6.06593 14.7566 7.51727 15.1635 9 15.1875C10.8335 15.1186 12.6065 14.5139 14.1001 13.4482C15.5936 12.3825 16.7422 10.9026 17.4037 9.19125C17.4484 9.06766 17.4484 8.93234 17.4037 8.80875ZM11.25 9C11.2476 9.39381 11.1419 9.7801 10.9435 10.1203C10.7451 10.4604 10.4608 10.7425 10.1192 10.9384C9.77751 11.1343 9.39044 11.2371 8.99662 11.2365C8.60281 11.2359 8.21605 11.1319 7.875 10.935L10.935 7.875C11.1372 8.21572 11.2459 8.60381 11.25 9ZM9 14.0625C7.81991 14.0419 6.66219 13.737 5.625 13.1737L7.05375 11.745C7.70363 12.1959 8.49124 12.4043 9.27907 12.3336C10.0669 12.263 10.8049 11.9179 11.3642 11.3586C11.9235 10.7993 12.2686 10.0613 12.3393 9.27345C12.4099 8.48561 12.2015 7.698 11.7506 7.04812L13.365 5.43375C14.6557 6.31968 15.665 7.55743 16.2731 9C15.1313 11.8519 11.9812 14.0625 9 14.0625Z"
        fill="#32343A"
      />
    </svg>
  );
};
