import React from 'react';
import translate from '../../utils/translate';
import { RiDeleteBin2Line } from 'react-icons/ri';
import ImageUploading from 'react-images-uploading';
import './style.scss';
import {
  ActionSelect
} from '../../scenes/Traveller/ProductDashboard/ProductHistory/AddAction/ActionTypes/ActionSelect';
import { useSelector } from 'react-redux';
import { GrReturn } from 'react-icons/gr';
import { resizeFile } from '../../utils/helpers/resizeFile';
import { uriToFile } from '../../utils/helpers/uriToFile';

const ImageUpload = ({ droppedFile, imagesToDelete = [], onDrop, onRemove, onReturnRemove, actionType }) => {
  const actionParams = useSelector(state => state.productInfo.actionList.find(el => el.type === actionType)?.params);
  const categories = actionParams.find(param => param.name === "file_attachment")?.possible_values;

  const categoriesOptions = categories
    ? categories.map(cat => ({ label: cat.displayed_name, value: cat.key }))
    : []

  const selectedCategoryValue = (img) => categoriesOptions.length === 1
    ? categoriesOptions[0]
    : categoriesOptions.find(el => el.value === img?.file_cat)

  const handleDropFile = async ( picture ) => {
    if (!picture.file) return picture;

    const image = await resizeFile(picture.file);
    const file = await uriToFile(image, picture.file.name, 'image/jpeg');
    const file_cat = picture.file_cat || categoriesOptions.length === 1 ? categoriesOptions[0].value : ''
    return { ...picture, file, file_cat };
  };

  const handleChangeDroppedFiles = async ( picturesList ) => {
    const pics = await Promise.all(picturesList.map(pic => handleDropFile(pic)));
    onDrop(pics);
  };

  const handleChangeCategory = (idx) => (category) => {
    const newImages = droppedFile.map((el, index) => {
      if (index !== idx) return el;

      return {
        ...el,
        file_cat: category.value
      }
    })

    onDrop(newImages)
  }

  return (
    <ImageUploading
      value={droppedFile}
      onChange={handleChangeDroppedFiles}
      multiple
      dataURLKey="data_url">
      {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
        <div className="picture-attachment">
            <div
            className='fileContainer'
            style={isDragging
              ? { color: 'red' }
              : undefined}>
            <div
              className='fileContainer--uploader'
              onClick={onImageUpload}
              {...dragProps}>
              <p className='file-uploader-text'>
                {translate('Drag and drop file here')}{' '}
                {translate('or browse file from your computer')}
              </p>
              <p className='chooseFileButton file-uploader-link'>
                Choose file with format jpg/jpeg/png
              </p>
            </div>
            <div className='fileContainer--images'>
              {imageList.map(( image, index ) => {
                const shouldBeDeleted = imagesToDelete.length > 0 && imagesToDelete.some(el => el?.file_url === image?.file_url);
                return (<div
                    key={index}
                    data-error={!image?.file_cat}
                    className='image-item'>
                    <img
                      data-delete={shouldBeDeleted}
                      src={image['data_url'] || image['file_url']}
                      alt='action-result'
                    />
                    {shouldBeDeleted
                      ? <GrReturn
                        className='deleteImage'
                        onClick={() => onReturnRemove(image)}
                      />
                      : <RiDeleteBin2Line
                        className='deleteImage'
                        onClick={() => image.file
                          ? onImageRemove(index)
                          : onRemove(image)}
                      />}

                    {image['file_url'] && categoriesOptions.length > 0
                      ? <p className='chooseFileButton'>
                        {translate(image['file_cat'])}
                      </p>
                      : <ActionSelect
                        placeholder={translate('Choose category')}
                        inputValue={selectedCategoryValue(image)}
                        key={index}
                        onChangeInput={handleChangeCategory(index)}
                        errorMessage=''
                        options={categoriesOptions}
                      />}
                  </div>);
              })}
            </div>
          </div>
        </div>
      )}
    </ImageUploading>
  );
};

export default ImageUpload;
