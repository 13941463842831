import React from 'react';
import './ProducActionCardBody.scss';
import AnimateHeight from 'react-animate-height';
import { parseObjectToDOM } from '../../../../../../../utils/timelineItem';

const ProductActionCardBody = ( { height, action } ) => {
  return (
    <AnimateHeight
      id='example-panel'
      duration={1000}
      height={height}
    >
      <hr style={{ margin: '0 24px' }} />
      <div className='product-history-timeline-card-details'>
        { parseObjectToDOM(action) }
      </div>
    </AnimateHeight>
  );
};

export default ProductActionCardBody;
