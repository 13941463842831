import React from 'react';
import translate from '../../../utils/translate';

const TravellerImage = ({ title, image }) => {
  return (
      <div className="history-action-image--container">
        <a
          download={`${image}`}
          href={`${image}`}
          title="ImageName"
          rel="noopener noreferrer"
          target="_blank">
          <div className='history-action--ribbon'>{translate(title)}</div>
          <img
            className="history-action-image"
            src={`${image}`}
            alt={title}
          />
        </a>
      </div>
  );
};

export default TravellerImage;
