import React from 'react';
import translate from '../../../utils/translate';
import history from '../../../history';
import { useDispatch, useSelector } from 'react-redux';
import { selectItemOnTree } from '../../../actions/actions';

const NavbarBreadcrumbs = ({ goTo, isDistributor }) => {
  const dispatch = useDispatch();

  const currentPath = window.location.pathname;

  const { isPreferencesModalOpen, partListDescription } = useSelector(
    (state) => state.appState
  );

  const { selectedSparepartsGroup, currentGroup } = useSelector(
    (state) => state.spareparts
  );
  const isCartModalOpen = useSelector(
    (state) => state.shoppingList.isCartModalOpen
  );
  const boiler = useSelector((state) => state.productInfo.boiler);

  return (
    <div className="navbar-left">
      <div className="navbar-left-path-container">
        <span
          className="navbar-left-path-text"
          onClick={() => {
            if (isDistributor) {
              dispatch(selectItemOnTree({}));
              history.push('/spareparts');
            } else history.push('/');
          }}
        >
          aicOn
        </span>
        <span className="navbar-left-path-arrow"> > </span>

        {/* dashboard path */}
        {currentPath.includes('dashboard') && (
          <span>
            <span
              className="navbar-left-path-text"
              onClick={() => history.push('/dashboard')}
            >
              {translate('All devices')}
            </span>

            {currentPath.split('/')[2] && (
              <span>
                <span className="navbar-left-path-arrow"> > </span>
                <span
                  className="navbar-left-path-text"
                  onClick={() => history.goBack()}
                >
                  {currentPath.split('/')[2]}
                </span>
                <span className="navbar-left-path-arrow"> > </span>
                <span className="navbar-left-path-text">
                  {currentPath.split('/').pop()}
                </span>
              </span>
            )}
          </span>
        )}

        {/* spareparts path */}
        {currentPath.includes('spareparts') && (
          <span>
            <span
              className="navbar-left-path-text"
              onClick={() => goTo('/spareparts')}
            >
              {translate('spareparts')}
            </span>

            {currentPath.includes('search') && (
              <span>
                <span className="navbar-left-path-arrow"> > </span>
                <span className="navbar-left-path-text">
                  {translate('Search')}
                </span>
              </span>
            )}

            {currentPath.includes('categories') && (
              <span>
                <span className="navbar-left-path-arrow"> > </span>
                <span className="navbar-left-path-text">
                  {translate('Categories')}
                </span>
              </span>
            )}

            {selectedSparepartsGroup && !currentPath.includes('categories') ? (
              <span>
                <span className="navbar-left-path-arrow"> > </span>
                <span
                  className="navbar-left-path-text"
                  onClick={() => goTo('/spareparts/categories')}
                >
                  {translate('Categories')}
                </span>
                <span className="navbar-left-path-arrow"> > </span>
                <span
                  className="navbar-left-path-text"
                  onClick={() => history.push('/spareparts')}
                >
                  {`${selectedSparepartsGroup.family} ${
                    selectedSparepartsGroup.subfamily || ''
                  } - ${selectedSparepartsGroup.value} ${selectedSparepartsGroup.unit || ''} - ${
                    currentGroup.name
                  }`}
                </span>
                {currentPath.includes('details') && (
                  <span>
                    <span className="navbar-left-path-arrow"> > </span>
                    <span className="navbar-left-path-text">
                      {partListDescription}
                    </span>
                  </span>
                )}
              </span>
            ) : (
              <span>
                {currentPath.includes('details') && (
                  <span>
                    <span className="navbar-left-path-arrow"> > </span>
                    <span className="navbar-left-path-text">
                      {partListDescription}
                    </span>
                  </span>
                )}
              </span>
            )}
          </span>
        )}

        {/* Device history (product_info) path */}
        {currentPath.includes('product_info') && (
          <span>
            <span
              className="navbar-left-path-text"
              onClick={() => history.push('/dashboard')}
            >
              {translate('All devices')}
            </span>
            <span className="navbar-left-path-arrow"> > </span>
            <span className="navbar-left-path-text">
              {boiler && boiler.serial_number}
            </span>
          </span>
        )}

        {isPreferencesModalOpen && (
          <span>
            <span className="navbar-left-path-arrow"> > </span>
            <span className="navbar-left-path-text">
              {translate('Preferences')}
            </span>
          </span>
        )}

        {isCartModalOpen && (
          <span>
            <span className="navbar-left-path-arrow"> > </span>
            <span className="navbar-left-path-text">{translate('Cart')}</span>
          </span>
        )}
      </div>
    </div>
  );
};

export default NavbarBreadcrumbs;
