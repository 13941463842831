import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import translate from '../../utils/translate';
import '../Login/login.scss';
import axios from 'axios';
import store from 'store';
import Input from '../../components/Input/Input';
import './ResetPassword.scss';
import { ReactComponent as Logotype } from '../../assets/svg/logosquare.svg';
import { showError } from '../../actions/actions';

const ResetPasswordEmailForm = () => {
  const [emailForm, setEmailForm] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isEmailEmpty, setIsEmailEmpty] = useState(false);
  const [requestedEmailSent, setRequestedEmailSent] = useState(false);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    const isValid = emailForm && validateEmail(emailForm);

    if (isValid) {
      const config = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      };

      const formData = new FormData();
      formData.append('Email', emailForm);

      axios
        .post('/api/auth/reset_password_request', formData, config)
        .then((response) => {
          store.dispatch({
            type: 'showMsg',
            value: {
              msg: translate('Reset Password Email sent.'),
              type: 'success'
            }
          });
          setRequestedEmailSent(true);
        })
        .catch((error) => {
          showError(error);
        });
    } else {
      if (!emailForm) {
        setIsEmailEmpty(true);
      }
      if (!validateEmail(emailForm)) {
        setIsEmailValid(false);
      }
    }
  };

  const handleChange = (event) => {
    setIsEmailValid(true);
    setIsEmailEmpty(false);
    setEmailForm(event.target.value);
  };

  const logotypeStyle = {
    position: 'fixed',
    height: '10rem',
    width: '10rem',
    top: '0',
    margin: '1rem auto',
    display: 'block'
  };
  return (
    <div
      className=" vertical-center vertical-center-register"
      style={{ overflow: 'hidden' }}
    >
      <div className="container center-block register-container">
        <div className="logotype" style={logotypeStyle}>
          <Logotype />
        </div>
        {!requestedEmailSent && (
          <div className="login-card login-card-register">
            <div className="card-header">
              <h3 className="login-header">{translate('Password Recovery')}</h3>
            </div>
            <div className="card-body">
              <p>
                {translate(
                  'If you forgot your password you can set up a new one. Enter your registration email and we will send you link for password change.'
                )}
              </p>
              <form onSubmit={handleSubmit} autoComplete="off">
                <Input
                  type="text"
                  value={emailForm}
                  onChange={handleChange}
                  id="Email"
                  placeholder={translate('Enter email')}
                />
                {isEmailEmpty && (
                  <div style={{ color: 'red' }}>
                    {translate('This field cannot be empty. ')}
                  </div>
                )}
                {!isEmailValid && !isEmailEmpty && (
                  <div style={{ color: 'red' }}>
                    {translate('Enter valid email.')}
                  </div>
                )}
                <div
                  className="form-group card-footer"
                  style={{ padding: '1rem 0 0 0' }}
                >
                  <Link to="/login">
                    <button
                      type="submit"
                      className="btn btn-outline btn-login float-left btn-close-modal"
                      style={{ fontWeight: '700' }}
                    >
                      {translate('Back to login')}
                    </button>
                  </Link>
                  <button
                    type="submit"
                    disabled={requestedEmailSent}
                    className="btn btn-outline btn-login float-right btn-register"
                    style={{ fontWeight: '700' }}
                  >
                    {translate('Reset Password')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {requestedEmailSent && (
          <div className="login-card login-card-register">
            <div className="card-header">
              <h3 className="login-header">
                {translate('Email Sent Successfully')}
              </h3>
            </div>
            <div className="card-body">
              <p>
                {translate(
                  'Your reset password email has been sent. Please check it and follow the instructions inside.'
                )}
              </p>
            </div>
            <div
              className="form-group card-footer"
              style={{ padding: '1rem 0 0 0' }}
            >
              <Link to="/login">
                <button
                  type="submit"
                  className="btn btn-outline btn-login float-right"
                  style={{
                    fontWeight: '700',
                    marginRight: '30px'
                  }}
                >
                  {translate('Back to login')}
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordEmailForm;
