import React, { useEffect, useRef, useState } from 'react';
import history from './../../history';
import { useSelector } from 'react-redux';
import './newlogin.scss';
import translate from 'utils/translate';
import ValidationError from '../../components/ValidationError/NewValidationError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { getLegalDocuments, showError } from 'actions/actions';
import { UserRoles } from 'utils/const';
import { Field, Form, Formik } from 'formik';
import { registerSchema } from '../../utils/validationSchema';
import PasswordDifficultyBar from '../../components/PasswordDifficultyBar/PasswordDifficultyBar';
import { DebounceInput } from 'react-debounce-input';
import FormInput from '../../components/FormInput/FormInput';
import axios from 'axios';

let renderedDepartments;

const { END_USER, INSTALLATION, SERVICE, DISTRIBUTION } = UserRoles;

const rolesOptions = [
  { label: translate(END_USER), value: END_USER },
  { label: translate(INSTALLATION), value: INSTALLATION },
  { label: translate(SERVICE), value: SERVICE },
  { label: translate(DISTRIBUTION), value: DISTRIBUTION }
];

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: false,
  role: undefined,
  isUser: undefined,
  isItaly: undefined,
  department: undefined,
  phoneNumber: '',
  taxNumber: '',
  acceptTermsAndConditions: false
};

const selectStyles = {
  singleValue: (styles) => ({
    ...styles,
    marginLeft: '18px',
    fontSize: '16px'
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none'
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: '#000'
  }),
  control: (styles) => ({
    ...styles,
    border: 'none !important',
    boxShadow: 'none !important'
  })
};

const Register = () => {
  const [requestedEmailSent, setRequestedEmailSent] = useState(false);
  let roleref = useRef(null);
  let departmentref = useRef(null);

  const clearSelects = (...args) =>
    args.forEach((ref) => ref.current.clearValue());

  const { legalDocuments, departments } = useSelector(
    (state) => state.appState
  );

  if (departments) {
    renderedDepartments = departments.map((department) => {
      return { label: department.code, value: department };
    });
  }

  useEffect(() => {
    getLegalDocuments();
  }, []);

  const handleRegisterSubmit = (values) => {
    const registerParams = {
      FirstName: values.firstName,
      LastName: values.lastName,
      Email: values.email,
      Password: values.password,
      ConfirmPassword: values.password,
      Role: values.role.value,
      Department: values.department && values.department.value.code,
      PhoneNumber: values.phoneNumber,
      AcceptTermsAndConditions: true,
      AcceptPrivacyPolicy: true,
      ServiceCompanyTaxNumber: values.taxNumber
    };

    const formData = new FormData();
    for (let key in registerParams) {
      formData.append(key, registerParams[key]);
    }

    axios
      .post('/api/auth/register', formData, {
        headers: {
          Accept: 'multipart/form-data',
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        if (response.status === 200) {
          setRequestedEmailSent(true);
        } else {
          console.log('Blad, Status inny niz 200');
        }
      })
      .catch((error) => showError(error));
  };

  if (requestedEmailSent) {
    return (
      <div className="login-step">
        <div className="login-step-title">{translate('Register')}</div>
        <h5 className="login-welcome-text">
          {translate('Thank you for registering to aicON.')}
        </h5>
        <div className="login-welcome-text">
          {translate('Please confirm your email.')}
        </div>
        <div className="login-welcome-buttons">
          <button
            className="contact-btn aic-btn"
            onClick={() => history.push('/login')}
          >
            {translate('Back to log in')}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="login-step">
      <div className="login-step-title">{translate('Register')}</div>
      <div className="login-step-login-form">
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { resetForm }) => {
            handleRegisterSubmit(values);
            resetForm();
            clearSelects(roleref, departmentref);
          }}
          validationSchema={registerSchema}
        >
          {({
            setFieldValue,
            handleSubmit,
            isSubmitting,
            values,
            errors,
            touched,
            handleChange
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <FormInput
                  value={values.email}
                  name="email"
                  label={translate('Email')}
                  handleChange={handleChange}
                  error={errors.email}
                  touch={touched.email}
                />

                <FormInput
                  value={values.firstName}
                  name="firstName"
                  label={translate('First name')}
                  handleChange={handleChange}
                  error={errors.firstName}
                  touch={touched.firstName}
                />

                <FormInput
                  name="lastName"
                  value={values.lastName}
                  label={translate('Last name')}
                  handleChange={handleChange}
                  error={errors.lastName}
                  touch={touched.lastName}
                />

                <FormInput
                  name="phoneNumber"
                  value={values.phoneNumber}
                  label={translate('Phone number')}
                  handleChange={handleChange}
                  error={errors.phoneNumber}
                  touch={touched.phoneNumber}
                />
                <Field name="role">
                  {() => (
                    <>
                      <div className="custom-input" data-testid="role">
                        {errors.role && touched.role && (
                          <FontAwesomeIcon
                            icon="exclamation-triangle"
                            size="sm"
                            color="gray"
                            className="warn-input-icon"
                          />
                        )}
                        <Select
                          ref={roleref}
                          className="custom-inputs inputText"
                          placeholder=""
                          value={values.role}
                          onChange={(newValue) => {
                            setFieldValue('role', newValue);
                            newValue &&
                              setFieldValue(
                                'isUser',
                                newValue.value === 'end_user'
                              );
                          }}
                          styles={selectStyles}
                          options={rolesOptions}
                          id="role"
                        />
                        <FontAwesomeIcon
                          icon="briefcase"
                          size="sm"
                          color="gray"
                          className="input-icon"
                        />
                        <span
                          className={
                            values.role && values.role.value
                              ? 'floating-label-up'
                              : 'floating-label'
                          }
                        >
                          {translate('Role')}*
                        </span>
                      </div>
                      {errors.role && touched.role && (
                        <div style={{ width: '100%' }}>
                          <ValidationError
                            text={translate('You have to choose your role')}
                          />
                        </div>
                      )}
                    </>
                  )}
                </Field>

                {/*<Fade top collapse when={showDepartments}>*/}
                {values.role && values.role.value !== 'end_user' && (
                  <Field name="department">
                    {() => (
                      <>
                        <div className="custom-input" data-testid="department">
                          {errors.department && touched.department && (
                            <FontAwesomeIcon
                              icon="exclamation-triangle"
                              size="sm"
                              color="gray"
                              className="warn-input-icon"
                            />
                          )}
                          <Select
                            ref={departmentref}
                            className="custom-inputs inputText"
                            placeholder=""
                            value={values.department}
                            onChange={(newValue) => {
                              console.log(newValue);
                              setFieldValue('department', newValue);
                              newValue &&
                                setFieldValue(
                                  'isItaly',
                                  newValue.label === 'AICIT'
                                );
                            }}
                            styles={selectStyles}
                            options={renderedDepartments}
                            id="department"
                          />
                          <FontAwesomeIcon
                            icon="globe"
                            size="sm"
                            color="gray"
                            className="input-icon"
                          />
                          <span
                            className={
                              values.department && values.department.value
                                ? 'floating-label-up'
                                : 'floating-label'
                            }
                          >
                            {translate('Department')}*
                          </span>
                        </div>
                        {errors.department && touched.department && (
                          <div
                            style={{
                              width: '100%'
                            }}
                          >
                            <ValidationError text={errors.department} />
                          </div>
                        )}
                      </>
                    )}
                  </Field>
                )}

                {values.role?.value !== END_USER &&
                  values.role?.value !== DISTRIBUTION && (
                    <FormInput
                      name="taxNumber"
                      value={values.taxNumber}
                      label={translate('Tax Number')}
                      handleChange={handleChange}
                      error={errors.taxNumber}
                      touch={touched.taxNumber}
                      required={!!values.isItaly}
                    />
                  )}

                <>
                  <div className="custom-input" style={{ marginBottom: '0' }}>
                    {errors.confirmPassword && touched.password && (
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="sm"
                        color="gray"
                        className="warn-input-icon"
                      />
                    )}
                    <DebounceInput
                      type="password"
                      className="inputText"
                      value={values.password}
                      onChange={handleChange}
                      debounceTimeout={300}
                      id="password"
                      name="password"
                      data-testid="password"
                    />
                    <FontAwesomeIcon
                      icon="lock"
                      size="sm"
                      color="gray"
                      className="input-icon"
                    />
                    <span className="floating-label">
                      {translate('Password') + '*'}
                    </span>
                  </div>
                  <PasswordDifficultyBar
                    pass={values.password}
                    setPasswordValidity={setFieldValue}
                  />
                </>

                <div className="form-check" style={{ marginTop: '1rem' }}>
                  {errors.acceptTermsAndConditions &&
                    touched.acceptTermsAndConditions && (
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="sm"
                        color="gray"
                        className="warn-input-icon"
                      />
                    )}
                  <Field
                    type="checkbox"
                    name="acceptTermsAndConditions"
                    className="form-check-input"
                    data-testid="privacyPolicyCheck"
                  />
                  <label
                    className="form-check-label chekbox-form"
                    htmlFor="inlineFormCheck"
                  >
                    <span>
                      {translate('I have read the')}
                      <a
                        className="document-link span-link"
                        href={
                          legalDocuments.privacy_policy &&
                          legalDocuments.privacy_policy.url
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {' '}
                        "{translate('Privacy Policy')}"
                      </a>{' '}
                      {translate('and agree to the')}
                      <a
                        className="document-link span-link"
                        href={
                          legalDocuments.terms_and_conditions &&
                          legalDocuments.terms_and_conditions.url
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {' '}
                        "{translate('Terms and conditions')}"
                      </a>
                    </span>
                    <span className="required-dot"> *</span>
                  </label>

                  {errors.acceptTermsAndConditions &&
                    touched.acceptTermsAndConditions && (
                      <div
                        style={{
                          width: '100%',
                          marginTop: '13px'
                        }}
                      >
                        <ValidationError
                          text={errors.acceptTermsAndConditions}
                        />
                      </div>
                    )}
                </div>

                <div className="login-welcome-buttons">
                  <button
                    className="login-btn aic-btn"
                    type="submit"
                    data-testid="btnSignUp"
                    disabled={isSubmitting}
                  >
                    {translate('Sign up')}
                  </button>
                </div>

                <div className="already-a-member">
                  <span
                    className="span-link"
                    onClick={() => history.push(`/login`)}
                  >
                    {translate('already a member? Log in')}
                  </span>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Register;
