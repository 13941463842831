import React from 'react';

const WarrantyExtension = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6875 5.16586L9.23062 3.70898L8.4375 4.50211L10.6875 6.75211L14.625 2.81461L13.8319 2.02148L10.6875 5.16586Z"
        fill="#32343A"
      />
      <path
        d="M12.4121 8.43592H11.1043C10.3826 9.46417 9.189 10.1167 7.88063 10.1167C5.72231 10.1167 3.9465 8.34086 3.9465 6.18254C3.9465 4.02423 5.72231 2.24842 7.88063 2.24842C7.992 2.24842 8.10338 2.25292 8.21419 2.26248V1.13242C8.09944 1.12454 7.98469 1.12061 7.86938 1.12061C5.09569 1.12061 2.8125 3.40323 2.8125 6.17748C2.8125 7.61692 3.42731 8.98942 4.50056 9.94848V16.8734L7.87556 14.6234L11.2506 16.8734V9.95917C11.7338 9.52829 12.1275 9.01023 12.4121 8.43592ZM10.1256 14.7714L7.87556 13.2712L5.62556 14.7714V10.714C7.03969 11.4245 8.71031 11.4262 10.1256 10.7174V14.7714Z"
        fill="#32343A"
      />
    </svg>
  );
};

export default WarrantyExtension;
