import React from 'react';
import './validationError.scss';
import Fade from 'react-reveal/Fade';

const ValidationError = ({ text }) => (
  <div className="new-validation-error">
    <Fade top collapse show>
      <small style={{ color: '#ff2f28' }} className="form-text error w-100">
        {text}
      </small>
    </Fade>
  </div>
);

export default ValidationError;
