export default function parseMsg(obj, variant = 'error') {
  let msg = 'Missing connection';
  if (obj) {
    if (typeof obj === 'string' || obj instanceof String) {
      msg = obj;
    } else {
      msg = 'Usnupported error';
    }
    if (obj.message) {
      msg = obj.message;
    }
    if (obj.response) {
      if (obj.response.message) {
        msg = obj.response.message;
      }
      if (obj.response.data) {
        if (
          typeof obj.response.data === 'string' ||
          obj.response.data instanceof String
        ) {
          msg = obj.response.data;
        }
        if (obj.response.data.message) {
          variant = 'warning';
          msg = obj.response.data.message;
        }
        if (obj.response.data.localized_message) {
          variant = 'warning';
          msg = obj.response.data.localized_message.description;
        }
      }
    }
  }
  return { msg: msg, variant: variant };
}
