import React from 'react';

const DocumentDownload = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 13L14.293 12.293L13 13.586V9.5H12V13.586L10.707 12.293L10 13L12.5 15.5L15 13Z"
        fill="#32343A"
      />
      <path
        d="M9 14.4999H4V2.49993H8V5.49993C8.00077 5.76491 8.10637 6.01882 8.29374 6.20619C8.48111 6.39356 8.73502 6.49916 9 6.49993H12V7.99993H13V5.49993C13.0018 5.43421 12.9893 5.36889 12.9634 5.30847C12.9375 5.24804 12.8988 5.19395 12.85 5.14993L9.35 1.64993C9.30599 1.60109 9.2519 1.56239 9.19147 1.53649C9.13104 1.51059 9.06572 1.49812 9 1.49993H4C3.73502 1.50069 3.48111 1.6063 3.29374 1.79367C3.10637 1.98104 3.00077 2.23495 3 2.49993V14.4999C3.00077 14.7649 3.10637 15.0188 3.29374 15.2062C3.48111 15.3936 3.73502 15.4992 4 15.4999H9V14.4999ZM9 2.69993L11.8 5.49993H9V2.69993Z"
        fill="#32343A"
      />
    </svg>
  );
};

export default DocumentDownload;
