import React from 'react';
import TrashIcon from 'assets/svg/trash.jsx';
import imgNotFound from '../../../assets/img/Photo.png';
import SuitableForCell from 'components/SuitableForCell/SuitableForCell';

const CartModalRow = ({ sparepart, updateCart, removeFromCart }) => {
  return (
    <tr className="light-row">
      <td className="sparepart-row-with-pic">
        <div className="row-pic">
          {sparepart.sparepart_image_url && (
            <img
              alt="Sparepart-thumb"
              src={
                sparepart.sparepart_image_url
                  ? sparepart.sparepart_image_url
                  : imgNotFound
              }
            />
          )}
        </div>
        <div className="sparepart-double-row">
          <div className="sparepart-index">
            {sparepart.translated_description}
          </div>
          <div className="sparepart-sescription">{sparepart.index}</div>
        </div>
      </td>
      <td className="sparepart-double-row sparepart-normal-row">
        <SuitableForCell sparepart={sparepart} />
      </td>
      <td className="sparepart-normal-row">
        <div className="quantity-modificator-cell">
          <div className="quantity-modificator">
            <button
              data-testid="minus"
              disabled={sparepart.count === 1}
              className="quantity-button"
              onClick={() => updateCart(sparepart, -1)}>
              -
            </button>
            <div className="quantity-number">{sparepart.count}</div>
            <button
              data-testid="plus"
              className="quantity-button"
              onClick={() => updateCart(sparepart, 1)}>
              +
            </button>
          </div>
        </div>
      </td>
      <td className="sparepart-normal-row">
        <div
          data-testid="delete"
          onClick={() => removeFromCart(sparepart)}>
          <TrashIcon />
        </div>
      </td>
    </tr>
  );
};

export default CartModalRow;
