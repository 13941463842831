import React, { useEffect, useState } from 'react';
import './profile.scss';
import axios from 'axios';
import translate from 'utils/translate';
import { connect } from 'react-redux';
import { showError } from '../../actions/actions';

const Appearance = ({
  initialLanguage,
  visibility,
  changeLanguage,
  toggleShowProducts
}) => {
  const [languages, setLanguages] = useState([]);
  const [language, setLanguage] = useState(initialLanguage);

  const langList = [];

  useEffect(() => {
    axios({ url: '/api/help/languages/' })
      .then((response) => setLanguages(response.data))
      .catch((error) => showError(error));
  }, []);

  if (languages) {
    Object.keys(languages).forEach((key) => {
      if (languages.hasOwnProperty(key)) {
        langList.push(
          <div
            className="select-menu-item"
            key={key}
            onClick={() => {
              changeLanguage(key);
              setLanguage(key);
            }}>
            {languages[key]}
          </div>
        );
      }
    });
  }

  return (
    <div className="preferences-section mt-2">
      <h3 className="preferences-section-header">{translate('Settings')}</h3>
      <div>
        <div className="preferences-container-fieldset">
          <fieldset className="preferences-fieldset">
            <legend>{translate('Language')}</legend>
            {languages && (
              <div
                className="dropdown"
                data-cy="language-dropdown">
                <a
                  className="btn user-menu w-100 my-custom-select dropdown-toggle"
                  href=" "
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  <span className="w-auto float-start">
                    {languages[language]}
                  </span>
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right dropdown-menu-custom w-100"
                  aria-labelledby="dropdownMenuLink">
                  {langList}
                </div>
              </div>
            )}
          </fieldset>
        </div>

        <div className="preferences__filtersList_container_items">
          <label className="preferences__filtersList_label">
            <input
              value=""
              type="checkbox"
              checked={visibility}
              onChange={toggleShowProducts}
              className="preferences__filtersList_input"
            />
            <div>
              <p className="preferences__filtersList_input_text">
                {translate('Show test products')}
              </p>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    initialLanguage: state.appState.preferences.language
  };
};

export default connect(mapStateToProps, undefined)(Appearance);
