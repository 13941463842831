import { useEffect } from 'react';
import parseMsg from 'utils/parseMsg';
import { connect } from 'react-redux';
import { enqueueSnackbar } from 'notistack';

const ErrorAlert = (props) => {
  useEffect(() => {
    if (props.lastMsg) {
      let unparsedMsg = props.lastMsg;
      if (unparsedMsg) {
        let res = parseMsg(unparsedMsg.msg, unparsedMsg.type);
        enqueueSnackbar(res.msg, { variant: res.variant });
      }
    }
  }, [props, props.lastMsg]);
  return null;
};

const mapStateToProps = (state) => {
  return {
    lastMsg: state.appState.lastMsg
  };
};

export default connect(mapStateToProps, undefined)(ErrorAlert);
