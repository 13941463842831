const customStyles = {
  tableWrapper: {
    style: {
      height: '20rem'
    }
  },
  cells: {
    style: {
      whiteSpace: 'revert'
    }
  },
  headCells: {
    style: {
      fontSize: '12px',
      fontWeight: 500,
      paddingLeft: '16px',
      paddingRight: '16px'
    }
  },
  expanderRow: {
    style: {
      backgroundColor: 'transparent'
    }
  },
  expanderButton: {
    style: {
      backgroundColor: 'transparent',
      borderRadius: '2px',
      transition: '1.75s',
      height: '100%',
      width: '100%',
      color: '#393b41',
      '&:hover:enabled': {
        cursor: 'pointer'
      },
      '&:disabled': {
        color: '#d5d5d5',
        cursor: 'default'
      },
      '&:hover:not(:disabled)': {
        cursor: 'pointer',
        backgroundColor: 'transparent'
      },
      '&:focus': {
        outline: 'none',
        backgroundColor: 'transparent'
      },
      svg: {
        margin: 'auto'
      }
    }
  },
  table: {
    style: {
      padding: '10px',
      // height: '100%',
      background: '$main-background-color'
    }
  },
  rows: {
    style: {
      background: '$main-background-color'
    }
  },
  head: {
    style: {
      // background: 'red'
    }
  },
  headRow: {
    style: {
      minHeight: '56px',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      marginBottom: '9px',
      boxShadow: '8px 8px 15px rgba(50, 52, 58, 0.05);'
    },
    denseStyle: {
      minHeight: '32px'
    }
  }
};

export default customStyles;
