import React, { useEffect, useState } from 'react';
import './DeviceInfo.scss';
import Circle from '../../../../../assets/svg/circle';
import translate from '../../../../../utils/translate';
import Question from '../../../../../assets/svg/question';
import Tooltip from 'react-tooltip-lite';
import { Spinner2 } from '../../../../../components/Spinners/Spinners';
import axios from 'axios';

const ProductStatusObj = {
  product_status_ok: 'OK',
  product_status_error: 'ERROR',
  product_status_warning: 'WARNING',
  product_status_ready: 'READY'
};

const DeviceInfo = ({ deviceInfo }) => {
  const [address, setAddress] = useState('');
  const lat = deviceInfo?.latitude;
  const lng = deviceInfo?.longitude;

  const url = `api/help/geolocation/${lat},${lng}`;

  const getAddresses = async () => {
    const response = await axios.get(url).catch((error) => console.log(error));
    const i = response?.data.results.findIndex(
      (e) =>
        e.geometry.location_type === 'ROOFTOP' ||
        e.geometry.location_type === 'RANGE_INTERPOLATED' ||
        e.geometry.location_type === 'GEOMETRIC_CENTER' ||
        e.geometry.location_type === 'APPROXIMATE'
    );
    if (i > -1) {
      setAddress(response.data.results[i].formatted_address);
    }
  };

  useEffect(() => {
    if (lat && lng) getAddresses();
  }, [lat, lng, deviceInfo]);

  const ProductInfoItem = ({ title, info, children }) => (
    <div className="product-info-card-list-item">
      <p>{translate(title)}</p>
      <p>{info || '—'}</p>
      {children}
    </div>
  );

  return (
    <>
      <div className="product-info-card-header">
        <p>{translate('Device info')}</p>
        {!deviceInfo && <Spinner2 />}
      </div>

      {deviceInfo && (
        <>
          <div className="product-info-card-img">
            {deviceInfo.product_type?.images[0] && (
              <img
                src={deviceInfo.product_type.images[0].url}
                alt={deviceInfo.product_type.images[0].description}
              />
            )}
          </div>
          <div className="product-info-card-list">
            <div className="product-info-card-list-item">
              <p>{translate('Device status')}</p>
              <div className="status-block">
                {deviceInfo.status && (
                  <>
                    <Circle color={deviceInfo.status.color} />
                    <p style={{ marginRight: '2px' }}>
                      {ProductStatusObj[`${deviceInfo.status.key}`]}
                    </p>
                    <Tooltip
                      content={
                        <div className="dashboard-tooltip">
                          <div className="dashboard-tooltip-content">
                            {translate(`${deviceInfo.status.name}`)}
                          </div>
                        </div>
                      }
                      tagName="div"
                      mouseOutDelay={200}
                      arrowSize={8}
                      className="target">
                      <Question />
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
            <ProductInfoItem
              title="index"
              info={deviceInfo.product_type?.product_index}
            />
            <ProductInfoItem
              title="warranty_description"
              info={deviceInfo.warranty_info?.warranty_description}
            />
            <ProductInfoItem
              title="Start of warranty"
              info={deviceInfo.warranty_info?.warranty_start_date}
            />
            <ProductInfoItem
              title="End of warranty"
              info={deviceInfo.warranty_info?.warranty_end_date}
            />
            <ProductInfoItem
              title="next_inspection_date"
              info={deviceInfo.warranty_info?.next_inspection_date}
            />
            <ProductInfoItem
              title="www_dashboard_installation"
              info={deviceInfo.date_installed?.split('T')[0]}
            />
            <ProductInfoItem
              title="CommissioningAction"
              info={deviceInfo.date_launched?.split('T')[0]}
            />
            <ProductInfoItem
              title="department"
              info={deviceInfo.supporting_department_code}
            />
            <ProductInfoItem
              title="Technician"
              info={deviceInfo.service_support ? `${deviceInfo.service_support.first_name} ${deviceInfo.service_support.last_name}` : '-'}
            />
            <ProductInfoItem
              title="Location"
              info={deviceInfo.location}>
              <a
                href={`https://maps.google.com/?ll=${deviceInfo.latitude},${deviceInfo.longitude}`}
                rel="noopener noreferrer"
                target="_blank">
                {address || '-'}
              </a>
            </ProductInfoItem>
          </div>
        </>
      )}
    </>
  );
};

export default DeviceInfo;
