const warrantyInitialState = { loading: false, error: '', success: '' };

export const WarrantyExtensionReducer = (
  state = warrantyInitialState,
  action
) => {
  switch (action.type) {
    case 'try_to_extend_warranty':
      return {
        loading: true,
        success: '',
        error: ''
      };
    case 'warranty_extension_error':
      return {
        error: action.payload,
        loading: false,
        success: ''
      };
    case 'warranty_extension_success':
      return {
        success: action.payload,
        loading: false,
        error: ''
      };

    default:
      return state;
  }
};
