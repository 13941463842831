import React from 'react';
import translate from 'utils/translate';
import 'react-datepicker/dist/react-datepicker.css';
import './UniversalActionForm.scss';
import axios from 'axios';
import store from 'store';
import { loadProductHistory, showError } from 'actions/actions';
import 'rc-datetime-picker/dist/picker.min.css';
import { renderParameterField } from './ActionTypes';
import { FieldLabel } from './ActionTypes/FieldLabel';
import ImageUpload from '../../../../../components/ImageUpload/ImageUpload';
import { yieldImageUploading } from '../../../../../utils/yeldImageUploading';

const behalfActionTypes = ["ServiceAction", "CommissioningAction", "InstallAction"];

class UniversalActionForm extends React.Component {
  constructor( props ) {
    super(props);
    this._default_state = {
      selectedIndex: [],
      form_data: {
        description: ''
      },
      bypass_field: [],
      isMapPickerVisible: false,
      isPartPickerVisible: false,
      fieldErrorMap: new Map(),
      selectedOption: null,
      iBoilerServiceIntercept: false,
      pictures: [],
      postingAction: false,
      commissioningAction: {},
      behalf_of_users: []
    };
    this.state = this._default_state;
  }

  // TODO move loadProductCommisioningAction to actions.jsx
  loadProductCommisioningAction = () => {
    axios({
      url: 'api/product/action/CommissioningAction/' + this.props.currentProduct.serial_number
    })
      .then(( response ) => {
        this.setState({ commissioningAction: response.data });
      })
      .catch(( error ) => showError(error));
  };

  loadBehalfOfUsersList = () => {
    const actionType = this.getCurrentAction().type;

    if (behalfActionTypes.includes(actionType)) {
      axios
        .get(`api/product/on_behalf_of/${actionType}/${this.props.productBarcode}`)
        .then(( response ) => {
          const list = response.data;
          if (list.length === 0) return;

          const listToRender = list.map(( user ) => {
            const { first_name, last_name, email, department } = user;
            return {
              label: `${last_name} ${first_name} (${department})`, value: email
            };
          });
          this.setState({
            behalf_of_users: listToRender
          });
        })
        .catch(( error ) => showError(error));
    }
  };

  componentDidUpdate( nextProps, nextState ) {
    if (this.props.actionType !== nextProps.actionType) {
      this.setState(this._default_state);
    }
  }

  componentDidMount() {
    if (this.props.actionType === 'CommissioningAction') {
      this.loadProductCommisioningAction();
    }

    if (this.props.isManager) {
      this.loadBehalfOfUsersList();
    }
  }

  addPictureToHistoryEntry = async ( actionID ) => {
    await yieldImageUploading(this.state.pictures, actionID, false)
    this.props.setOpenModal(false);
    loadProductHistory(this.props.productBarcode);
  };

  postNewAction = ( currentAction, form_data, productBarcode, additionalHeaders = {}, uploadedPicture = false ) => {
    const config = {
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', ...additionalHeaders
      }
    };

    if (!currentAction) {
      return;
    }

    if (this.state.pictures.some(el => !el?.file_cat)) {
      return showError(translate('Define category for new images'))
    }

    this.setState({ postingAction: true });
    // TODO move to actions.jsx
    axios
      .post('api/' + currentAction.url + productBarcode, form_data, config)
      .then(( response ) => {
        if (uploadedPicture) {
          const newlyAddedActionID = response.data.last_inserted_action_id;
          return this.addPictureToHistoryEntry(newlyAddedActionID);
        }
        this.props.setOpenModal(false);
        store.dispatch({
          type: 'showMsg', value: {
            msg: translate('Action added successfull.'), type: 'success'
          }
        });
        loadProductHistory(this.props.productBarcode);
      })
      .catch(( error ) => {
        this.setState({ postingAction: false });
        showError(error);
      });
  };

  performAction = () => {
    const currentAction = this.getCurrentAction();
    if (this.validate_form(currentAction, this.state.form_data)) {
      this.postNewAction(currentAction, this.state.form_data, this.props.productBarcode, { iBoilerServiceIntercept: this.state.iBoilerServiceIntercept }, this.state.pictures.length > 0);
    }
  };

  changeFieldValue = ( val, name ) => {
    this.setState({
      form_data: {
        ...this.state.form_data, [name]: val
      }
    });
  };

  getCurrentAction = () => {
    if (this.props.actionType === 'CommissioningAction' && this.state.commissioningAction.params) {
      return {
        ...this.state.commissioningAction,
        file_upload_enabled: true,
        service_intercept: true,
        url: 'product/action/CommissioningAction/'
      };
    }

    return this.props.actionList.find(( el ) => {
      return el.type === this.props.actionType;
    });
  };

  checkIfEmpty( field ) {
    return field === '' || field === null || field === undefined;
  }

  validate_form( currentAction, form_data ) {
    let fieldErrorMap = new Map();

    for (let paramDef of currentAction.params) {
      this.setState({ fieldErrorMap: new Map() });
      //if some field is missing
      if (!form_data.hasOwnProperty(paramDef.name) || form_data[paramDef.name] === '' || form_data[paramDef.name] === null) {
        //if field is not set and its a bool, we provide it with default false
        if (paramDef.type === 'bool' && paramDef.optional) {
          this.setState({
            form_data: {
              ...this.state.form_data, [paramDef.name]: false
            }
          });
          form_data = {
            ...this.state.form_data, [paramDef.name]: false
          };
          this.state.form_data = form_data;
        }
        else if (paramDef.optional === true || paramDef.type === 'checklist') {
          continue;
        }
        else if (!paramDef.optional && paramDef.type === 'file_attachment' && this.state.pictures.length > 0) {
          continue;
        }
        else {
          store.dispatch({
            type: 'showMsg', value: {
              msg: translate('Missing field: ' + paramDef.displayed_name)
            }
          });

          this.setState({
            fieldErrorMap: fieldErrorMap.set(paramDef.name, translate('Field is mandatory'))
          });
        }
      }
      if (paramDef.type === 'bool' && form_data[paramDef.name] === false && paramDef.optional === false) {
        if (this.state.bypass_field && this.state.bypass_field[paramDef.name]) {
          continue;
        }
        this.setState({
          fieldErrorMap: fieldErrorMap.set(paramDef.name, translate('Field is mandatory'))
        });
        return false;
      }
      if (paramDef.type === 'checklist') {
        for (let checklistParamDef of paramDef.checklist_parameters) {
          if (form_data[paramDef.name] === undefined || (form_data[paramDef.name][checklistParamDef.name] === undefined && checklistParamDef.optional === false)) {
            this.setState({
              fieldErrorMap: fieldErrorMap.set(checklistParamDef.name, translate('Field is mandatory'))
            });
          }
        }
      }
    }
    return fieldErrorMap.size === 0;
  }

  serviceIntercept = () => {
    return (<div className='intercept-field'>
        <div className='label'>{translate('ask_about_intercept_title')}</div>
        <div className='intercept-checkbox'>
          <input
            type='checkbox'
            checked={this.state.iBoilerServiceIntercept}
            id='serviceIntercept'
            onChange={() => this.setState({
              iBoilerServiceIntercept: !this.state.iBoilerServiceIntercept
            })}
          />
          <label htmlFor='serviceIntercept'>
            <span> {translate('ask_about_intercept_message')}</span>
          </label>
        </div>
      </div>);
  };

  onDropFiles = ( pictures ) => {
    this.setState(prevState => ({
      ...prevState, pictures
    }));
  };

  render() {

    const { fieldErrorMap, form_data, behalf_of_users, postingAction, pictures } = this.state;
    const currentAction = this.getCurrentAction();

    const fieldsList = currentAction?.params.map(( obj, index ) =>
      renderParameterField(obj, index, fieldErrorMap, this.changeFieldValue, form_data, this.props.currentProduct, behalf_of_users)
    ) || null;

    const hasServiceEntitlements = this.props.isService;
    const fileUploadObj = currentAction?.params.find(( el ) => el.type === 'file_attachment');

    return (
      <div
        className={`action-fields-container ${postingAction
          ? 'posting-action'
          : ''}`}>
        <div className='action-fields-fieldslist'>{fieldsList}</div>

        {currentAction.file_upload_enabled && (<div className='file-upload-input'>
            <div className='brand-new-input-label'>
              <FieldLabel obj={fileUploadObj} />
            </div>
            <ImageUpload
              actionType={this.props.actionType}
              onDrop={this.onDropFiles}
              droppedFile={pictures}
            />
          </div>)}
        {currentAction.service_intercept && this.props.currentProduct.service_support && store.getState().user.user_name !== this.props.currentProduct.service_support.email && hasServiceEntitlements && this.serviceIntercept()}

        <div
          className={`actions-list-bottom-section ${postingAction
            ? 'disabled'
            : ''}`}>
          <button
            disabled={postingAction}
            className='aic-btn-outline'
            onClick={() => this.props.setOpenModal(false)}>
            {translate('Cancel')}
          </button>
          <button
            disabled={postingAction}
            className='aic-btn-black'
            onClick={this.performAction}
            style={{ marginLeft: '8px' }}>
            {translate('Register action')}
          </button>
        </div>
      </div>);
  }
}

export default UniversalActionForm;
