import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './../UniversalActionForm.scss';
import Select from 'react-select';
import { FieldLabel } from './FieldLabel';
import { LastActionInfo } from '../LastActionInfo';

export const ActionSelect = (props) => {
  const { obj, onChangeInput, inputValue, errorMessage, options, placeholder = '' } = props;

  return (
    <div
      key={obj?.name}
      className="input-container select-field">
      <div className="brand-new-input">
        <div className="brand-new-input-label">
          <FieldLabel obj={obj} />
        </div>
        <Select
          classNamePrefix="react-select"
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#A6C8EF'
            }
          })}
          placeholder={placeholder}
          value={inputValue}
          options={options}
          onChange={(selectedOption) => {
            onChangeInput(selectedOption);
          }}
        />
      </div>
      <LastActionInfo last_value={obj?.last_value} />

      {errorMessage}
    </div>
  );
};
