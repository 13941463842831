import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './../UniversalActionForm.scss';
import 'rc-datetime-picker/dist/picker.min.css';
import Fade from 'react-reveal/Fade';
import { makeTextHighlighted } from '../../../../../../utils/makeTextHighlighted';
import { FieldLabel } from './FieldLabel';
import { v4 as uuidv4 } from 'uuid';

export const ActionPartPicker = ({
  obj,
  onChangeInput,
  errorMessage,
  currentProduct
}) => {
  const [searchedPart, setSearchedPart] = useState('');
  const [showPartsPicker, setShowPartsPicker] = useState(false);

  // Extract all parts from spare parts groups
  const allParts = currentProduct.product_type.sparepart_groups.reduce(
    (sum, val) => {
      return sum.concat(val.spareparts);
    },
    []
  );

  // Separate s-kits from single parts
  const skitsArray = allParts.filter((part) => part.is_set);
  const singlePartsArray = allParts.filter((part) => !part.is_set);

  // Make sets of parts by s-kits
  const partSets = singlePartsArray.map(
    ({ parent_set_index, index, description }) => ({
      [parent_set_index]: { index, description }
    })
  );

  // Remove duplicates of s-kits & add description to each s-kit
  const helpObject = {};
  partSets.forEach((el) => {
    const sindex = Object.keys(el)[0];
    const skitItem = skitsArray.find((item) => item.index === sindex);
    if (!helpObject[sindex]) {
      helpObject[sindex] = {
        skit: {
          sindex: sindex,
          description: skitItem ? skitItem.description : ''
        },
        singleParts: [el[sindex]]
      };
    } else helpObject[sindex].singleParts.push(el[sindex]);
  });

  const finalPartsList = Object.values(helpObject);

  const applySparepartFilter = (part) => {
    const isPartOfSkit =
      part.skit.sindex.toLowerCase().indexOf(searchedPart.toLowerCase()) >= 0;

    const isPartOfSkitDescription =
      part.skit.description.toLowerCase().indexOf(searchedPart.toLowerCase()) >=
      0;

    let isPartOfIndex;
    let isPartOfDescription;

    part.singleParts.forEach((elem) => {
      isPartOfIndex =
        elem.index.toLowerCase().indexOf(searchedPart.toLowerCase()) >= 0;
    });

    part.singleParts.forEach((elem) => {
      isPartOfDescription =
        elem.description.toLowerCase().indexOf(searchedPart.toLowerCase()) >= 0;
    });

    return (
      isPartOfSkit ||
      isPartOfSkitDescription ||
      isPartOfIndex ||
      isPartOfDescription
    );
  };

  const handleChange = (e) => {
    setShowPartsPicker(false);
    setSearchedPart(e.target.value);
    onChangeInput(e.target.value);
    setShowPartsPicker(true);
  };

  const updateSearchedPart = (part) => {
    setSearchedPart(part);
    onChangeInput(part);
    setShowPartsPicker(false);
  };

  const _renderSparepart = (item) => {
    return (
      <div
        key={uuidv4()}
        className="set-part-container">
        <div
          className="skit-container"
          onClick={() => updateSearchedPart(item.skit.sindex)}>
          <p className="skit-title">
            {makeTextHighlighted(item.skit.sindex, searchedPart)}
          </p>
          <p className="skit-description">
            {makeTextHighlighted(item.skit.description, searchedPart)}
          </p>
        </div>

        {item.singleParts.map((onepart) => (
          <div
            className="single-part-container"
            onClick={() => updateSearchedPart(onepart.index)}
            key={onepart.index}>
            <div className="part-title">
              {makeTextHighlighted(onepart.index, searchedPart)}
            </div>

            <div className="part-subtitle">
              {makeTextHighlighted(onepart.description, searchedPart)}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      key={obj.name}
      className="input-container part-picker-container">
      <div className="brand-new-input">
        <div className="brand-new-input-label">
          <FieldLabel obj={obj} />
        </div>
        <input
          onFocus={() => setShowPartsPicker(true)}
          onBlur={() => setShowPartsPicker(false)}
          autoFocus={true}
          type="text"
          onChange={(e) => handleChange(e)}
          value={searchedPart}
          className="part-search-input"
        />
        <div className={`fade-container-${showPartsPicker ? 'open' : 'close'}`}>
          <Fade
            collapse
            when={showPartsPicker}>
            <div className="parts-list-container">
              {finalPartsList
                .filter(applySparepartFilter)
                .map((sparepart) => _renderSparepart(sparepart))}
            </div>
          </Fade>
        </div>
      </div>
      {errorMessage}
    </div>
  );
};
