import React from 'react';
import WarehouseReceiving from 'assets/svg/productActions/warehouseReceiving';
import Transport from 'assets/svg/productActions/transport';
import ProductInstallation from 'assets/svg/productActions/productInstallation';
import Commissioning from 'assets/svg/productActions/commissioning';
import Maintenance from 'assets/svg/productActions/maintenance';
import Repair from 'assets/svg/productActions/repair';
import PartReplacement from 'assets/svg/productActions/partReplacement';
import WarrantyInspection from 'assets/svg/productActions/warrantyInspection';
import WarrantyExtension from 'assets/svg/productActions/warrantyExtension';
import WarrantyReactivation from 'assets/svg/productActions/warrantyReactivation';
import Issue from 'assets/svg/productActions/issue';
import HideProductHistory from 'assets/svg/productActions/hideProductHistory';
import Registration from 'assets/svg/productActions/registration';
import Service from 'assets/svg/productActions/Sevice';
import translate from './translate';

export const googleApiUrl = process.env.REACT_APP_GOOGLE_API_KEY;
export const MAX_ZOOM = 16;
export const DEFAULT_PASSWORD_DIFFICULTY_BAR_COLOR = '#7A7D85';

export const tristatePossibleValues = [
  {
    key: 'nok',
    displayed_name: 'Nok',
    color: '#EA280A',
    is_comment_required: false,
    value: false
  },
  {
    key: 'ok',
    displayed_name: 'Ok',
    color: '#0EAE4A',
    is_comment_required: false,
    value: true
  }
];

export const UserRoles = {
  END_USER: 'end_user',
  INSTALLATION: 'installation',
  SERVICE: 'service',
  DISTRIBUTION: 'distribution',
  MANAGER: 'manager'
};

export const commentObj = {
  displayed_name: translate('additional info'),
  name: 'comment',
  optional: false
};

export const statuses = [
  {
    label: 'ok',
    color: 'green',
    value: 'product_status_ok'
  },
  {
    label: 'warning',
    color: 'orange',
    value: 'product_status_warning'
  },
  {
    label: 'ready',
    color: '#00ffbb',
    value: 'product_status_ready'
  },
  {
    label: 'error',
    color: 'red',
    value: 'product_status_error'
  }
];

export const actions = {
  WarehouseReceptionAction: () => <WarehouseReceiving />,
  MoveProductAction: () => <Transport />,
  InstallAction: () => <ProductInstallation />,
  CommissioningAction: () => <Commissioning />,
  MaintenanceAction: () => <Maintenance />,
  RepairAction: () => <Repair />,
  PartsExchangeAction: () => <PartReplacement />,
  InspectionAction: () => <WarrantyInspection />,
  WarrantyAction: () => <WarrantyExtension />,
  WarrantyReactivateAction: () => <WarrantyReactivation />,
  DefectAction: () => <Issue />,
  HideHistoryAction: () => <HideProductHistory />,
  SellProductAction: () => <Transport />, // do not used
  RegisterProductAction: () => <Registration />,
  ServiceAction: () => <Service />
};

export const availableLocationTypes = [
  'ROOFTOP',
  'RANGE_INTERPOLATED',
  'GEOMETRIC_CENTER',
  'APPROXIMATE'
];

export const datePlaceholder = 'YYYY-MM-DD';
export const userNamePlaceholder = 'eg. John Wayne';
export const minSNLength = 10;

export const fieldsWithoutMargins = [
  'lastActionDateFrom',
  'lastActionDateTo',
  'lastInspectionDateFrom',
  'lastInspectionDateTo',
  'nextInspectionDateFrom',
  'nextInspectionDateTo'
];

export const fieldsWithChips = [
  'power',
  'name',
  'sn',
  'index',
  'location',
  'wtyStartFrom',
  'wtyStartTo',
  'wtyEndFrom',
  'wtyEndTo',
  'installDateFrom',
  'installDateTo',
  'launchDateFrom',
  'launchDateTo',
  'technician',
  'lastActionTechnician',
  'lastActionDateFrom',
  'lastActionDateTo',
  'nextInspectionDateFrom',
  'nextInspectionDateTo',
  'lastInspectionDateFrom',
  'lastInspectionDateTo'
];

export const timelineItemHiddenKeys = [
  'username',
  'type',
  'action_date',
  'displayed_name',
  'serial_number',
  'attachments',
  'user_action_date',
  'latitude',
  'longitude',
  'id',
  'file_url_title'
];

export const routingTable = [
  {
    title: 'Login',
    path: '/login'
  },
  {
    title: 'Spareparts',
    path: '/spareparts'
  },
  {
    title: 'aicON Tracker',
    path: '/product_info'
  },
  {
    title: 'My actions',
    path: '/user_history'
  }
];

export const theme = {
  chipsContainer: {
    display: 'flex',
    position: 'relative',
    border: 'none',
    backgroundColor: '#fff',
    minHeight: 24,
    alignItems: 'center',
    flexWrap: 'wrap',
    fontSize: '1rem',
    padding: '0 0 0.5rem 0',
    margin: '0',
    borderRadius: '0',
    ':focus': {
      border: '1px solid #aaa'
    }
  },
  container: {
    flex: 1
  },
  containerOpen: {},
  input: {
    border: 'none',
    outline: 'none',
    boxSizing: 'border-box',
    width: '100%',
    padding: '8px 0'
  },
  suggestionsContainer: {},
  suggestionsList: {
    position: 'absolute',
    border: '1px solid #ccc',
    zIndex: 10,
    left: 0,
    top: '100%',
    width: '100%',
    backgroundColor: '#fff',
    listStyle: 'none',
    padding: 0,
    margin: 0
  },
  suggestion: {
    padding: '5px 15px'
  },
  suggestionHighlighted: {
    background: '#ddd'
  },
  sectionContainer: {},
  sectionTitle: {}
};

export const chipTheme = {
  chip: {
    padding: '5px',
    background: '#F2F2F3',
    margin: '0 8px 0 0',
    borderRadius: 3,
    cursor: 'default',
    color: '##32343A',
    fontSize: '1rem'
  },
  chipSelected: {
    background: '#888'
  },
  chipRemove: {
    fontWeight: 'bold',
    cursor: 'pointer',
    ':hover': {
      color: '#32343A'
    }
  }
};
