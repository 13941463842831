import React, { useCallback, useEffect, useState } from 'react';
import './DeviceDocuments.scss';
import translate from '../../../../../utils/translate';
import { Spinner2 } from '../../../../../components/Spinners/Spinners';
import DocumentLink from '../../../../../components/DocumentLink/DocumentLink';
import { v4 as uuidv4 } from 'uuid';

const DeviceDocuments = ({ deviceInfo }) => {
  const [documents, setDocuments] = useState([]);

  const createDocumentsArray = useCallback(() => {
    const productsDocs = deviceInfo.product_type.documents;
    const warrantyDocs = deviceInfo.warranty_info.warranty_specs;

    return [...productsDocs, warrantyDocs]
      .filter(({ url, description }) => url && description)
      .map(({ url, description }) => (
        <DocumentLink
          key={uuidv4()}
          url={url}
          description={description}
        />
      ));
  }, [deviceInfo]);

  useEffect(() => {
    if (deviceInfo) {
      const documents = createDocumentsArray();
      setDocuments(documents);
    }
  }, [deviceInfo, createDocumentsArray]);

  return (
    <>
      <div className="product-info-card-header">
        <p>{translate('Documents')}</p>
        {!deviceInfo && <Spinner2 />}
      </div>
      {deviceInfo && (
        <div className="product-info-card-documents">{documents}</div>
      )}
    </>
  );
};
export default DeviceDocuments;
