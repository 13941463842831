import React, { useEffect, useState } from 'react';
import '../TriStateToggleSwitch.scss';
import 'react-datepicker/dist/react-datepicker.css';
import './../UniversalActionForm.scss';
import 'rc-datetime-picker/dist/picker.min.css';
import { FieldLabel } from './FieldLabel';
import { ActionComment } from './ActionComment';
import { LastActionInfo } from '../LastActionInfo';
import { commentObj, tristatePossibleValues } from 'utils/const';
import ThumbDownIcon from '../../../../../../assets/svg/thumbDown';
import ThumbUpIcon from '../../../../../../assets/svg/thumbUp';

export const ActionTristate = (props) => {
  const { obj, onChangeInput, inputValue, errorMessage } = props;
  if (obj.type !== 'tristate') {
    obj.possible_values = tristatePossibleValues;
  }

  const determineIndicatorDirection = (input) => {
    if (input.value === obj.possible_values[0].value) {
      return 'left';
    }
    if (input.value === obj.possible_values[1].value) {
      return 'right';
    }
    return '';
  };
  const initialValue = { value: undefined, comment: '' };
  const [showCommentField, setShowCommentField] = useState(false);
  const [triStateValue, setTristateValue] = useState(initialValue);
  const [indicatorDirection, setIndicatorDirection] = useState(() =>
    determineIndicatorDirection(
      inputValue === undefined ? initialValue : inputValue
    )
  );

  useEffect(() => {
    setTriStateFlags(inputValue);
  }, [inputValue]);
  const setTriStateFlags = (input) => {
    if (input === undefined) {
      input = initialValue;
    }
    if (input.value === obj.possible_values[0].value) {
      checkIfShowComment(obj.possible_values[0]);
      setIndicatorDirection('left');
    }
    if (input.value === obj.possible_values[1].value) {
      checkIfShowComment(obj.possible_values[1]);
      setIndicatorDirection('right');
    }
    if (input.value === undefined) {
      setIndicatorDirection('');
      setShowCommentField(false);
    }
    setTristateValue(input);
  };
  const returnTristateValue = (val) => {
    setTimeout(() => {
      onChangeInput(val);
    }, 0);
  };
  const setComment = (e) => {
    returnTristateValue({
      value: triStateValue.value,
      comment: e.target.value
    });
  };
  const switchHandler = (input) => {
    setTriStateFlags(input);
    returnTristateValue({ value: input, comment: triStateValue.comment });
  };
  const checkIfShowComment = (option) => {
    if (option.is_comment_required) {
      setShowCommentField(true);
    } else {
      setShowCommentField(false);
    }
  };

  return (
    <div
      key={obj.name}
      className="input-container"
      style={{ marginBottom: '1.2rem' }}>
      <div>
        <FieldLabel obj={obj} />
      </div>
      <div className="my-toggle">
        {obj.possible_values[0].key === 'nok' && <div className="middle-dot" />}
        <div
          data-cy="my-toggle-left"
          className="my-toggle-btn"
          onClick={() => switchHandler(obj.possible_values[0].value)}>
          {obj.possible_values[0].key === 'nok' ? (
            <div
              style={{
                transform: 'rotate(180deg)',
                marginTop: '4px'
              }}>
              <ThumbDownIcon active={indicatorDirection === 'left'} />
            </div>
          ) : (
            <span
              style={{
                fontWeight: indicatorDirection === 'left' ? 'bold' : ''
              }}>
              {obj.possible_values[0].displayed_name}
            </span>
          )}
        </div>
        <div
          className={`my-toggle-btn center ${
            obj.type !== 'tristate' ? 'disabled' : ''
          }`}
          onClick={() => {
            obj.type === 'tristate' && switchHandler(undefined);
          }}
        />
        <div
          data-cy="my-toggle-right"
          className="my-toggle-btn"
          onClick={() => switchHandler(obj.possible_values[1].value)}>
          {obj.possible_values[1].key === 'ok' ? (
            <ThumbUpIcon active={indicatorDirection === 'right'} />
          ) : (
            <span
              style={{
                fontWeight: indicatorDirection === 'right' ? 'bold' : ''
              }}>
              {obj.possible_values[1].displayed_name}
            </span>
          )}
        </div>
        <div className={`indicator ${indicatorDirection} animated`} />
      </div>
      <LastActionInfo last_value={obj.last_value} />
      {showCommentField && (
        <ActionComment
          obj={commentObj}
          onChangeInput={(e) => setComment(e)}
          inputValue={triStateValue.comment}
          errorMessage={errorMessage}
        />
      )}
      {errorMessage}
    </div>
  );
};
