import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './../UniversalActionForm.scss';
import 'rc-datetime-picker/dist/picker.min.css';
import { FieldLabel } from './FieldLabel';
import { ActionTristate } from './ActionTristate';
import { errorMessage } from './../ActionTypes';

export const ActionCheckbox = (props) => {
  const { obj, onChangeInput, inputValue, fieldErrorMap } = props;
  const changeFieldValue = (val, name) => {
    onChangeInput({ ...inputValue, [name]: val });
  };
  return (
    <div className="input-container checklist-container">
      <div className="brand-new-input-label">
        <FieldLabel obj={obj} />
      </div>
      {obj.checklist_parameters.map((param, index) => {
        return (
          <ActionTristate
            key={param.name + index}
            obj={param}
            onChangeInput={(val) => changeFieldValue(val, param.name)}
            inputValue={inputValue[param.name]}
            errorMessage={errorMessage(param, fieldErrorMap)}
          />
        );
      })}
    </div>
  );
};
