import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import './app.scss';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => {
  let redirectAfterLogin;
  if (
    rest.location.pathname !== '/dashboard' &&
    localStorage.getItem('redirectAfterLogin')
  ) {
    localStorage.setItem('redirectAfterLogin', rest.location.pathname);
    redirectAfterLogin = rest.location.pathname;
  } else {
    redirectAfterLogin = localStorage.getItem('redirectAfterLogin');
  }

  if (redirectAfterLogin) {
    localStorage.removeItem('redirectAfterLogin');
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: redirectAfterLogin,
              state: { from: props.location }
            }}
          />
        )}
      />
    );
  }

  if (!rest.user) {
    if (rest.location.pathname.includes('/login')) {
      return;
    }
    localStorage.setItem('redirectAfterLogin', rest.location.pathname);
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, null)(PrivateRoute);
