import axios from 'axios';
import Cookies from 'js-cookie';

const supportedLanguages = ["en", "de", "es", "fr", "it", "nl", "pl", "pt", "ru", "zh"];

export const appStateReducer = (state, action) => {
  if (typeof state === 'undefined') {
    let preferences = JSON.parse(localStorage.getItem('preferences'));
    let translations = JSON.parse(localStorage.getItem('translations')) || {};

    if (!preferences) {
      // in case we have language in format "de-CH"
      const browserLanguage = navigator.language.split("-")[0];
      const defaultLanguage = supportedLanguages.includes(browserLanguage) ? browserLanguage : supportedLanguages[0];
      preferences = { language: defaultLanguage, colorMode: 'Dark' };
      localStorage.setItem('preferences', JSON.stringify(preferences));
      axios.defaults.headers.common['iBoilerForceLanguage'] =
        preferences.language;
    }

    axios.defaults.headers.common['iBoilerForceLanguage'] =
      preferences.language;
    return {
      boiler: undefined,
      productWithParts: undefined,
      rerender: false,
      lastMsg: undefined, //  {msg, type:('success', 'error', 'warning')}
      translations: translations,
      preferences: preferences,
      departments: [],
      languages: {},
      legalDocuments: {},
      openCookiesModal: !Cookies.get('cookiePermissions'),
      warehouses: [],
      isImagesLoading: false
    };
  }

  switch (action.type) {
    case 'showMsg':
      return {
        ...state,
        lastMsg: action.value
      };

    case 'toggleImagesLoading':
      return {
        ...state,
        isImagesLoading: action.value
      };

    case 'setRedirectAfterLogin':
      return {
        ...state,
        redirectAfterLogin: action.value
      };

    case 'setLocaleDict':
      state.translations[action.value.Locale] = action.value;
      localStorage.setItem('translations', JSON.stringify(state.translations));
      return {
        ...state
      };

    case 'setLanguages':
      return {
        ...state,
        languages: action.value
      };

    case 'setLanguage':
      let preferences = {
        ...state.preferences,
        language: action.value
      };
      localStorage.setItem('preferences', JSON.stringify(preferences));
      axios.defaults.headers.common['iBoilerForceLanguage'] = action.value;

      return {
        ...state,
        preferences: preferences,
        rerender: true
      };

    case 'storeDepartments':
      let selectableDepartmentList = action.value.map((department) => {
        return { ...department, value: department.code };
      });
      return {
        ...state,
        departments: selectableDepartmentList
      };

    case 'storeWarehouses':
      let selectableWarehouses = action.value.map((warehouse) => {
        return { ...warehouse, value: warehouse.name };
      });
      return {
        ...state,
        warehouses: selectableWarehouses
      };

    case 'storeLegalDocuments':
      return {
        ...state,
        legalDocuments: action.value
      };

    case 'togglePreferencesModal':
      return {
        ...state,
        isPreferencesModalOpen: action.value
      };

    case 'openCookiesModal':
      return {
        ...state,
        openCookiesModal: true
      };

    case 'closeCookiesModal':
      return {
        ...state,
        openCookiesModal: false
      };

    case 'clickedClusterer':
      state.clustererContainer = action.value;
      return state;

    case 'partListDescription':
      return {
        ...state,
        partListDescription: action.value
      };

    default:
      return state;
  }
};
