import React from 'react';
import './svgstyles.scss';

const CloseBlackButton = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.8999 9.4L23.4999 8L16.8999 14.6L10.2999 8L8.8999 9.4L15.4999 16L8.8999 22.6L10.2999 24L16.8999 17.4L23.4999 24L24.8999 22.6L18.2999 16L24.8999 9.4Z"
        fill="#32343A"
      />
    </svg>
  );
};

export default CloseBlackButton;
