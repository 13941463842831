const initialSparepartsState = {
  groupedProductTypes: [],
  userLoadHistory: [],
  selectedSparepartsGroup: undefined,
  isFindPartsModalOpen: false,
  closeAllPartDetails: false,
  sparepartsSelectedPart: undefined
};

export const sparepartsReducer = (state = initialSparepartsState, action) => {
  switch (action.type) {
    case 'openFindPartsModal':
      return {
        ...state,
        isFindPartsModalOpen: true
      };

    case 'closeFindPartsModal':
      return {
        ...state,
        isFindPartsModalOpen: false
      };

    case 'setSpareparts':
      return {
        ...state,
        groupedProductTypes: action.value
      };

    case 'setProductWithSpareparts':
      return {
        ...state,
        productWithSpareparts: action.value
      };

    case 'selectItemOnTree':
      return {
        ...state,
        selectedSparepartsGroup: action.value.revision,
        currentGroup: action.value.currentGroup
      };

    case 'choosePartFromSvg':
      return {
        ...state,
        sparepartsSelectedPart: action.value.index
      };

    case 'setUserLoadHistory':
      return {
        ...state,
        userLoadHistory: action.value
      };

    case 'close_parts_details':
      return {
        ...state,
        closeAllPartDetails: !state.closeAllPartDetails
      };

    default:
      return state;
  }
};
