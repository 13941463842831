import React, { useRef } from 'react';
import './SwitchRecentProducts.scss';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import RecentProductsBeforeSearch from './RecentProductGroups/RecentProductsBeforeSearch';
import AvailableSerialNumbersWithSearch from './RecentProductGroups/AvailibleSerialNumbersWithSearch';
import { useSelector } from 'react-redux';

const SwitchRecentProducts = ({ searchPhrase }) => {
  const { userLoadHistory } = useSelector((state) => state.spareparts);

  const recentProductsRef = useRef(null);
  const availableSNRef = useRef(null);
  const nodeRef = !searchPhrase ? recentProductsRef : availableSNRef;

  //these components should be passed as variables into CSSTransition "key" property
  const recentProducts = (
    <RecentProductsBeforeSearch
      recentProductsRef={recentProductsRef}
      userLoadHistory={userLoadHistory}
    />
  );

  const availableSNs = (
    <AvailableSerialNumbersWithSearch
      availableSNRef={availableSNRef}
      searchPhrase={searchPhrase}
      userLoadHistory={userLoadHistory}
    />
  );

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={!searchPhrase ? recentProducts : availableSNs}
        nodeRef={nodeRef}
        addEndListener={(done) => {
          nodeRef.current.addEventListener('transitionend', done, false);
        }}
        classNames="fade">
        {!searchPhrase ? recentProducts : availableSNs}
      </CSSTransition>
    </SwitchTransition>
  );
};

export default SwitchRecentProducts;
