import React, { Component } from 'react';
import MapWithMarkerClusterer from './MapWithMarkerCLusterer';

class ShelterMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMarker: false
    };
    this.child = React.createRef();
  }

  handleClick = (marker, event) => {
    this.props.handlePinClick(marker);
    this.setState({ selectedMarker: marker });
  };

  centerOnMap = (product) => {
    this.child.current.centerOnMap(product);
  };

  render() {
    return (
      <div style={{ position: 'relative', height: '100%' }}>
        <MapWithMarkerClusterer
          selectedMarker={this.state.selectedMarker}
          markers={this.props.pins}
          onClick={this.handleClick}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          handlePinClick={this.handleClick}
          ref={this.child}
        />
      </div>
    );
  }
}

export default ShelterMap;
