const shoppingListInitialState = {
  isCartModalOpen: false,
  cart: JSON.parse(localStorage.getItem('cart')) || []
};

export const shoppingListReducer = (
  state = shoppingListInitialState,
  action
) => {
  let newCart;

  switch (action.type) {
    case 'openCartModal':
      const isCartNotEmpty = state.cart.length;
      return {
        ...state,
        isCartModalOpen: !!isCartNotEmpty
      };

    case 'closeCartModal':
      return {
        ...state,
        isCartModalOpen: false
      };

    case 'addToCart':
      let newPart = action.value.part;
      let newRevision = action.value.revision;

      const isIncluded = [...state.cart].some(
        (el) =>
          el.index === newPart.index && el.description === newPart.description
      );

      if (isIncluded) {
        const newCart = state.cart.map((product) => {
          const { info, description } = product;
          if (info === newPart.info && description === newPart.description) {
            return {
              ...product,
              count: product.count + 1
            };
          } else return product;
        });

        return {
          ...state,
          cart: newCart
        };
      } else {
        newPart.product_type = {
          family: newRevision.family,
          subfamily: newRevision.subfamily,
          model: newRevision.model,
          full_name: newRevision.family + ' ' + (newRevision.subfamily || ''),
          id: newRevision.id
        };

        newPart.count = 1;

        newCart = [...state.cart, newPart];

        localStorage.setItem('cart', JSON.stringify(newCart));

        return {
          ...state,
          cart: newCart
        };
      }

    case 'removeFromCart':
      const itemToRemove = action.value;

      newCart = [...state.cart].filter(
        ({ index, description }) =>
          index !== itemToRemove.index &&
          description !== itemToRemove.description
      );

      localStorage.setItem('cart', JSON.stringify(newCart));

      const cartModalState = newCart.length !== 0;

      return {
        ...state,
        cart: newCart,
        isCartModalOpen: cartModalState
      };

    case 'clearCart':
      localStorage.setItem('cart', JSON.stringify([]));
      return {
        ...state,
        cart: []
      };

    case 'updateCart':
      const { partToChange, countDiff } = action.value;

      newCart = [...state.cart]
        .map((part) => {
          const { index, description, count } = part;

          if (
            index === partToChange.index &&
            description === partToChange.description
          ) {
            return {
              ...part,
              count: count + countDiff
            };
          } else {
            return part;
          }
        })
        .filter((elem) => elem.count !== 0);

      localStorage.setItem('cart', JSON.stringify(newCart));

      return {
        ...state,
        cart: newCart
      };

    default:
      return state;
  }
};
