import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  addToCart,
  removeFromCart,
  selectItemOnTree,
  updateCart
} from 'actions/actions';
import { bindActionCreators } from 'redux';
import noImageAvailable from 'assets/img/no-image-icon-23485.png';
import store from '../../../../../../store';
import SuitableForCell from 'components/SuitableForCell/SuitableForCell';
import { useHistory } from 'react-router-dom';

const SearchSparepartsRow = ({
  revision,
  sparepart,
  cart,
  isSet,
  updateCart,
  addToCart
}) => {
  const historyRouter = useHistory();

  const [sparepartCount, setCount] = useState(0);
  const imgSrc = sparepart.sparepart_image_url;

  const firstElementWithImage = sparepart?.kit_elements?.find(
    (el) => el.sparepart_image_url
  );
  const kitElementImg = firstElementWithImage?.sparepart_image_url;

  useEffect(() => {
    const findPartInCart = cart.find((item) => item.index === sparepart.index);

    findPartInCart ? setCount(findPartInCart.count) : setCount(0);
  }, [cart]);

  const decrementQuantity = (e) => {
    e.stopPropagation();
    if (sparepartCount === 0) return;

    updateCart(sparepart, -1);
    setCount((prevState) => prevState - 1);
  };

  const incrementQuantity = (e) => {
    e.stopPropagation();
    addToCart(sparepart, revision);
    setCount((prevState) => prevState + 1);
  };

  const goToPart = () => {
    store.dispatch({ type: 'closeFindPartsModal' });
    historyRouter.push(`/spareparts/search`);
    historyRouter.push(`/spareparts/details/${sparepart.index}`);
  };

  return (
    <tr className="light-row" onClick={() => goToPart()}>
      <td className="sparepart-row-with-pic">
        <div className="row-pic">
          <img
            src={imgSrc || kitElementImg || noImageAvailable}
            alt="Sparepart-thumb"
          />
        </div>
        <div className="sparepart-normal-row" style={{ padding: '0 0 0 16px' }}>
          <div className="sparepart-sescription">
            {sparepart.translated_description}
          </div>
        </div>
      </td>
      <td className="sparepart-normal-row">
        <div>{sparepart.index}</div>
      </td>
      <td className="sparepart-normal-row sparepart-normal-row">
        <SuitableForCell sparepart={sparepart} />
      </td>
      <td className="sparepart-normal-row sparepart-last-column">
        {!sparepart.parent_set_number && (
          <div className="quantity-modificator-cell">
            {isSet && (
              <div className="quantity-modificator">
                <div className="quantity-button" onClick={decrementQuantity}>
                  -
                </div>
                <div className="quantity-number">{sparepartCount}</div>
                <div className="quantity-button" onClick={incrementQuantity}>
                  +
                </div>
              </div>
            )}
          </div>
        )}
      </td>
    </tr>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ removeFromCart, addToCart, updateCart }, dispatch),
    selectItemOnTree
  };
};

const mapStateToProps = (state) => {
  return {
    lastMsg: state.appState.lastMsg,
    lastChanges: state.appState.lastChanges,
    cart: state.shoppingList.cart,
    groupedProductTypes: state.spareparts.groupedProductTypes,
    closeAllPartDetails: state.spareparts.closeAllPartDetails
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchSparepartsRow);
