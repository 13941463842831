import React from 'react';
import style from './Spinner.module.css';

const Spinner = () => {
  return (
    <div className={style.container}>
      <div className={style.loader}>Loading...</div>
    </div>
  );
};

export default Spinner;
