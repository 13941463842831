import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { serviceSupportIntercept } from '../../actions/actions';
import history from '../../history';
import { InputSpinner, Spinner2 } from '../Spinners/Spinners';
import './ServiceSupportIntercept.scss';
import translate from '../../utils/translate';

const ServiceSupportIntercept = () => {
  const { error, loading, success } = useSelector(
    (state) => state.serviceSupportIntercept
  );

  useEffect(() => {
    const urlArray = history.location.pathname.split('/');
    const token = urlArray[urlArray.length - 1];
    serviceSupportIntercept(token);
  }, []);

  useEffect(() => {
    if (error || success) {
      setTimeout(() => {
        window.location = 'https://aicon.myaic.eu';
      }, 7000);
    }
  }, [error, success]);

  return (
    <div className="warranty-extend-container">
      {loading && <Spinner2 />}

      {!loading && (success || error) && (
        <div>
          <div className="login-step-title">
            {success && translate(success)}
            {error && translate('Failed to change service support')}
          </div>

          <div className="login-welcome-text">
            <div>
              {translate(
                'After a few seconds, you will be redirected to Spare Parts'
              )}
            </div>
            <InputSpinner />
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceSupportIntercept;
