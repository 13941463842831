import React from 'react';

const Link = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.4536 3.80248C16.14 3.48784 15.7675 3.23819 15.3572 3.06784C14.947 2.8975 14.5072 2.80981 14.063 2.80981C13.6188 2.80981 13.1789 2.8975 12.7687 3.06784C12.3585 3.23819 11.9859 3.48784 11.6723 3.80248L12.4711 4.60123C12.6805 4.39181 12.9291 4.22569 13.2027 4.11235C13.4764 3.99902 13.7696 3.94068 14.0658 3.94068C14.3619 3.94068 14.6552 3.99902 14.9288 4.11235C15.2024 4.22569 15.4511 4.39181 15.6605 4.60123C15.8699 4.81064 16.036 5.05926 16.1493 5.33287C16.2627 5.60649 16.321 5.89975 16.321 6.19591C16.321 6.49207 16.2627 6.78533 16.1493 7.05895C16.036 7.33257 15.8699 7.58118 15.6605 7.7906L11.1605 12.2906C10.7383 12.7135 10.1654 12.9514 9.56777 12.952C8.97018 12.9525 8.39685 12.7156 7.97391 12.2934C7.55097 11.8712 7.31307 11.2983 7.31255 10.7007C7.31202 10.1031 7.54891 9.52979 7.9711 9.10685L8.76422 8.3081L7.9711 7.50935L7.17235 8.3081C6.85771 8.62165 6.60806 8.99422 6.43772 9.40446C6.26737 9.8147 6.17969 10.2545 6.17969 10.6987C6.17969 11.1429 6.26737 11.5827 6.43772 11.993C6.60806 12.4032 6.85771 12.7758 7.17235 13.0893C7.80881 13.7177 8.66864 14.0677 9.56297 14.0625C10.0089 14.0643 10.4507 13.9778 10.863 13.8078C11.2752 13.6379 11.6497 13.388 11.9648 13.0725L16.4648 8.57247C17.0954 7.93817 17.4484 7.07953 17.4463 6.18515C17.4441 5.29077 17.0871 4.4338 16.4536 3.80248Z"
        fill="#32343A"
      />
      <path
        d="M2.35799 13.9612C2.14795 13.7521 1.98127 13.5036 1.86755 13.2299C1.75382 12.9563 1.69527 12.6628 1.69527 12.3665C1.69527 12.0701 1.75382 11.7767 1.86755 11.503C1.98127 11.2293 2.14795 10.9808 2.35799 10.7718L6.85799 6.27178C7.06705 6.06173 7.31555 5.89506 7.58922 5.78133C7.86288 5.66761 8.15632 5.60906 8.45267 5.60906C8.74903 5.60906 9.04247 5.66761 9.31613 5.78133C9.58979 5.89506 9.8383 6.06173 10.0474 6.27178C10.2561 6.48249 10.4204 6.73295 10.5305 7.00832C10.6407 7.28369 10.6944 7.57837 10.6886 7.8749C10.6903 8.17235 10.633 8.46719 10.5201 8.74237C10.4071 9.01754 10.2407 9.26758 10.0305 9.47803L8.83799 10.6874L9.63674 11.4862L10.8292 10.2936C11.464 9.65887 11.8206 8.79793 11.8206 7.90021C11.8206 7.0025 11.464 6.14155 10.8292 5.50678C10.1945 4.872 9.33351 4.51538 8.4358 4.51538C7.53809 4.51538 6.67714 4.872 6.04236 5.50678L1.54236 10.0068C1.22688 10.3204 0.976512 10.6934 0.805669 11.1041C0.634826 11.5149 0.546875 11.9553 0.546875 12.4002C0.546875 12.8451 0.634826 13.2856 0.805669 13.6963C0.976512 14.1071 1.22688 14.48 1.54236 14.7936C2.18295 15.4172 3.04474 15.7611 3.93861 15.7499C4.84036 15.7508 5.70583 15.3949 6.34611 14.7599L5.54736 13.9612C5.33829 14.1712 5.08979 14.3379 4.81613 14.4516C4.54247 14.5653 4.24903 14.6239 3.95267 14.6239C3.65632 14.6239 3.36288 14.5653 3.08922 14.4516C2.81555 14.3379 2.56705 14.1712 2.35799 13.9612Z"
        fill="#32343A"
      />
    </svg>
  );
};

export default Link;
