import React from 'react';

const PartReplacement = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.75 3.375H10.125V2.25C10.125 1.95163 10.0065 1.66548 9.7955 1.4545C9.58452 1.24353 9.29837 1.125 9 1.125H2.25C1.95163 1.125 1.66548 1.24353 1.4545 1.4545C1.24353 1.66548 1.125 1.95163 1.125 2.25V13.5C1.125 13.7984 1.24353 14.0845 1.4545 14.2955C1.66548 14.5065 1.95163 14.625 2.25 14.625H7.875V15.75C7.875 16.0484 7.99353 16.3345 8.2045 16.5455C8.41548 16.7565 8.70163 16.875 9 16.875H15.75C16.0484 16.875 16.3345 16.7565 16.5455 16.5455C16.7565 16.3345 16.875 16.0484 16.875 15.75V4.5C16.875 4.20163 16.7565 3.91548 16.5455 3.7045C16.3345 3.49353 16.0484 3.375 15.75 3.375ZM2.25 8.4375H5.72062L4.26938 9.89437L5.0625 10.6875L7.875 7.875L5.0625 5.0625L4.26938 5.85563L5.72062 7.3125H2.25V2.25H9V13.5H2.25V8.4375ZM9 15.75V14.625C9.29837 14.625 9.58452 14.5065 9.7955 14.2955C10.0065 14.0845 10.125 13.7984 10.125 13.5V4.5H15.75V9.5625H12.2794L13.7306 8.10563L12.9375 7.3125L10.125 10.125L12.9375 12.9375L13.7306 12.1444L12.2794 10.6875H15.75V15.75H9Z"
        fill="#32343A"
      />
    </svg>
  );
};

export default PartReplacement;
