import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, withRouter } from 'react-router-dom';
import {
  addToCart,
  openCartModal,
  partListDescription,
  removeFromCart,
  showError,
  updateCart
} from '../../../../actions/actions';
import translate from 'utils/translate';
import OkIcon from 'assets/svg/ok.jsx';
import './sparepartsPage.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import history from './../../../../history';
import SparepartsImage from '../../../../components/SparepartsImage/SparepartsImage';
import SparepartDataCard from '../../../../components/SparepartDataCard/SparepartDataCard';
import ProductInstallation from '../../../../assets/svg/productActions/productInstallation';
import SparepartKitsTable from '../../../../components/SparepartKitsTable/SparepartKitsTable';
import SparepartReplaces from '../../../../components/SparepartReplaces/SparepartReplaces';
import SparepartByReplaced from '../../../../components/SparepartByReplaced/SparepartByReplaced';

const SparepartPage = (props) => {
  const { addToCart, updateCart, openCartModal } = props;

  const [count, setCount] = useState(0);
  const [sparepart, setSparepart] = useState(null);
  const [sparepartId, setSparepartId] = useState(
    props.match.params.sparepartId
  );

  // TODO move to actions.jsx
  function loadSparepartDetails(id = sparepartId) {
    axios({ url: 'api/spareparts/' + id })
      .then((response) => {
        if (!response.data.description) history.push('/spareparts');
        setSparepart(response.data);
        partListDescription(response.data.description);
      })
      .catch((error) => showError(error));
  }

  useEffect(() => {
    if (!sparepart) return;
    props.cart.map((part) => {
      if (part.sparepart_id === sparepart.sparepart_id) {
        return setCount(part.count);
      }
    });
    setCount(0);
  }, [props.cart]);

  useEffect(() => {
    if (props.match.params.sparepartId && !sparepart) {
      loadSparepartDetails();
    }

    if (sparepartId !== props.match.params.sparepartId) {
      setSparepartId(props.match.params.sparepartId);
      loadSparepartDetails(props.match.params.sparepartId);
    }
  }, [loadSparepartDetails, props.match.params.sparepartId]);

  useEffect(() => {
    if (props.cart.length && sparepart) {
      for (let i = 0; i < props.cart.length; i++) {
        if (
          props.cart[i].description === sparepart.description &&
          props.cart[i].index === sparepart.index
        ) {
          setCount(props.cart[i].count);
        }
      }
    }
  }, [sparepart, props.cart]);

  const addThisToCart = () => {
    if (count === 0) {
      addToCart(sparepart, sparepart.suitable_for);
    } else {
      updateCart(sparepart, 1);
    }
    setCount((prevState) => prevState + 1);
  };

  const removeThisFromCart = () => {
    updateCart(sparepart, -1);
    setCount((prevState) => prevState - 1);
  };

  if (!sparepart) return null;
  let gridStyles = { grid: '1fr', width: '24rem' };
  if (sparepart.kit_elements.length > 1) {
    gridStyles = { grid: '1fr 1fr', width: '16rem' };
  }
  if (sparepart.kit_elements.length > 4) {
    gridStyles = { grid: '1fr 1fr 1fr', width: '12rem' };
  }

  const uniqueSuitableFor = () => {
    let unique = [
      ...new Set(
        sparepart.suitable_for.map((item) => {
          return `${item.family} ${item.subfamily ? item.subfamily + ' ' : ''}${
            item.model
          }`;
        })
      )
    ];

    return unique.map((item, i) => {
      return (
        <div
          className="data-card--item"
          key={i}>
          {item}
        </div>
      );
    });
  };

  return (
    <div className="sparepart-page-container">
      <div className="left-pane">
        <div className="kit-header">
          <div className="kit-name">{sparepart.translated_description}</div>
          <div className="kit-index">{sparepart.index}</div>
        </div>

        {sparepart.replaced_by.length > 0 && (
          <SparepartByReplaced sparepart={sparepart} />
        )}

        {sparepart.replaces.length > 0 && (
          <SparepartReplaces sparepart={sparepart} />
        )}

        {sparepart.suitable_for.length > 0 && (
          <SparepartDataCard
            title={translate('Suitable for')}
            icon={<OkIcon />}>
            {uniqueSuitableFor()}
          </SparepartDataCard>
        )}

        {sparepart.is_set ? (
          <SparepartKitsTable
            count={count}
            sparepart={sparepart}
            addThisToCart={addThisToCart}
            openCartModal={openCartModal}
            removeThisFromCart={removeThisFromCart}
          />
        ) : (
          sparepart.included_in && (
            <SparepartDataCard
              title={translate('included_in')}
              icon={<ProductInstallation />}>
              {sparepart.included_in.map(({ id, index }) => (
                <Link
                  key={id}
                  className="data-card--item"
                  to={`/spareparts/details/${index}`}>
                  {index}
                </Link>
              ))}
            </SparepartDataCard>
          )
        )}
      </div>

      <div className="right-pane">
        {sparepart.kit_elements.length > 0 ? (
          <div
            className="pictures-grid"
            style={{
              gridTemplateColumns: `repeat(auto-fill, ${gridStyles.width})`
            }}>
            {sparepart.kit_elements.map((item, i) => {
              return (
                <div
                  className="grid-picture"
                  key={i}
                  style={{ width: gridStyles.width }}>
                  <div className="grid-label">{i + 1}</div>
                  <SparepartsImage image_source={item.sparepart_image_url} />
                </div>
              );
            })}
          </div>
        ) : (
          <div
            className="grid-picture"
            style={{ width: gridStyles.width }}>
            <SparepartsImage image_source={sparepart.image_url} />
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      addToCart,
      updateCart,
      removeFromCart,
      openCartModal
    },
    dispatch
  )
});

const mapStateToProps = (state) => {
  return {
    cart: state.shoppingList.cart
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SparepartPage));
