import React, { useEffect, useState } from 'react';
import './ProductActionTimeline.scss';
import { actions } from 'utils/const';

const ProductActionTimeLine = ({ actionType, last, id, isHidden }) => {
  const [lineStyles, setLineStyles] = useState({});
  useEffect(() => {
    if (last) {
      setLineStyles({
        height: '0%'
      });
    }
  }, []);
  return (
    <div
      className="product-history-timeline-block"
      key={id}>
      <div className="timeline">
        <div
          className="timeline-line"
          style={lineStyles}></div>
        <div
          className={
            !isHidden ? `timeline-icon` : 'timeline-icon hidden-timeline-icon'
          }>
          {actions[actionType] && actions[actionType]()}
        </div>
      </div>
    </div>
  );
};

export default ProductActionTimeLine;
