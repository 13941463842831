import React from 'react';
import './notFound.scss';

const NotFound = () => (
  <div className="not-found">
    <h1>Page not found :(</h1>
  </div>
);

export default NotFound;
