import React from 'react';
import translate from '../../utils/translate';
import { Link } from 'react-router-dom';

const SparepartKitsTable = ({
  sparepart: { kit_elements },
  removeThisFromCart,
  count,
  addThisToCart,
  openCartModal
}) => {
  return (
    <div className="kit-items-container">
      <table>
        <thead>
          <tr>
            <th>{translate('No.')}</th>
            <th>{translate('Kit element')}</th>
            <th>{translate('Group')}</th>
            <th>{translate('Quantity')}</th>
          </tr>
        </thead>
        <tbody>
          {kit_elements.map(
            ({ description, index, group, quantity, unit }, i) => {
              return (
                <tr key={i} className="kit-items-row">
                  <td>{i + 1}</td>
                  <td>
                    <div className="sparepart-double-row">
                      <div className="sparepart-index">{description}</div>
                      <div className="sparepart-description">
                        <Link to={`/spareparts/details/${index}`}>{index}</Link>
                      </div>
                    </div>
                  </td>
                  <td>{group}</td>
                  <td>
                    {quantity} {unit}
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>

      <div className="kit-items-controls">
        <div className="quantity-modificator-cell">
          <div className="quantity-modificator">
            <button
              className="quantity-button"
              onClick={removeThisFromCart}
              disabled={!count}
            >
              -
            </button>
            <div className="quantity-number">{count}</div>
            <button className="quantity-button" onClick={addThisToCart}>
              +
            </button>
          </div>
        </div>
        <button className="aic-btn" onClick={openCartModal}>
          {translate('Show your shopping list')}
        </button>
      </div>
    </div>
  );
};

export default SparepartKitsTable;
