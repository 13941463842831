import React from 'react';
import './NoResultsFound.scss';
import { minSNLength } from 'utils/const';
import translate from 'utils/translate';

const NoResultsFound = ({
  results,
  withoutResultsRef,
  searchPhrase,
  isResultsLoaded,
  searchBySNMode,
  resultsBySN
}) => {
  const textDivider = 'XXXXX'; // should NOT be translated and must be returned from translate!!!
  const noResultsText = translate(
    `Product with serial number ${textDivider} not found. Try to search by categories`
  ); //todo: translation!
  const textArray = noResultsText.split(textDivider);
  textArray.splice(1, 0, <b>{searchPhrase}</b>); // replace divider with BOLDED search phrase

  return (
    <div
      ref={withoutResultsRef}
      className="no-results-container">
      {isResultsLoaded && (
        <>
          {searchPhrase.trim() && !results.length && !searchBySNMode && (
            <div className="no-results">
              {translate(
                'No results found. Please, try different keywords or search for recent devices.'
              )}
            </div>
          )}

          {searchPhrase.length >= minSNLength &&
            searchBySNMode &&
            !resultsBySN && (
              <div className="no-results no-results-by-SN">
                {textArray.map((text, i) => (
                  <span key={i}> {text} </span>
                ))}
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default NoResultsFound;
