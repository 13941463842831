const sparepartsInitialState = {
  tIndex: null,
  fIndex: null,
  mIndex: null,
  gIndex: null
};

export const sparepartsCategoriesReducer = (
  state = sparepartsInitialState,
  action
) => {
  switch (action.type) {
    case 'addToPath':
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};
