import React, { useMemo } from 'react';
import translate from '../../../utils/translate';
import { UserRoles } from '../../../utils/const';

const CartBottomSection = ({
  comments,
  onChange,
  exportToXLSX,
  onSubmit,
  user
}) => {
  const isService = useMemo(
    () =>
      user &&
      user.roles.some((el) => el === UserRoles.SERVICE || el === UserRoles.DISTRIBUTION),
    [user]
  );

  return (
    <div className="cart-bottom-section">
      <div className="comments-container">
        <textarea
          value={comments}
          onChange={onChange}
          id="comments"
          placeholder={translate('comments')}
        />
      </div>

      <div className="cart-buttons">
        <button onClick={exportToXLSX} className="aic-small-btn-outline">
          {translate('Export to xlsx')}
        </button>

        <button
          data-testid="submitParts"
          onClick={onSubmit}
          disabled={!isService}
          className="aic-small-btn-orange"
        >
          {translate('Send request for spare parts')}
        </button>

        {!isService && <span>* {translate('User need specific role.')}</span>}
      </div>
    </div>
  );
};

export default CartBottomSection;
