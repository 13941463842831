import React from 'react';
import './partsSearch.scss';
import InputSearch from 'assets/svg/inputSearch';
import { DebounceInput } from 'react-debounce-input';
import translate from 'utils/translate';
import CloseBlackButton from 'assets/svg/close';

const PartsSearch = ({ searchPhrase, handleChange, clearSearchHandler }) => {
  return (
    <>
      <div className="find-parts-search-input">
        <div className="spareparts-number-input">
          <InputSearch />
          <DebounceInput
            minLength={1}
            debounceTimeout={400}
            type="text"
            value={searchPhrase}
            onChange={handleChange}
            placeholder={translate('Search...')}
            id="searchPhrase"
          />
          <div
            onClick={() => clearSearchHandler()}
            style={{ cursor: 'pointer' }}>
            <CloseBlackButton />
          </div>
        </div>
      </div>
    </>
  );
};

export default PartsSearch;
