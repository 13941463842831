import React, { useState } from 'react';
import 'utils/timeline.scss';
import './AddAction.scss';
import translate from 'utils/translate';
import AddActionList from './AddActionList';
import { actions } from 'utils/const';

const AddAction = ({ currentProduct }) => {
  const [openModal, setOpenModal] = useState(false);
  const [editingAction, setEditingAction] = useState(undefined);

  const toggleModal = () => {
    setOpenModal((prevState) => {
      prevState && setEditingAction(undefined);

      return !prevState;
    });
  };

  return (
    <React.Fragment>
      <div
        className="timeline-icon add-action-button"
        onClick={toggleModal}>
        +
      </div>
      <div className="add-new-action-container">
        <div
          className="add-new-action-header bolded"
          onClick={toggleModal}>
          <div>{translate('Add new action')}</div>
          {editingAction && (
            <div className="add-new-action-header-action-info">
              <div>{translate(editingAction)}</div>
              <div className="in-circle">
                {actions[editingAction] && actions[editingAction]()}
              </div>
            </div>
          )}
        </div>
        {openModal && (
          <AddActionList
            setOpenModal={toggleModal}
            currentProduct={currentProduct}
            setEditingAction={setEditingAction}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default AddAction;
