import React from 'react';
import './TriStateToggleSwitch.scss';
import 'react-datepicker/dist/react-datepicker.css';
import './UniversalActionForm.scss';
import 'rc-datetime-picker/dist/picker.min.css';
import Fade from 'react-reveal/Fade';
import moment from 'moment';
import DocumentLink from 'components/DocumentLink/DocumentLink';
import { ActionCheckbox } from './ActionTypes/ActionCheckbox';
import { ActionSelect } from './ActionTypes/ActionSelect';
import { ActionPartPicker } from './ActionTypes/ActionPartPicker';
import { ActionDate } from './ActionTypes/ActionDate';
import { ActionInput } from './ActionTypes/ActionInput';
import { ActionTristate } from './ActionTypes/ActionTristate';
import { LocationAction } from './ActionTypes/LocationAction';
import { ActionComment } from './ActionTypes/ActionComment';
import { ItemListAction } from './ActionTypes/ItemListAction';

export const errorMessage = (obj, fieldErrorMap) => {
  let hasError = fieldErrorMap.has(obj.name);
  return hasError ? (
    <Fade
      top
      collapse
      when={hasError}>
      <div style={{ color: 'red' }}>{fieldErrorMap.get(obj.name)}</div>
    </Fade>
  ) : null;
};

const showWarrantyFile = (possible_values, key) => {
  if (possible_values && key) {
    return possible_values.filter((item) => {
      return item.key === key;
    })[0];
  } else {
    return null;
  }
};

export const renderParameterField = (
  obj,
  index,
  fieldErrorMap,
  changeFieldValue,
  form_data,
  currentProduct,
  on_behalf_users = []
) => {
  let fileObj =
    obj.name === 'warranty_level'
      ? showWarrantyFile(obj.possible_values, form_data[obj.name])
      : undefined;

  if (
    obj.possible_values &&
    obj.type !== 'tristate' &&
    obj.type !== 'bool_choose' &&
    obj.name !== 'warranty_level' &&
    obj.name !== 'file_attachment'
  ) {
    return (
      <ActionSelect
        obj={obj}
        key={obj.name + index}
        onChangeInput={(selectedOption) => {
          changeFieldValue(selectedOption.value, obj.name);
        }}
        errorMessage={errorMessage(obj, fieldErrorMap)}
        options={obj.possible_values.map((val) => {
          return { value: val.key, label: val.displayed_name };
        })}
      />
    );
  } else if (obj.type === 'checklist') {
    return (
      <ActionCheckbox
        obj={obj}
        key={obj.name + index}
        onChangeInput={(result) => changeFieldValue(result, obj.name)}
        inputValue={form_data[obj.name] || {}}
        fieldErrorMap={fieldErrorMap}
      />
    );
  } else if (obj.type === 'bool' || obj.type === 'file_attachment') {
    return null;
  } else if (obj.type === 'bool_choose') {
    return (
      <ActionTristate
        obj={obj}
        key={obj.name + index}
        inputValue={form_data[obj.name]}
        onChangeInput={(result) => changeFieldValue(result, obj.name)}
        errorMessage={errorMessage(obj, fieldErrorMap)}
      />
    );
  } else if (obj.type === 'tristate') {
    return (
      <ActionTristate
        obj={obj}
        key={obj.name + index}
        inputValue={form_data[obj.name]}
        onChangeInput={(val) => changeFieldValue(val, obj.name)}
        errorMessage={errorMessage(obj, fieldErrorMap)}
      />
    );
  } else if (obj.type === 'datetime' || obj.type === 'date') {
    if (!form_data[obj.name]) {
      form_data[obj.name] = moment();
    } else {
      moment(form_data[obj.name]);
    }

    return (
      <ActionDate
        obj={obj}
        key={obj.name + index}
        momentValue={form_data[obj.name]}
        errorMessage={errorMessage(obj, fieldErrorMap)}
        inputValue={form_data[obj.name] || moment()}
        onChangeInput={(date) => {
          changeFieldValue(moment(date), obj.name);
        }}
      />
    );
  } else if (obj.type === 'location') {
    return (
      <LocationAction
        obj={obj}
        key={obj.name + index}
        errorMessage={errorMessage(obj, fieldErrorMap)}
        setChosenLocation={(o) =>
          changeFieldValue(`${o.lat}, ${o.lng}`, obj.name)
        }
      />
    );
  } else if (obj.type === 'part_index') {
    return (
      <ActionPartPicker
        obj={obj}
        key={obj.name + index}
        onChangeInput={(partNumber) => changeFieldValue(partNumber, obj.name)}
        currentProduct={currentProduct}
        inputValue={form_data[obj.name]}
        errorMessage={errorMessage(obj, fieldErrorMap)}
      />
    );
  } else if (obj.type === 'item_list') {
    return (
      <ItemListAction
        obj={obj}
        key={obj.name + index}
        onChangeInput={(result) => changeFieldValue(result, obj.name)}
        inputValue={form_data[obj.name] || []}
        fieldErrorMap={fieldErrorMap}
        currentProduct={currentProduct}
      />
    );
  }
  if (obj.name === 'comment') {
    return (
      <ActionComment
        obj={obj}
        key={obj.name + index}
        onChangeInput={(e) => changeFieldValue(e.target.value, obj.name)}
        inputValue={form_data[obj.name]}
        errorMessage={errorMessage(obj, fieldErrorMap)}
      />
    );
  }
  if (obj.name === 'warranty_level') {
    let buttonNames = [];
    if (obj.possible_values) {
      buttonNames = obj.possible_values.map((ps) => {
        return { ...ps, value: ps.key, label: ps.displayed_name };
      });
    }
    return (
      <div key={obj.name + index}>
        <ActionSelect
          obj={obj}
          onChangeInput={(selectedOption) => {
            changeFieldValue(selectedOption.key, obj.name);
          }}
          options={buttonNames}
          errorMessage={errorMessage(obj, fieldErrorMap)}
        />
        <div>
          {fileObj && (
            <DocumentLink
              url={fileObj.file}
              description={fileObj.file_description}
            />
          )}
        </div>
      </div>
    );
  }
  if (obj.name === 'on_behalf_of') {
    return (
      <ActionSelect
        obj={obj}
        inputValue={undefined}
        options={on_behalf_users}
        key={obj.name}
        onChangeInput={(selectedOption) =>
          changeFieldValue(selectedOption.value, obj.name)
        }
        errorMessage={errorMessage(obj, fieldErrorMap)}
      />
    );
  } else {
    return (
      <ActionInput
        obj={obj}
        key={obj.name + index}
        onChangeInput={(e) => changeFieldValue(e.target.value, obj.name)}
        inputValue={form_data[obj.name]}
        errorMessage={errorMessage(obj, fieldErrorMap)}
      />
    );
  }
};
