import React, { useState } from 'react';
import './ProductAction.scss';
import ProductActionCard from './ProductActionCard/ProductActionCard';
import ProductActionTimeLine from './ProductActionTimeline/ProductActionTimeLine';
import { useLoadingWhileUpdateBoilerInfo } from '../../ProductDashboard';
import { HistorySpiner } from '../../../../../components/Spinners/Spinners';
import AddAction from '../AddAction/AddAction';
import { v4 as uuidv4 } from 'uuid';

const ProductActions = () => {
  const [deviceInfo, setDeviceInfo] = useState(undefined);

  useLoadingWhileUpdateBoilerInfo(setDeviceInfo);

  return (
    <div className="product-history-timeline-block">
      <div />
      {deviceInfo && <AddAction currentProduct={deviceInfo} />}
      {deviceInfo ? (
        deviceInfo.history.map((action, index) => {
          let last = false;
          if (index === deviceInfo.history.length - 1) {
            last = true;
          }
          return (
            <React.Fragment key={uuidv4()}>
              <ProductActionTimeLine
                actionType={action.type}
                last={last}
                isHidden={action.hidden}
              />
              <ProductActionCard action={action} />
            </React.Fragment>
          );
        })
      ) : (
        <div
          className="product-wrapper"
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
          <HistorySpiner />
        </div>
      )}
    </div>
  );
};

export default ProductActions;
