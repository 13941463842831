import React from 'react';
import { AiOutlineCloseCircle, AiOutlineSend } from 'react-icons/ai';
import ImageUpload from '../../ImageUpload/ImageUpload';
import translate from '../../../utils/translate';
import { FaDeleteLeft } from 'react-icons/fa6';

const TravellerUploader = ({ onClose, onDrop, onUpload, onRemove, files, actionType, imagesToDelete, onReturnRemove }) => {
  return (
    <div className="position-relative w-100">
      <AiOutlineCloseCircle
        onClick={onClose}
        className="history-action-add-image--close"
      />

      <ImageUpload
        actionType={actionType}
        onDrop={onDrop}
        onRemove={onRemove}
        onReturnRemove={onReturnRemove}
        droppedFile={files}
        imagesToDelete={imagesToDelete}
      />

      <button
        onClick={onUpload}
        className="aic-btn aic-small-btn history-action-upload-image">
        {
          imagesToDelete.length && !files.some(elem => elem.file)
          ? <>
              <span>{translate('delete')}</span>
              <FaDeleteLeft />
            </>
          : <>
              <span>{translate('Accept choices')}</span>
              <AiOutlineSend />
            </>
        }
      </button>
    </div>
  );
};

export default TravellerUploader;
