import React from 'react';
import '../../scenes/Login/login.scss';
import ValidationError from '../ValidationError/ValidationError';

const Input = (props) => {
  const {
    type,
    value,
    id,
    onChange,
    placeholder,
    validationError,
    style,
    required
  } = props;

  return (
    <>
      <div className="input-group form-group">
        <div className="input-group-prepend"></div>
        <input
          type={type}
          className="form-control login-input"
          value={value}
          onChange={onChange}
          id={id}
          placeholder={placeholder}
          style={style}
        />
        {required && <span style={{ paddingLeft: '6px' }}> *</span>}
        {validationError && <ValidationError text={validationError} />}
      </div>
    </>
  );
};

export default Input;
