import React from 'react';
import translate from 'utils/translate';
import RecentItem from './RecentItem';

const RecentProductsBeforeSearch = ({ recentProductsRef, userLoadHistory }) => {
  return (
    <div ref={recentProductsRef}>
      <div className="recent-products">
        <div className="recent-title">{translate('Recent')}:</div>
        <div className="recent-items">
          {userLoadHistory
            .slice(0, 6) //show 6 recent products from list
            .map((product, i) => (
              <RecentItem
                key={i}
                product={product}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default RecentProductsBeforeSearch;
