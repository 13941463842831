import React from 'react';

const ErrorBoldIcon = (c) => {
  let cls = `custom-aic-icon  ${c === {} ? '' : c.className}`;
  return (
    <svg
      className={cls}
      width="43"
      height="39"
      viewBox="0 0 43 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        description="evenodd"
        clipRule="evenodd"
        d="M16.8695 3.08076C17.7481 1.55779 19.3726 0.619629 21.1308 0.619629C22.8891 0.619629 24.5135 1.55779 25.3921 3.08076C29.899 10.8925 36.9675 23.1446 41.4725 30.9533C42.3506 32.4755 42.3502 34.3506 41.4713 35.8723C40.5923 37.394 38.9686 38.3315 37.2112 38.3315C28.1994 38.3315 14.0622 38.3315 5.05045 38.3315C3.29311 38.3315 1.66919 37.394 0.790314 35.8723C-0.0885657 34.3506 -0.0889816 32.4755 0.789204 30.9533C5.29412 23.1446 12.3626 10.8925 16.8695 3.08076ZM17.851 30.9533C17.851 32.7689 19.3604 34.2331 21.1598 34.2331C22.9593 34.2331 24.4105 32.7689 24.4105 30.9533C24.4105 29.1377 22.9593 27.6736 21.1598 27.6736C19.3604 27.6736 17.851 29.1377 17.851 30.9533ZM18.3815 11.8636L18.5846 23.5724H23.6858L23.8889 11.8636H18.3815Z"
        fill="#32343A"
      />
    </svg>
  );
};

export default ErrorBoldIcon;
