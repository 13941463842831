import React, { useEffect, useState } from 'react';
import store from 'store';
import axios from 'axios';
import translate from 'utils/translate';
import { logout, showError } from 'actions/actions';

import './profile.scss';

const DeleteAccount = ({ goBack }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  const confirmationMsg =
    translate('I want to delete my account') +
    ' ' +
    translate('all your user data will be deleted');

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .delete('/api/user/delete_account/')
      .then((response) =>
        store.dispatch({
          type: 'showMsg',
          value: {
            msg: translate('Account deleted'),
            type: 'success'
          }
        })
      )
      .catch((error) => showError(error));
    logout();
  };

  useEffect(() => {
    let timer = setTimeout(() => setIsVisible(true), 100);
    return () => clearTimeout(timer);
  });

  return (
    <div
      className={`delete-account-container ${
        isVisible && 'delete-account-container-show'
      }`}>
      {!confirmation ? (
        <div
          className={`delete-account-container ${
            isVisible && 'delete-account-container-show'
          }`}>
          <div className="span-link">
            {translate(
              'You want to delete your account. Once you confirm, all of your account data will be permamently deleted.'
            )}
          </div>
          <div className="preferences-btn-container">
            <button
              onClick={() => {
                goBack(0);
              }}
              className="preferences-btn">
              {translate('Back')}
            </button>
            <button
              onClick={() => setConfirmation(true)}
              className="preferences-btn preferences-btn-background"
              type="submit">
              {translate('Confirm')}
            </button>
          </div>
        </div>
      ) : (
        <div
          className={`delete-account-container ${
            isVisible && 'delete-account-container-show'
          }`}>
          <h3 className="preferences-section-header">
            {translate('Confirmation required')}
          </h3>
          <div className="span-link">{confirmationMsg}</div>
          <div className="preferences-btn-container">
            <button
              onClick={() => {
                goBack(0);
              }}
              className="preferences-btn">
              {translate('Cancel')}
            </button>
            <button
              onClick={handleSubmit}
              className="preferences-btn preferences-btn-background"
              type="submit">
              {translate('Confirm')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteAccount;
