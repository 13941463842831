import React from 'react';
import Screw from '../../assets/svg/screw';
import translate from '../../utils/translate';

const SparepartsImage = ({ image_source }) => {
  return image_source ? (
    <img
      src={image_source}
      alt="Sparepart-thumb"
    />
  ) : (
    <div className="grid-no-image">
      <Screw />
      <div>{translate('Photo not available yet')}</div>
    </div>
  );
};

export default SparepartsImage;
