import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './../UniversalActionForm.scss';
import 'rc-datetime-picker/dist/picker.min.css';
import { FieldLabel } from './FieldLabel';
import { errorMessage } from '../ActionTypes';
import { ActionPartPicker } from './ActionPartPicker';
import { ActionInput } from './ActionInput';
import translate from 'utils/translate';

export const ItemListAction = (props) => {
  const { obj, onChangeInput, inputValue, fieldErrorMap, currentProduct } =
    props;

  let emptyObj = {};
  obj.item_fields.forEach((field) => {
    emptyObj[field.name] = undefined;
  });

  const changeFieldValue = (val, name, index) => {
    let output = [...inputValue];
    output.splice(index, 1, val);
    onChangeInput(output);
  };

  const addAnotherPart = () => {
    let output = [...inputValue];
    output.push(emptyObj);
    onChangeInput(output);
  };

  const removeReplaceItem = (index) => {
    let output = [...inputValue];
    output.splice(index, 1);
    onChangeInput(output);
  };

  return (
    <div className="input-container">
      <div className="item-list-container">
        <div className="brand-new-input-label">
          <FieldLabel obj={obj} />
        </div>
        {inputValue.map((param, index) => {
          return (
            <div key={param.name + index}>
              <div className="item-list-item">
                <div className="item-list-index">{index + 1}</div>
                <div className="item-list-row">
                  {obj.item_fields.map((item_field) => {
                    if (item_field.type === 'part_index') {
                      return (
                        <ActionPartPicker
                          key={obj.name + item_field.name + index}
                          obj={item_field}
                          onChangeInput={(val) =>
                            changeFieldValue(
                              {
                                ...param,
                                [item_field.name]: val
                              },
                              obj.name,
                              index
                            )
                          }
                          currentProduct={currentProduct}
                          inputValue={param[item_field.name]}
                          errorMessage={errorMessage(obj, fieldErrorMap)}
                        />
                      );
                    } else {
                      return (
                        <ActionInput
                          obj={item_field}
                          key={obj.name + item_field.name + index}
                          onChangeInput={(e) =>
                            changeFieldValue(
                              {
                                ...param,
                                [item_field.name]: e.target.value
                              },
                              obj.name,
                              index
                            )
                          }
                          inputValue={param[item_field.name]}
                          errorMessage={errorMessage(obj, fieldErrorMap)}
                        />
                      );
                    }
                  })}
                </div>
                <button
                  className="item-list-remove"
                  onClick={() => removeReplaceItem(index)}>
                  X
                </button>
              </div>
              <div className="item-list-separator" />
            </div>
          );
        })}

        <button
          className="replace-another-part-button"
          onClick={() => addAnotherPart()}>
          {inputValue.length > 0 && translate('Replace another part')}
          {inputValue.length === 0 && translate('Replace a part')}
        </button>
      </div>
    </div>
  );
};
