import React from 'react';
import ReactDOM from 'react-dom';
import App from './scenes/Main/App';
import { Provider } from 'react-redux';
import store from './store';
import { SnackbarProvider } from 'notistack';
import { library } from '@fortawesome/fontawesome-svg-core';
import axios from 'axios';
import translate from 'utils/translate';
import * as serviceWorker from './serviceWorker';
import '../node_modules/jquery/dist/jquery.min.js';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './index.css';
import MobileOrWebDetector from 'components/mobileOrWebDetector/mobileOrWebDetector';

import {
  faInfo,
  faEyeSlash,
  faUser,
  faExclamationTriangle,
  faBars,
  faGlobe,
  faFlag,
  faEnvelope,
  faLock,
  faUserTag,
  faMobileAlt,
  faBriefcase,
  faInfoCircle,
  faWindowClose,
  faPlus,
  faMinus,
  faMinusSquare,
  faShoppingCart,
  faCartPlus,
  faKey,
  faTrashAlt,
  faSearch,
  faHistory,
  faEdit,
  faCalendarAlt,
  faQuestion,
  faWarehouse,
  faPeopleCarry,
  faCashRegister,
  faPastafarianism,
  faWrench,
  faTools,
  faClipboardList,
  faShippingFast,
  faFileSignature,
  faExclamation,
  faAngleRight,
  faPlayCircle,
  faCheckSquare,
  faTimesCircle,
  faPlusCircle,
  faMapMarkedAlt,
  faRetweet,
  faArrowLeft
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faInfo,
  faUser,
  faEyeSlash,
  faBars,
  faExclamationTriangle,
  faGlobe,
  faFlag,
  faEnvelope,
  faUserTag,
  faLock,
  faBriefcase,
  faMobileAlt,
  faInfoCircle,
  faWindowClose,
  faPlus,
  faMinus,
  faMinusSquare,
  faShoppingCart,
  faCartPlus,
  faKey,
  faTrashAlt,
  faSearch,
  faHistory,
  faEdit,
  faCalendarAlt,
  faQuestion,
  faWarehouse,
  faPeopleCarry,
  faCashRegister,
  faPastafarianism,
  faWrench,
  faTools,
  faClipboardList,
  faShippingFast,
  faFileSignature,
  faExclamation,
  faAngleRight,
  faPlayCircle,
  faCheckSquare,
  faTimesCircle,
  faPlusCircle,
  faMapMarkedAlt,
  faRetweet,
  faArrowLeft
);

axios.defaults.headers['appUTCTimezoneOffset'] =
  (new Date().getTimezoneOffset() / 60) * -1; //+2 for Poland
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers['X-API-Key'] = process.env.REACT_APP_API_KEY;
axios.defaults.timeout = 100000;
axios.defaults.withCredentials = true;

ReactDOM.render(
  <Provider store={store}>
    <MobileOrWebDetector />
    <SnackbarProvider
      classes={{
        root: 'snack-root'
      }}
      maxSnack={4}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={6000}
      action={[
        <button
          key={Math.random()}
          className="btn btn-outline-warning">
          {translate('Hide')}
        </button>
      ]}>
      <App />
    </SnackbarProvider>
  </Provider>,
  document.getElementById('root')
);

if (window.Cypress) {
  window.store = store;
}

serviceWorker.unregister();
