import React, { Component } from 'react';
import axios from 'axios';
import history from './../../history';
import store from 'store';
import './newlogin.scss';
import translate from 'utils/translate';
import { b64encode } from 'utils/misc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ValidationError from '../../components/ValidationError/NewValidationError';
import { UserRoles } from 'utils/const';

class NewLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: window.location.pathname.includes('spareparts')
        ? 'Spareparts'
        : 'product_info',
      password: '',
      username: ''
    };
  }

  onLogin = (user) => {
    if (user && user.need_to_change_password) {
      history.push(`/user/change_password`);
      return;
    }
    axios.defaults.headers['iBoilerAppUserBarcode'] = user.user_name;
    if (history.location.pathname === '/spareparts/login') {
      history.push('/spareparts');
    } else if (user?.roles.includes(UserRoles.DISTRIBUTION)) {
      history.push('/spareparts');
    } else if (history.location.pathname === '/login') {
      history.push('/product_info');
    } else {
      history.push('/dashboard');
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.validate()) return;

    const config = {
      headers: {
        Login: b64encode(this.state.username),
        Password: b64encode(this.state.password)
      },
      data: {
        Login: b64encode(this.state.username),
        Password: b64encode(this.state.password)
      },
      url: '/api/auth/login',
      method: 'post'
    };

    axios(config)
      .then((response) => {
        store.dispatch({ type: 'login', value: response.data });
        this.onLogin(response.data);
      })
      .catch((error) => {
        // show message if user account wasn't confirmed or department not confirm user
        if (error.response.data.message === 'User not confirmed') {
          const title = error.response.data.localized_message.description;
          const text =
            error.response.data.localized_message.recovery_suggestion;
          history.push(`/user_account_not_confirmed`, {
            title,
            text
          });
          return;
        }

        this.setState({
          usernameError: translate('Wrong username or password'),
          passwordError: translate('Wrong username or password')
        });
      });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  validate = () => {
    let usernameError = '';
    let passwordError = '';
    let isValid = true;
    const emptyFieldError = translate('This field cannot be empty. ');

    if (!this.state.username) {
      usernameError += emptyFieldError;
      isValid = false;
    }

    if (!this.state.password) {
      passwordError += emptyFieldError;
      isValid = false;
    }

    this.setState({
      usernameError: usernameError,
      passwordError: passwordError
    });

    return isValid;
  };

  render() {
    return (
      <div className="login-step">
        <div className="login-step-title">{translate('Login')}</div>
        <div className="login-step-login-form">
          <form
            onSubmit={this.handleSubmit}
            noValidate
            className="login-form-content"
          >
            <div className="custom-input">
              <input
                data-testid="username"
                type="text"
                required
                value={this.state.username}
                onChange={this.handleChange}
                id="username"
                autoComplete="off"
              />
              <FontAwesomeIcon
                icon="user"
                size="sm"
                color="gray"
                className="input-icon"
              />
              <span className="floating-label">{translate('Username')}</span>
            </div>
            {this.state.usernameError && (
              <div style={{ width: '100%' }} data-testid="loginValidationError">
                <ValidationError text={this.state.usernameError} />
              </div>
            )}

            <div className="custom-input">
              <input
                data-testid="password"
                type="password"
                required
                value={this.state.password}
                onChange={this.handleChange}
                id="password"
                autoComplete="off"
              />
              <FontAwesomeIcon
                icon="lock"
                size="sm"
                color="gray"
                className="input-icon"
              />
              <span className="floating-label">{translate('Password')}</span>
            </div>
            {this.state.passwordError && (
              <div
                style={{ width: '100%' }}
                data-testid="passwordValidationError"
              >
                <ValidationError text={this.state.passwordError} />
              </div>
            )}

            <div className="remeber-and-forgot-password">
              <div className="remember-password">
                <label>
                  <input
                    data-testid="rememberPassword"
                    type="checkbox"
                    id="rememberPassword"
                    onChange={() =>
                      this.setState({
                        rememberPassword: !this.state.rememberPassword
                      })
                    }
                  />
                </label>
                <span>{translate('Remember password')}</span>
              </div>
              <div className="reset-password-link">
                <span
                  data-testid="resetPassword"
                  onClick={() =>
                    history.push(
                      `/${this.props.match?.params.module}/reset_password`
                    )
                  }
                  className="span-link"
                >
                  {translate('Forgot your password?')}
                </span>
              </div>
            </div>
            <div className="login-welcome-buttons">
              <button
                data-testid="logIn"
                className="contact-btn aic-btn"
                type="submit"
              >
                {translate('Log in')}
              </button>
              <button
                data-testid="signUp"
                className="login-btn aic-btn"
                onClick={() =>
                  history.push(`/${this.props.match?.params.module}/register`)
                }
              >
                {translate('Sign up')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default NewLogin;
