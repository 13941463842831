import React from 'react';

const VectorClose = () => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 7.3749L0.375 1.7499L1.1625 0.962402L6 5.7999L10.8375 0.962402L11.625 1.7499L6 7.3749Z"
        fill="#32343A"
      />
    </svg>
  );
};

export default VectorClose;
