import React from 'react';

const MapMarkerSvg = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6696 23.3333L10.5796 14.7333C9.58283 13.5563 8.94089 12.1204 8.7286 10.5927C8.51631 9.06508 8.74241 7.50854 9.38056 6.10441C10.0187 4.70027 11.0426 3.50633 12.3331 2.66164C13.6236 1.81695 15.1275 1.35627 16.6696 1.33325C18.8074 1.35438 20.8496 2.22185 22.3491 3.74567C23.8485 5.26949 24.683 7.32545 24.6696 9.46325C24.6705 11.3282 24.0356 13.1377 22.8696 14.5933L16.6696 23.3333ZM16.6696 3.33325C15.0615 3.35172 13.5264 4.00763 12.4015 5.15696C11.2765 6.30629 10.6536 7.85508 10.6696 9.46325C10.6763 10.9311 11.2044 12.3488 12.1596 13.4633L16.6696 19.8533L21.2996 13.3333C22.1797 12.2347 22.6625 10.8708 22.6696 9.46325C22.6857 7.85508 22.0628 6.30629 20.9378 5.15696C19.8129 4.00763 18.2778 3.35172 16.6696 3.33325Z"
        fill="#32343A"
      />
      <path
        d="M16.6667 10.3333C17.7713 10.3333 18.6667 9.43782 18.6667 8.33325C18.6667 7.22868 17.7713 6.33325 16.6667 6.33325C15.5622 6.33325 14.6667 7.22868 14.6667 8.33325C14.6667 9.43782 15.5622 10.3333 16.6667 10.3333Z"
        fill="#32343A"
      />
      <path
        d="M28.6667 11.3333H26.6667V13.3333H28.6667V27.3333H4.66675V13.3333H6.66675V11.3333H4.66675C4.13632 11.3333 3.62761 11.544 3.25253 11.919C2.87746 12.2941 2.66675 12.8028 2.66675 13.3333V27.3333C2.66675 27.8637 2.87746 28.3724 3.25253 28.7475C3.62761 29.1225 4.13632 29.3333 4.66675 29.3333H28.6667C29.1972 29.3333 29.7059 29.1225 30.081 28.7475C30.456 28.3724 30.6667 27.8637 30.6667 27.3333V13.3333C30.6667 12.8028 30.456 12.2941 30.081 11.919C29.7059 11.544 29.1972 11.3333 28.6667 11.3333Z"
        fill="#32343A"
      />
    </svg>
  );
};

export default MapMarkerSvg;
