import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './PasswordDifficultyBar.scss';
import translate from '../../utils/translate';
import { DEFAULT_PASSWORD_DIFFICULTY_BAR_COLOR } from '../../utils/const';

const PasswordDifficultyBar = (props) => {
  const { pass, setPasswordValidity } = props;
  const [lineWidth, setLineWidth] = useState(0);
  const [description, setDescription] = useState('Minimum 8 characters');
  const [suggestion, setSuggestion] = useState(null);
  const [color, setColor] = useState(DEFAULT_PASSWORD_DIFFICULTY_BAR_COLOR);

  useEffect(() => {
    setPasswordValidity && setPasswordValidity('confirmPassword', !suggestion);
  }, [suggestion]);

  useEffect(() => {
    if (!pass) {
      setLineWidth(0);
      setColor(DEFAULT_PASSWORD_DIFFICULTY_BAR_COLOR);
      setDescription(translate('Minimum 8 characters'));
      return;
    }

    axios
      .post('api/help/password_check', { password: pass })
      .then((response) => {
        if (response.status === 200) {
          const { strength, color, description, suggestions } = response.data;
          setLineWidth(Math.round(strength * 100));
          setColor(color);
          setDescription(translate(description));
          setSuggestion(suggestions);
        } else {
          console.log('Blad, Status inny niz 200');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [pass]);

  return (
    <>
      <div className="password-bar-container">
        <div
          className="password-bar-difficulty"
          style={{
            width: `${lineWidth}%`,
            background: `${color}`
          }}></div>
        <p
          className="password-bar-description"
          style={{ color: `${color}` }}>
          {description}
        </p>
      </div>
    </>
  );
};

export default PasswordDifficultyBar;
