import React, { useEffect } from 'react';
import 'utils/timeline.scss';
import './ProductHistory.scss';
import ProductActions from './ProductAction/ProdyctAction';
import { getActionList } from '../../../../actions/actions';
import { useSelector } from 'react-redux';

export default function ProductHistory() {
  const language = useSelector((state) => state.appState.preferences.language);
  const boiler = useSelector((state) => state.productInfo.boiler);

  useEffect(() => {
    const action_group_id = boiler?.product_type?.action_group_id;
    if (!action_group_id) {
      return;
    }
    getActionList(action_group_id);
  }, [language, boiler]);

  return (
    <div className="product-history-wrapper">
      <ProductActions />
    </div>
  );
}
