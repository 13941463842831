import React from 'react';
import './profile.scss';
import store from 'store';
import axios from 'axios';
import translate from 'utils/translate';
import { bindActionCreators } from 'redux';
import {
  changeLanguageAndGetLegalDocuments,
  editUserProfile,
  getDepartments,
  getLegalDocuments,
  setLanguage,
  showError,
  togglePreferencesModal
} from '../../actions/actions';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import UserData from './UserData';
import CookiesData from './CookiesData';
import Appearance from './Appearance';

class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language: this.props.language,
      cookiePermissionModalOpen: false,
      isDeleteAccountModalOpen: false,
      cookiesDetailsIsOpen: false,
      user: this.props.user,
      cookiesPermissions: {
        necessary: true,
        authentication: false,
        preferential: false
      },
      visibilityTestProducts:
        JSON.parse(localStorage.getItem('isTestProductsShown')) ?? false
    };
    getDepartments();
    this.state.user.department = this.state.user.department || '';
    this.state.user.phone_number = this.state.user.phone_number || '';
  }

  setChoice = (key) => {
    let copyOfPermissions = Object.assign({}, this.state.cookiesPermissions);
    copyOfPermissions[key] = !copyOfPermissions[key];
    this.setState({ cookiesPermissions: copyOfPermissions });
    Cookies.set('cookiePermissions', JSON.stringify(copyOfPermissions));
  };

  showCookiesDetails = () =>
    this.setState({
      cookiesDetailsIsOpen: !this.state.cookiesDetailsIsOpen
    });

  handleChange = (event) => {
    if (event.target.value.trim() !== '') {
      let user = { ...this.state.user };
      user[event.target.id] = event.target.value;
      this.setState({ user });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    changeLanguageAndGetLegalDocuments(this.state.language);
    const config = {
      url: '/api/user/edit',
      method: 'post',
      data: this.state.user
    };

    axios(config)
      .then((response) => {
        store.dispatch({
          type: 'showMsg',
          value: { msg: translate('Saved'), type: 'success' }
        });
        this.props.editUserProfile(this.state.user);
        togglePreferencesModal(false);
      })
      .catch((error) => showError(error));
  };

  changeLanguage = (key) => this.setState({ language: key });

  toggleShowProducts = (e) => {
    this.setState({ visibilityTestProducts: e.target.checked });
    localStorage.setItem(
      'isTestProductsShown',
      JSON.stringify(e.target.checked)
    );
  };

  componentDidMount() {
    axios
      .get('/api/department/codes')
      .then((response) => this.setState({ departmentsList: response.data }))
      .catch((error) => console.log(error));

    getLegalDocuments();

    //cookies
    let permissionsFromCookies = Cookies.get('cookiePermissions');
    if (permissionsFromCookies) {
      this.setState({
        cookiesPermissions: JSON.parse(permissionsFromCookies)
      });
    }
  }

  toogleDeleteModal = () => {
    this.setState((state) => {
      return {
        isDeleteAccountModalOpen: !state.isDeleteAccountModalOpen
      };
    });
  };

  render() {
    let departments = [];
    if (this.props.departments) {
      departments = this.props.departments.map((item) => {
        return (
          <div
            className="select-menu-item"
            key={item.code}
            onClick={() => {
              this.setState({
                user: {
                  ...this.state.user,
                  department: item.code
                }
              });
            }}>
            {item.country}
          </div>
        );
      });
    }

    return (
      <form
        className="apperance-form profile apperance-form-show"
        onSubmit={this.handleSubmit}>
        {!this.state.isDeleteAccountModalOpen && (
          <>
            <div className="preferences-section-container">
              <div className="preferences-section">
                <UserData
                  user={this.state.user}
                  handleChange={this.handleChange.bind(this)}
                  departments={departments}
                />

                <Appearance
                  changeLanguage={this.changeLanguage}
                  toggleShowProducts={this.toggleShowProducts}
                  visibility={this.state.visibilityTestProducts}
                />
              </div>

              <CookiesData
                showCookiesDetails={this.showCookiesDetails}
                legalDocuments={this.props.legalDocuments}
                setChoice={this.setChoice}
                cookiesDetailsIsOpen={this.state.cookiesDetailsIsOpen}
                cookiesPermissions={this.state.cookiesPermissions}
              />
            </div>

            <button
              className="preferences-btn preferences-btn-background"
              type="submit">
              {translate('Save')}
            </button>
          </>
        )}
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.appState.preferences.language,
    departments: state.appState.departments,
    legalDocuments: state.appState.legalDocuments,
    user: state.user
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLanguage,
      getDepartments,
      editUserProfile
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
