import React, { useRef, useState, useEffect } from 'react';
import './findSpareparts.scss';
import {
  changeSearchValue,
  clearSearchValue,
  showError
} from '../../../actions/actions';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import SparePartsSearch from './SparepartsSearch/SparePartsSearch';
import SearchResults from './SearchResults/SearchResults';
import { minSNLength, UserRoles } from 'utils/const';
import SwitchRecentProducts from './SwitchRecentProducts/SwitchRecentProducts';

const SparepartFind = () => {
  const dispatch = useDispatch();

  const isDistributor = useSelector((state) =>
    state?.user.roles.includes(UserRoles.DISTRIBUTION)
  );
  const searchValue = useSelector(
    (state) => state?.sparepartsSearch.searchInput
  );

  const [results, setResults] = useState([]);
  const [isResultsLoaded, setIsResultsLoaded] = useState(false);

  const [showSearchButton, setShowSearchButton] = useState(false);
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(false);

  //states for search by serial number
  const [searchBySNMode, setSearchBySNMode] = useState(false);
  const [resultsBySN, setResultsBySN] = useState(null);
  const [receivedImage, setReceivedImage] = useState(null);
  const [showShortNameError, setShowShortNameError] = useState(false);

  const searchRef = useRef(null);
  const inputRef = useRef(null);

  const handleScroll = () => {
    if (
      !lastPage &&
      searchRef.current.scrollTop > searchRef.current.scrollHeight - 1000
    ) {
      setPage(page + 1);
      searchItems(searchValue, results);
    }
    if (searchRef.current.scrollTop > 300) {
      setShowSearchButton(true);
    } else {
      setShowSearchButton(false);
    }
  };

  const inputHandleChange = (event) => {
    setResults([]);
    setIsResultsLoaded(true);
    setPage(0);
    searchItems(event.target.value, []);

    dispatch(changeSearchValue(event.target.value));
  };

  const handleSearchBySN = (e) => {
    setIsResultsLoaded(true);
    const value = e.target.value;
    dispatch(changeSearchValue(e.target.value));
    if (value.length >= minSNLength) {
      setShowShortNameError(false);
      searchBySerialNumber(value);
    } else {
      setShowShortNameError(true);
      setResultsBySN(null);
    }
  };

  const toggleSearchMode = () => {
    if (searchBySNMode && searchValue) {
      searchItems(searchValue);
    } else searchBySerialNumber(searchValue);
    setSearchBySNMode((prev) => !prev);
  };

  const onScrollButtonClick = () => {
    searchRef.current.animate({ scrollTop: 0 }, 2000);
    inputRef.current.focus();
  };
  const searchItems = (phrase, prevResults = []) => {
    if (!phrase.trim()) return;
    setIsResultsLoaded(false);
    axios({
      url: '/api/spareparts/find/' + phrase + `?limit=50&page=${page}`
    })
      .then((response) => {
        if (response.data.length >= 50) {
          setLastPage(false);
        } else {
          setLastPage(true);
        }
        setResults(prevResults.concat(response.data));
        setIsResultsLoaded(true);
      })
      .catch((err) => {
        setResults([]);
        setIsResultsLoaded(true);
        if (err.response.status !== 404) {
          showError(err);
        }
      });
  };

  useEffect(() => {
    if (searchValue.length > 3) searchItems(searchValue);
  }, []);

  const searchBySerialNumber = (sn) => {
    if (sn.length < minSNLength) {
      setShowShortNameError(true);
      setResultsBySN(null);
      return;
    }
    setIsResultsLoaded(false);
    axios('api/product/light/' + sn.trim())
      .then((res) => {
        const id = res.data?.product_type?.id;
        if (id) {
          axios(`api/product_type_revision/${id}`)
            .then((res) => {
              setIsResultsLoaded(true);
              setResultsBySN(res.data);
            })
            .catch((err) => {
              console.log(err);
              setIsResultsLoaded(true);
              setResultsBySN(null);
            });

          axios(`api/product_type/${id}`)
            .then((res) => {
              const img = res.data.images[0].url;
              setReceivedImage(img);
            })
            .catch((err) => {
              setReceivedImage(null);
              console.log(err);
            });
        }
      })
      .catch((err) => {
        setIsResultsLoaded(true);
        setResultsBySN(null);
        setReceivedImage(null);
        if (err.response.status !== 404) {
          showError(err);
        }
      });
  };

  const clearSearchHandler = () => {
    setResults([]);
    setResultsBySN(null);
    dispatch(clearSearchValue());
  };

  return (
    <div className="find-parts-modal">
      <div
        className="find-parts-body"
        ref={searchRef}
        onScroll={(e) => handleScroll(e)}
      >
        <div className="inner-body">
          <SparePartsSearch
            inputRef={inputRef}
            searchPhrase={searchValue}
            handleChange={inputHandleChange}
            handleSearchBySN={handleSearchBySN}
            isResultsLoaded={isResultsLoaded}
            clearSearchHandler={clearSearchHandler}
            searchBySNMode={searchBySNMode}
            toggleSearchMode={toggleSearchMode}
            showShortNameError={showShortNameError}
          />

          {!isDistributor && !searchBySNMode && (
            <SwitchRecentProducts
              searchPhrase={searchValue}
              results={results}
            />
          )}

          {!!searchValue && (
            <SearchResults
              searchPhrase={searchValue}
              results={results}
              resultsBySN={resultsBySN}
              showSearchButton={showSearchButton}
              isResultsLoaded={isResultsLoaded}
              onScrollButtonClick={onScrollButtonClick}
              searchBySNMode={searchBySNMode}
              receivedImage={receivedImage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SparepartFind;
