import COILMASTER from 'assets/img/boiler_images/coilmaster.png';
import NESTA from 'assets/img/boiler_images/nesta.png';
import NESTA_CHROME from 'assets/img/boiler_images/nesta_chrome.png';
import NESTA_PLUS from 'assets/img/boiler_images/nesta_plus.png';
import TEXAS from 'assets/img/boiler_images/texas.png';
import SILOX from 'assets/img/boiler_images/silox.png';
import TMU from 'assets/img/boiler_images/tmu.png';

export const boilerImages = {
  COILMASTER,
  NESTA,
  NESTA_CHROME,
  NESTA_PLUS,
  TEXAS,
  SILOX,
  TMU
};
