import React, { useEffect, useState } from 'react';
import './SparepartCategoriesPage.scss';
import translate from '../../../utils/translate';
import { useSelector } from 'react-redux';
import { addToPath } from '../../../actions/actions';
import baseAIClogo from 'assets/svg/baseAIClogo.svg';
import history from '../../../history';
import { InputSpinner } from '../../../components/Spinners/Spinners';
import { UserRoles } from '../../../utils/const';

const SparepartCategories = () => {
  const isDistributor = useSelector(( state ) => state?.user?.roles.includes(UserRoles.DISTRIBUTION));
  const { userLoadHistory, groupedProductTypes } = useSelector(( state ) => state.spareparts);
  const { tIndex, fIndex, mIndex, gIndex } = useSelector(( state ) => state.categoriesPath);

  const [type, setType] = useState(null);
  const [family, setFamily] = useState(null);
  const [model, setModel] = useState(null);
  const [group, setGroup] = useState(null);

  const gotoProductParts = ( product ) => {
    history.push('/spareparts');
    history.push('dashboard/' + product.serial_number + '/parts'); //todo: czy musi być przekierowanie na dashboard?
  };

  const selectFamily = ( item, i ) => {
    setFamily(item);
    addToPath({ fIndex: i });
    selectRevision(undefined, undefined);
  };

  const selectType = ( item, i ) => {
    setType(item);
    addToPath({ tIndex: i });
    selectFamily(undefined, undefined);
  };

  const selectRevision = ( item, i ) => {
    setModel(item);
    addToPath({ mIndex: i });
    setGroup(undefined);
  };

  useEffect(() => {
    if (mIndex !== null && mIndex !== undefined) {
      setType(groupedProductTypes[tIndex]);
      setFamily(groupedProductTypes[tIndex].product_families[fIndex]);
      setModel(groupedProductTypes[tIndex].product_families[fIndex].revisions[mIndex]);
    }
  }, []);

  const selectGroup = async ( groupItem, i ) => {
    setGroup(groupItem);
    addToPath({ gIndex: i });
    const url = `/spareparts?groupName=${encodeURIComponent(JSON.stringify(groupItem))}&model=${encodeURIComponent(JSON.stringify(model))}`;
    window.open(url, '_blank');
  };

  return (<div className='spareparts-categories-modal'>
      <div className='modal-header'>
        <div className='modal-logotype'>
          <img
            src={baseAIClogo}
            height='50'
            className='d-inline-block align-top'
            alt=''
          />
        </div>
      </div>
      <div className='modal-content'>
        <div className='categories-container'>
          {!isDistributor && (<div className='categories-recent'>
              <div className='categories-title'>{translate('Recent')}:</div>
              <div className='recent-items'>
                {userLoadHistory.map(( product, i ) => {
                  if (i > 2) return null;
                  return (<div
                      key={i}
                      className='recent-item'
                      onClick={() => gotoProductParts(product)}
                    >
                      <span>{product.family}</span>
                      <div className='vertical-separtor' />
                      <span>{product.model}</span>
                      <div className='vertical-separtor' />
                      <span className='model-index'>
                        {product.serial_number}
                      </span>
                    </div>);
                })}
              </div>
            </div>)}

          <div className='categories-selector-container'>
            <div className='categories-title'>{translate('Categories')}</div>

            <div className='categories-tree'>
              <div className='categories-column'>
                <div className='categories-column-title'>
                  {translate('Type')}
                </div>
                <div className='categories-list types'>
                  {groupedProductTypes && groupedProductTypes.length > 0
                    ? (groupedProductTypes.map(( typeItem, i ) => {
                        return (<div
                            className={`category-item ${tIndex === i
                              ? 'picked'
                              : ''}`}
                            key={i}
                            onClick={() => selectType(typeItem.category, i)}
                          >
                            {translate(typeItem.category)}
                          </div>);
                      }))
                    : (<div>
                        <InputSpinner />
                      </div>)}
                </div>
              </div>

              <div className={`categories-column ${type
                ? ''
                : 'not-picked'}`}>
                <div className='categories-column-title'>
                  {translate('Family')}
                </div>
                <div className='categories-list '>
                  {groupedProductTypes && groupedProductTypes.length > 0
                    ? (!type && (<div className='disabled-category'>
                          {translate('Choose type')}
                        </div>))
                    : (<InputSpinner />)}
                  {type && groupedProductTypes[tIndex].product_families.map(( familyItem, i ) => {
                    return (<div
                        className={`category-item family-item ${fIndex === i
                          ? 'picked'
                          : ''}`}
                        key={i}
                        onClick={() => selectFamily(familyItem, i)}
                      >
                        {familyItem.family} {familyItem.subfamily}
                      </div>);
                  })}
                </div>
              </div>

              <div
                className={`categories-column ${family
                  ? ''
                  : 'not-picked'}`}
              >
                <div className='categories-column-title'>
                  {translate('Model')}
                </div>
                <div className='categories-list'>
                  {groupedProductTypes && groupedProductTypes.length > 0
                    ? (!family && (<div className='disabled-category'>
                          {translate('Choose family')}
                        </div>))
                    : (<InputSpinner />)}
                  {family && family.revisions
                    .sort(( { power: b }, { power: a } ) => b - a)
                    .map(( modelItem, i ) => {
                      return (<div
                          className={`category-item model-item ${mIndex === i
                            ? 'picked'
                            : ''}`}
                          key={i}
                          onClick={() => selectRevision(modelItem, i)}
                        >
                          {modelItem.model}{' '}
                          <span className='categories-product-index'>
                              ({modelItem.product_index})
                            </span>
                        </div>);
                    })}
                </div>
              </div>

              <div className={`categories-column ${model
                ? ''
                : 'not-picked'}`}>
                <div className='categories-column-title'>
                  {translate('Group')}
                </div>
                <div className='categories-list'>
                  {groupedProductTypes && groupedProductTypes.length > 0
                    ? (!model && (<div className='disabled-category'>
                          {translate('Choose model')}
                        </div>))
                    : (<InputSpinner />)}
                  {model?.sparepart_groups.map(( groupItem, i ) => {
                    return (
                      <div
                        key={i}
                        onClick={() => selectGroup(groupItem, i)}
                          className={`category-item group-item ${gIndex === i
                            ? 'picked'
                            : ''}`}
                        >
                          {groupItem.name}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);
};

export default SparepartCategories;
