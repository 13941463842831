import React, { useEffect, useState } from 'react';
import './sparepartsRow.scss';
import { connect } from 'react-redux';
import translate from '../../../../../utils/translate';
import AnimateHeight from 'react-animate-height';
import history from '../../../../../history';
import { bindActionCreators } from 'redux';
import {
  addToCart,
  closePartDetails,
  updateCart
} from '../../../../../actions/actions';

const SparepartsRow = React.memo(
  ({
    sparepart,
    selectSparepartFromTable,
    revision,
    addToCart,
    updateCart,
    cart,
    closeAllPartDetails,
    sparepartsSelectedPart
  }) => {
    const [height, setHeight] = useState(0);
    const [partCount, setPartCount] = useState(0);
    const decrementQuantity = (e) => {
      e.stopPropagation();
      if (partCount === 0) return;

      updateCart(sparepart, -1);
      setPartCount((prevState) => prevState - 1);
    };

    const incrementQuantity = (e) => {
      e.stopPropagation();
      addToCart(sparepart, revision);
      setPartCount((prevState) => prevState + 1);
    };

    useEffect(() => {
      const findPartInCart = cart.find(
        (item) => item.index === sparepart.index
      );

      findPartInCart ? setPartCount(findPartInCart.count) : setPartCount(0);
    }, [cart]);

    useEffect(() => {
      setHeight(0);
    }, [closeAllPartDetails]);

    useEffect(() => {
      if (sparepartsSelectedPart === sparepart.index) {
        setTimeout(() => {
          setHeight('auto');
        });
      } else if (height !== 0) {
        setTimeout(() => {
          setHeight(0);
        });
      }
    }, [sparepartsSelectedPart]);

    const choosePart = async () => {
      await new Promise((resolve) => {
        setTimeout(() => {
          height === 0 ? setHeight('auto') : setHeight(0);
        });
        resolve();
      })
        .then(() => closePartDetails())
        .finally(() => selectSparepartFromTable(sparepart));
    };

    return (
      <>
        <tr
          id={`tablepart${sparepart.index}`}
          className={height === 0 ? `table-row` : `table-row table-row-open`}
          onClick={choosePart}
        >
          <td>{sparepart.svg_callout}</td>
          <td>{sparepart.translated_description}</td>
          <td>{sparepart.index}</td>
          <td>
            <div className="quantity-modificator-cell">
              {sparepart.is_set && (
                <div className="quantity-modificator">
                  <div className="quantity-button" onClick={decrementQuantity}>
                    -
                  </div>
                  <div className="quantity-number">{partCount}</div>
                  <div className="quantity-button" onClick={incrementQuantity}>
                    +
                  </div>
                </div>
              )}
            </div>
          </td>
        </tr>
        <tr className="part-details">
          <td colSpan={4} className="p-0">
            <AnimateHeight id="example-panel" duration={1000} height={height}>
              <div className="part-details-container">
                <div className="part-details-info">
                  <div className="part-details-info-content">
                    <div className="part-details-info-item">
                      <span>{translate('Group')}</span>
                      <span>{sparepart.group}</span>
                    </div>
                    <div className="part-details-info-item">
                      <span>{translate('Quantity')}</span>
                      <span>{sparepart.quantity + ' ' + sparepart.unit}</span>
                    </div>
                  </div>
                </div>
                <div className="part-details-info-kit">
                  <div
                    onClick={() =>
                      history.push(`/spareparts/details/${sparepart.index}`)
                    }
                  >
                    {translate("Kit's elements >")}
                  </div>
                </div>
              </div>
            </AnimateHeight>
          </td>
        </tr>
      </>
    );
  }
);

const mapStateToProps = (state) => {
  return {
    cart: state.shoppingList.cart,
    sparepartsSelectedPart: state.spareparts.sparepartsSelectedPart,
    closeAllPartDetails: state.spareparts.closeAllPartDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ addToCart, updateCart }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SparepartsRow);
