import React, { useEffect } from 'react';
import ProductInfo from './ProductInfo/ProductInfo';
import ProductHistory from './ProductHistory/ProductHistory';
import { loadProductHistory, saveBoilerId } from '../../../actions/actions';
import ProductPageHeader from './ProductPageHeader/ProductPageHeader';
import './ProductDashboard.scss';
import { useSelector } from 'react-redux';

export function useLoadingWhileUpdateBoilerInfo(setInfo) {
  const newInfo = useSelector((state) => state.productInfo.boiler);

  useEffect(() => {
    setInfo(newInfo);
  }, [newInfo]);

  useEffect(() => {
    setInfo(undefined);
  }, []);
}

const ProductDashboard = (props) => {
  const language = useSelector((state) => state.appState.preferences.language);

  useEffect(() => {
    saveBoilerId(props.match.params.boiler_id);
  }, []);

  useEffect(() => {
    loadProductHistory(props.match.params.boiler_id);
  }, [language]);

  return (
    <div className="product-wrapper">
      <ProductPageHeader />
      <ProductInfo serial_number={props.match.params.boiler_id} />
      <ProductHistory />
    </div>
  );
};

export default ProductDashboard;
