import React from 'react';

const Repair = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1875 5.0625H13.5V3.375C13.5 3.07663 13.3815 2.79048 13.1705 2.5795C12.9595 2.36853 12.6734 2.25 12.375 2.25H5.625C5.32663 2.25 5.04048 2.36853 4.8295 2.5795C4.61853 2.79048 4.5 3.07663 4.5 3.375V5.0625H2.8125C2.36495 5.0625 1.93572 5.24029 1.61926 5.55676C1.30279 5.87322 1.125 6.30245 1.125 6.75V14.625C1.125 14.9234 1.24353 15.2095 1.4545 15.4205C1.66548 15.6315 1.95163 15.75 2.25 15.75H15.75C16.0484 15.75 16.3345 15.6315 16.5455 15.4205C16.7565 15.2095 16.875 14.9234 16.875 14.625V6.75C16.875 6.30245 16.6972 5.87322 16.3807 5.55676C16.0643 5.24029 15.6351 5.0625 15.1875 5.0625ZM5.625 3.375H12.375V5.0625H5.625V3.375ZM15.75 14.625H2.25V9.5625H6.75V12.375H11.25V9.5625H15.75V14.625ZM7.875 9.5625H10.125V11.25H7.875V9.5625ZM2.25 8.4375V6.75C2.25 6.60082 2.30926 6.45774 2.41475 6.35225C2.52024 6.24676 2.66332 6.1875 2.8125 6.1875H15.1875C15.3367 6.1875 15.4798 6.24676 15.5852 6.35225C15.6907 6.45774 15.75 6.60082 15.75 6.75V8.4375H2.25Z"
        fill="#32343A"
      />
    </svg>
  );
};

export default Repair;
