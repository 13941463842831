import React from 'react';

const Circle = ({ color = 'grey' }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="8"
        cy="8.5"
        r="8"
        fill={`${color}`}
      />
    </svg>
  );
};

export default Circle;
