const serviceSupportInitialState = { loading: false, error: '', success: '' };

export const ServiceSupportReducer = (
  state = serviceSupportInitialState,
  action
) => {
  switch (action.type) {
    case 'try_to_service_support_intercept':
      return {
        loading: true,
        success: '',
        error: ''
      };
    case 'service_support_intercept_error':
      return {
        error: action.payload,
        loading: false,
        success: ''
      };
    case 'service_support_intercept_success':
      return {
        success: action.payload,
        loading: false,
        error: ''
      };

    default:
      return state;
  }
};
