import { userReducer } from './userReducer';
import { WarrantyExtensionReducer } from './warrantyReducer';
import { ServiceSupportReducer } from './serviceSupportReducer';
import { combineReducers } from 'redux';
import { sparepartsReducer } from './sparepartsReducer';
import { productInfoReducer } from './productInfoReducer';
import { dashboardReducer } from './dashboardReducer';
import { appStateReducer } from './appStateReducer';
import { shoppingListReducer } from './shoppingListReducer';
import { sparepartsCategoriesReducer } from './sparepartsCategoriesReducer';
import { sparepartsSearchReducer } from './sparepartsSearchReducer';

export const reducers = combineReducers({
  user: userReducer,
  warrantyExtension: WarrantyExtensionReducer,
  serviceSupportIntercept: ServiceSupportReducer,
  spareparts: sparepartsReducer,
  productInfo: productInfoReducer,
  shoppingList: shoppingListReducer,
  dashboard: dashboardReducer,
  appState: appStateReducer,
  categoriesPath: sparepartsCategoriesReducer,
  sparepartsSearch: sparepartsSearchReducer
});
