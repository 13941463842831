import React, { useState } from 'react';
import './ProducActionCard.scss';
import ProductActionCardHeader from './ProductCardHeader/ProductActionCardHeader';
import './ProductCardBody/ProducActionCardBody.scss';
import ProductActionCardBody from './ProductCardBody/ProductActionCardBody';

const ProductActionCard = ({ action }) => {
  const [height, setHeight] = useState(0);

  return (
      <div
        className={
          !action.hidden
            ? `product-history-timeline-card`
            : `product-history-timeline-card hidden-card`
        }>
        <ProductActionCardHeader
          action={action}
          height={height}
          setHeight={setHeight}
        />
        <ProductActionCardBody
          height={height}
          action={action}
        />
      </div>
  );
};

export default ProductActionCard;
