import React, { Component } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import Navbar from './../../components/Navbar/Navbar';
import Traveller from './../../scenes/Traveller/Traveller';
import LoginSwitch from './../../scenes/Login/LoginSwitch';
import history from './../../history';
import axios from 'axios';
import store from 'store';
import NotFound from 'components/NotFound/NotFound';
import './app.scss';
import PreferencesDialog from '../../components/PreferencesDialog/PreferencesDialog';
import CookiesDialog from '../../components/CookiesDialog/CookiesDialog';
import ErrorAlert from '../../components/ErrorAlert/ErrorAlert';
import CartModal from '../../components/CartModal/CartModal';
import { routingTable, UserRoles } from 'utils/const';
import { getTranslations, logout, showError } from 'actions/actions';
import ResetPasswordEmailForm from '../ResetPassword/ResetPasswordEmailForm';
import UserConfirm from '../AccountConfirmations/UserConfirm';
import SparepartsSwitch from '../Spareparts/SparepartsSwitch';
import DashboardSwitch from 'scenes/Dashboard/DashboardSwitch';
import ChangePinToPassword from '../Login/ChangePinToPassword';
import { connect } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import ExtendWarranty from '../../components/ExtendWarranty/ExtendWarranty';
import ServiceSupportIntercept
  from '../../components/ServiceSupportIntercept/ServiceSupportIntercept';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';

class App extends Component {
  constructor(props) {
    super(props);

    getTranslations();

    this.state = {
      loaded: false,
      language: store.getState().appState.preferences.language,
      routing_table: routingTable
    };
    this.state.selectedRoute = this.state.routing_table[0];
  }

  async componentDidMount() {
    const userCheckd = this.checkUser();
    const translationsCheckd = this.checkTranslations();
    await Promise.all([userCheckd, translationsCheckd]);
    this.setState({
      loaded: true
    });
    this.checkIsNeedChangePassword();
  }

  componentDidUpdate() {
    this.checkIsNeedChangePassword();
  }

  checkIsNeedChangePassword() {
    if (this.props.user && this.props.user.need_to_change_password) {
      history.push(`/user/change_password`);
    }
  }

  async checkUser() {
    return await axios({ url: '/api/auth/login' })
      .then((response) => {
        store.dispatch({ type: 'login', value: response.data });
      })
      .catch((error) => {
        console.dir(error);
      });
  }

  async checkTranslations() {
    if (
      store.getState().appState.translations[
        store.getState().appState.preferences.language
      ]
    ) {
      return true;
    }

    return await axios({ url: 'api/help/translate/' })
      .then((response) => {
        store.dispatch({ type: 'setLocaleDict', value: response.data });
      })
      .catch((error) => showError(error));
  }

  render() {
    return (
      <Router history={history}>
        {this.state.loaded && (
          <>
            <PreferencesDialog />
            <CookiesDialog />
            <ErrorAlert />
            <CartModal />
            {this.props.user && !this.props.user.need_to_change_password ? (
              <Navbar onLogout={logout} />
            ) : null}

            <Switch>
              <Route
                exact
                path="/login"
                render={() => <Redirect to="/dashboard/login" />}
              />
              <Route
                exact
                path="/register"
                render={() => <Redirect to="/dashboard/register" />}
              />
              <Route path="/:module/login" component={LoginSwitch} />
              <Route path="/:module/register" component={LoginSwitch} />
              <Route path="/:module/reset_password" component={LoginSwitch} />
              <Route path="/:module/change_password" component={LoginSwitch} />
              <Route path="/login" component={LoginSwitch} />
              <Route path="/:module/privacy_policy" component={PrivacyPolicy} />
              <PrivateRoute path="/product_info" component={Traveller} />
              <PrivateRoute path="/spareparts" component={SparepartsSwitch} />
              <PrivateRoute path="/dashboard" component={DashboardSwitch} />
              {/* routes open from email  */}
              <PrivateRoute
                path="/accept_warranty_extension/:token"
                component={ExtendWarranty}
              />
              <PrivateRoute
                path="/accept_service_support_intercept/:token"
                component={ServiceSupportIntercept}
              />
              {/* routes not used? - check  component scenes=>login=>LoginSwitch */}
              <PrivateRoute
                path="/change_password"
                component={ChangePinToPassword}
              />
              <Route
                exact
                path="/reset_password"
                render={() => <ResetPasswordEmailForm />}
              />
              <Redirect
                from="/reset_password/:token"
                to="/product_info/reset_password/:token"
              />
              <Redirect from="/tracker/:sn" to="/product_info/:sn" />
              <Redirect from="/change_password/" to="/user/change_password/" />
              {/* registration */}
              <Route
                path="/user_confirm/:token"
                render={() => <UserConfirm type="account" />}
              />{' '}
              {/* call from email link when end-user confirm email or department confirm installer/serviceMenager */}
              <Route
                path="/user_confirm_email/:token"
                render={() => <UserConfirm type="email" />}
              />{' '}
              {/* call from email link when installer/serviceMenager confirm email and wait for department confirmation */}
              <Route
                path="/user_account_not_confirmed/"
                render={(props) => (
                  <UserConfirm {...props} type="confirmError" />
                )}
              />{' '}
              {/* call from NewLogin component if is not user email or department confirmation*/}
              <Route
                exact
                path="/"
                render={() => {
                  if (!this.props.user) {
                    return <Redirect to="/dashboard/login" />;
                  } else if (
                    this.props.user.roles?.includes(UserRoles.DISTRIBUTION)
                  ) {
                    return <Redirect to="/spareparts" />;
                  } else {
                    return <Redirect to="/dashboard" />;
                  }
                }}
              />
              <Route component={NotFound} />
            </Switch>
          </>
        )}
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, null)(App);
