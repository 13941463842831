import React, { useMemo } from 'react';
import translate from '../translate';
import Tooltip from 'react-tooltip-lite';
import ErrorBoldIcon from '../../assets/svg/errorbold';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import InfoBoldIcon from '../../assets/svg/infobold';
import ExternalIcon from '../../assets/svg/external';

const UseDataColumns = () => {
  return useMemo(
    () => [
      {
        name: translate('Spare part').toUpperCase(),
        sortable: true,
        wrap: true,
        selector: (item) =>
          item.part.name || item.part.sparepart
            ? item.part.sparepart.name
            : null
      },
      {
        name: translate('index').toUpperCase(),
        sortable: true,
        cell: (part) => {
          if (part.part_index !== part.part.part_index) {
            return (
              <div className="product-parts-row-with-tooltip">
                <Tooltip
                  content={
                    <div className="product-parts-tooltip-content">
                      {translate('This kit is out of date.')}
                    </div>
                  }
                  arrowSize={4}
                  tagName="div"
                  direction="down"
                  className="own-tooltip"
                  forceDirection>
                  <ErrorBoldIcon className="fill-gray medium-svg" />
                </Tooltip>
                {part.part_index}
              </div>
            );
          } else {
            return <div>{part.part_index}</div>;
          }
        }
      },
      {
        name: translate('serial number').toUpperCase(),
        sortable: true,
        wrap: true,
        selector: (item) => item.part.identifying_number
      },
      {
        name: translate('group').toUpperCase(),
        sortable: true,
        selector: (item) =>
          item.part.sparepart ? item.part.sparepart.group : null
      },
      {
        name: translate('www_dashboard_installation').toUpperCase(),
        sortable: true,
        selector: (part) => part.part.date_inserted
      },
      {
        name: translate('included_in').toUpperCase(),
        sortable: true,
        cell: (part) => {
          return (
            <div className="included_in-container">
              {part.included_in?.map((sPart) => {
                return (
                  <Link
                    key={sPart.index}
                    to={'/spareparts/details/' + sPart.index}
                    className="included_in-item">
                    {sPart.index}
                  </Link>
                );
              })}
            </div>
          );
        }
      },
      {
        name: translate('End of warranty').toUpperCase(),
        sortable: true,
        cell: (part) => {
          if (moment(part.part.warranty_end_date) < moment.now()) {
            return (
              <div className="product-parts-row-with-tooltip">
                <Tooltip
                  content={
                    <div className="product-parts-tooltip-content">
                      {translate('Warranty of this kit has expired.')}
                    </div>
                  }
                  arrowSize={4}
                  tagName="div"
                  direction="down"
                  className="own-tooltip"
                  forceDirection>
                  <ErrorBoldIcon className="fill-gray medium-svg" />
                </Tooltip>
                {part.part.warranty_end_date}
              </div>
            );
          } else if (
            moment(part.part.warranty_end_date) < moment().add(90, 'days')
          ) {
            return (
              <div className="product-parts-row-with-tooltip">
                <Tooltip
                  content={
                    <div className="product-parts-tooltip-content">
                      {translate('Warranty of this kit is running out.')}
                    </div>
                  }
                  arrowSize={4}
                  tagName="div"
                  direction="down"
                  className="own-tooltip"
                  forceDirection>
                  <InfoBoldIcon className="fill-gray medium-svg" />
                </Tooltip>
                {part.part.warranty_end_date}
              </div>
            );
          } else {
            return (
              <div className="product-parts-tooltip-content">
                {part.part.warranty_end_date}
              </div>
            );
          }
        }
      },
      {
        name: translate('kit elements').toUpperCase(),
        cell: (part) => (
          <div className="padded-left">
            <Link
              className="round-white-staple-hover"
              to={`/spareparts/details/${part.part_index}`}>
              <ExternalIcon className="normal-svg" />
            </Link>
          </div>
        )
      }
    ],
    []
  );
};

export default UseDataColumns;
