import axios from 'axios';
import store from '../store';
import translate from './translate';
import { showError } from '../actions/actions';

export const yieldImageUploading = async (imgArr, actionId, flag = true) => {
  let lastResponse;

  for (let i = 0; i < imgArr.length; i++) {
    const pictureItem = imgArr[i];
    const lastIdx = imgArr.length - 1;

    if (!pictureItem.file) continue;

    const config = {
      headers: {
        Accept: 'application/json', 'Content-Type': 'multipart/form-data'
      },
      params: {
        product_flag: flag || i === lastIdx,
        file_cat: pictureItem?.file_cat
      }
    };

    let formData = new FormData();
    formData.append('iBoilerServiceFileUpload', pictureItem.file);

    try {
      lastResponse = await axios.post('api/product/upload_file/' + actionId, formData, config);

      if (i === lastIdx && lastResponse.status === 200) {
        store.dispatch({
          type: 'showMsg', value: {
            msg: translate('Action added successfull.'), type: 'success'
          }
        });
      }
    } catch (e) {
      showError(`${e.response?.data?.localized_message?.description} ${e.response?.data?.localized_message?.recovery_suggestion}`);
      if (i !== lastIdx) continue;
      else break;
    }
  }

  return lastResponse;
}
