import React from 'react';

const Maintenance = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 1.125V2.25H14.625V5.625C14.625 6.22174 14.388 6.79403 13.966 7.21599C13.544 7.63795 12.9717 7.875 12.375 7.875C11.7783 7.875 11.206 7.63795 10.784 7.21599C10.3621 6.79403 10.125 6.22174 10.125 5.625V2.25H11.25V1.125H9.00001V5.625C9.00138 6.42184 9.28442 7.19253 9.79908 7.80087C10.3138 8.4092 11.0269 8.81601 11.8125 8.94938V12.375C11.8125 13.2701 11.4569 14.1286 10.824 14.7615C10.1911 15.3944 9.33261 15.75 8.43751 15.75C7.5424 15.75 6.68396 15.3944 6.05102 14.7615C5.41808 14.1286 5.06251 13.2701 5.06251 12.375V8.92007C5.59275 8.78316 6.05486 8.45757 6.36222 8.00431C6.66958 7.55106 6.80108 7.00128 6.73207 6.45801C6.66307 5.91474 6.3983 5.41528 5.98739 5.05326C5.57648 4.69124 5.04764 4.49152 4.50001 4.49152C3.95237 4.49152 3.42353 4.69124 3.01262 5.05326C2.60171 5.41528 2.33694 5.91474 2.26794 6.45801C2.19893 7.00128 2.33044 7.55106 2.63779 8.00431C2.94515 8.45757 3.40726 8.78316 3.93751 8.92007V12.375C3.93751 13.5685 4.41161 14.7131 5.25552 15.557C6.09944 16.4009 7.24403 16.875 8.43751 16.875C9.63098 16.875 10.7756 16.4009 11.6195 15.557C12.4634 14.7131 12.9375 13.5685 12.9375 12.375V8.94938C13.7231 8.81601 14.4363 8.4092 14.9509 7.80087C15.4656 7.19253 15.7486 6.42184 15.75 5.625V1.125H13.5ZM3.37501 6.75C3.37501 6.5275 3.44099 6.30999 3.5646 6.12498C3.68822 5.93998 3.86392 5.79578 4.06949 5.71064C4.27505 5.62549 4.50125 5.60321 4.71948 5.64662C4.93771 5.69002 5.13817 5.79717 5.2955 5.9545C5.45283 6.11184 5.55998 6.31229 5.60339 6.53052C5.6468 6.74875 5.62452 6.97495 5.53937 7.18052C5.45422 7.38609 5.31003 7.56179 5.12502 7.6854C4.94002 7.80902 4.72251 7.875 4.50001 7.875C4.20174 7.87466 3.91579 7.75602 3.70489 7.54512C3.49398 7.33421 3.37535 7.04826 3.37501 6.75Z"
        fill="#32343A"
      />
    </svg>
  );
};

export default Maintenance;
