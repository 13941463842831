import React from 'react';
import './svgstyles.scss';

const TrashIcon = () => {
  return (
    <svg
      className="custom-aic-icon-small"
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3957 7.27485C14.4008 6.9081 14.4319 6.54198 14.4908 6.17967C14.5881 5.58185 14.7599 4.99638 15.0011 4.44071C15.3638 3.60483 15.8833 2.83901 16.5236 2.1911C17.1373 1.57013 17.8606 1.05852 18.6518 0.68892C19.3314 0.371305 20.0599 0.159565 20.8038 0.0622518C21.601 -0.041718 22.4157 -0.015413 23.2043 0.140859C23.8985 0.278428 24.5721 0.517755 25.1981 0.848049C25.917 1.22748 26.5716 1.72672 27.1292 2.31821C27.6772 2.90019 28.1305 3.57092 28.4646 4.29744C28.7299 4.87435 28.9195 5.48581 29.0276 6.11153C29.0938 6.49572 29.1287 6.88496 29.1341 7.27485L29.1344 7.29069H37.8973C37.9186 7.29038 37.9398 7.29038 37.9614 7.29069H40.7838C41.877 7.29069 42.7649 8.17856 42.7649 9.27183C42.7649 10.3651 41.877 11.253 40.7838 11.253H39.6036L34.4955 41.3018C34.3335 42.2543 33.5084 42.9511 32.5423 42.9511H10.9875C10.0214 42.9511 9.19628 42.2543 9.0343 41.3018L3.92615 11.253H2.74602C1.65276 11.253 0.764893 10.3651 0.764893 9.27183C0.764893 8.17856 1.65276 7.29069 2.74602 7.29069H5.56842C5.58998 7.29038 5.61122 7.29038 5.63246 7.29069H14.3954L14.3957 7.27485ZM35.5846 11.253H7.94514L12.6602 38.9888H30.8695L35.5846 11.253ZM26.9158 31.4605V18.7813C26.9158 17.688 26.028 16.8001 24.9347 16.8001C23.8414 16.8001 22.9536 17.688 22.9536 18.7813V31.4605C22.9536 32.5538 23.8414 33.4416 24.9347 33.4416C26.028 33.4416 26.9158 32.5538 26.9158 31.4605ZM20.5762 31.4605V18.7813C20.5762 17.688 19.6883 16.8001 18.5951 16.8001C17.5018 16.8001 16.6139 17.688 16.6139 18.7813V31.4605C16.6139 32.5538 17.5018 33.4416 18.5951 33.4416C19.6883 33.4416 20.5762 32.5538 20.5762 31.4605ZM25.1715 7.29069C25.1293 5.4465 23.6189 3.96239 21.7649 3.96239C19.9109 3.96239 18.4005 5.4465 18.3583 7.29069H25.1715Z"
        fill="#32343A"
      />
    </svg>
  );
};

export default TrashIcon;
