import React from 'react';

const ProductInstallation = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.1875 1.125H1.125V6.1875H2.25V2.25H6.1875V1.125Z"
        fill="#32343A"
      />
      <path
        d="M1.125 11.8125V16.875H6.1875V15.75H2.25V11.8125H1.125Z"
        fill="#32343A"
      />
      <path
        d="M16.875 6.1875V1.125H11.8125V2.25H15.75V6.1875H16.875Z"
        fill="#32343A"
      />
      <path
        d="M11.8125 16.875H16.875V11.8125H15.75V15.75H11.8125V16.875Z"
        fill="#32343A"
      />
      <path
        d="M14.3382 5.69816L9.27565 2.88566C9.19014 2.83629 9.09314 2.8103 8.9944 2.8103C8.89566 2.8103 8.79866 2.83629 8.71315 2.88566L3.65065 5.69816C3.56586 5.74843 3.49576 5.8201 3.44739 5.90599C3.39902 5.99188 3.37406 6.08897 3.37503 6.18754V11.8125C3.3752 11.9122 3.40185 12.01 3.45226 12.096C3.50267 12.182 3.57501 12.2531 3.6619 12.3019L8.7244 15.1144C8.80991 15.1638 8.90691 15.1898 9.00565 15.1898C9.10439 15.1898 9.20139 15.1638 9.2869 15.1144L14.3494 12.3019C14.4342 12.2516 14.5043 12.18 14.5527 12.0941C14.601 12.0082 14.626 11.9111 14.625 11.8125V6.18754C14.6249 6.08787 14.5982 5.99003 14.5478 5.90404C14.4974 5.81805 14.425 5.747 14.3382 5.69816ZM9.00003 4.01629L12.9038 6.18754L9.00003 8.35879L5.09628 6.18754L9.00003 4.01629ZM4.50003 7.14379L8.43753 9.33191V13.6688L4.50003 11.4807V7.14379ZM9.56253 13.6688V9.33191L13.5 7.14379V11.4807L9.56253 13.6688Z"
        fill="#32343A"
      />
    </svg>
  );
};

export default ProductInstallation;
