import React, { Component } from 'react';
import './newlogin.scss';
import translate from 'utils/translate';
import ValidationError from '../../components/ValidationError/NewValidationError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import history from '../../history';
import { DebounceInput } from 'react-debounce-input';
import PasswordDifficultyBar from '../../components/PasswordDifficultyBar/PasswordDifficultyBar';
import { showError } from '../../actions/actions';
import axios from 'axios';

class NewSetNewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: undefined,
      title: window.location.pathname.includes('spareparts')
        ? 'Spareparts'
        : 'product_info',
      PasswordError: '',
      ConfirmPasswordError: '',
      Password: '',
      PasswordConfirmation: '',
      isPasswordValid: false
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  validate = () => {
    this.setState({
      PasswordError: '',
      PasswordConfirmationError: ''
    });

    let PasswordError = '';
    let PasswordConfirmationError = '';

    let isValid = true;
    const emptyFieldError = translate('This field cannot be empty. ');
    const passwordsAreEqualeError = translate('Passwords has to be identical.');

    if (!this.state.Password) {
      PasswordError += emptyFieldError;
    }

    if (PasswordError) {
      this.setState({ PasswordError });
      isValid = false;
    }

    if (!this.state.PasswordConfirmation) {
      PasswordConfirmationError += emptyFieldError;
    }

    if (PasswordConfirmationError) {
      this.setState({ PasswordConfirmationError });
      isValid = false;
    }

    const isPasswordsAreEquale =
      this.state.Password === this.state.PasswordConfirmation;

    if (!isPasswordsAreEquale) {
      PasswordConfirmationError += passwordsAreEqualeError;
      PasswordError += passwordsAreEqualeError;
    }

    if (PasswordConfirmationError || PasswordError) {
      this.setState({ PasswordConfirmationError });
      this.setState({ PasswordError });
      isValid = false;
    }

    return isValid;
  };

  handleResetSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const isValid = this.validate();

    if (!this.state.isPasswordValid || !isValid) return;

    const urlArray = this.props.location.pathname.split('/');
    const token = urlArray[urlArray.length - 1];

    const setNewPasswordParams = {
      Password: this.state.Password,
      ConfirmPassword: this.state.PasswordConfirmation
    };
    const formData = new FormData();
    for (let key in setNewPasswordParams) {
      formData.append(key, setNewPasswordParams[key]);
    }

    axios
      .post(`api/auth/reset_password/${token}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        if (response.status === 200) {
          window.location.replace('https://aicon.myaic.eu/');
        } else {
          console.log('error another then 200');
        }
      })
      .catch((error) => showError(error));
  };

  checkIsPasswordValid = (_, suggestion) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        isPasswordValid: suggestion
      };
    });
  };

  render() {
    return (
      <>
        <div className="login-step">
          <div className="login-step-title">
            {translate('Set new password')}
          </div>
          <div className="login-step-login-form">
            <div className="login-welcome-text">
              {translate(
                'If you forgot your password you can set up a new one. Enter your registration email and we will send you link for password change.'
              )}
            </div>
            <form
              onSubmit={(e) => this.handleResetSubmit(e)}
              autoComplete="off"
              noValidate={true}
            >
              <>
                <div className="custom-input">
                  {this.state.PasswordError && (
                    <FontAwesomeIcon
                      icon="exclamation-triangle"
                      size="sm"
                      color="gray"
                      className="warn-input-icon"
                    />
                  )}
                  <DebounceInput
                    type="password"
                    className="inputText"
                    required
                    value={this.state.Password}
                    onChange={this.handleChange}
                    id="Password"
                    debounceTimeout={400}
                  />
                  <FontAwesomeIcon
                    icon="lock"
                    size="sm"
                    color="gray"
                    className="input-icon"
                  />
                  <span className="floating-label">
                    {translate('Password')}
                  </span>
                </div>
                <PasswordDifficultyBar
                  setPasswordValidity={this.checkIsPasswordValid}
                  pass={this.state.Password}
                />
              </>
              <>
                <div className="custom-input">
                  {this.state.PasswordConfirmationError && (
                    <FontAwesomeIcon
                      icon="exclamation-triangle"
                      size="sm"
                      color="gray"
                      className="warn-input-icon"
                    />
                  )}
                  <input
                    type="password"
                    className="inputText"
                    required
                    value={this.state.PasswordConfirmation}
                    onChange={this.handleChange}
                    id="PasswordConfirmation"
                  />
                  <FontAwesomeIcon
                    icon="lock"
                    size="sm"
                    color="gray"
                    className="input-icon"
                  />
                  <span className="floating-label">
                    {translate('Password confirmation')}
                  </span>
                </div>
                {this.state.PasswordConfirmationError && (
                  <div style={{ width: '100%' }}>
                    <ValidationError
                      text={this.state.PasswordConfirmationError}
                    />
                  </div>
                )}
              </>

              <div className="login-welcome-buttons">
                <button className="contact-btn aic-btn" type="submit">
                  {translate('Set new password')}
                </button>
              </div>

              <div className="already-a-member">
                <span
                  className="span-link"
                  onClick={() =>
                    history.push(`/${this.props.match.params.module}/login`)
                  }
                >
                  {translate('Back to log in')}
                </span>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default NewSetNewPassword;
