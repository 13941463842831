import React from 'react';

const WarrantyReactivation = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.7375 4.25837H13.5878V4.59587C13.5878 5.71243 12.6692 6.63156 11.5526 6.63156C10.4355 6.63156 9.51694 5.71243 9.51694 4.59587C9.51694 3.47931 10.4355 2.56018 11.5526 2.56018H12.6686L12.0386 3.184L12.735 3.88768L14.6374 1.98531L12.735 0.0834961L12.0398 0.785496L12.6692 1.41043H11.5526C9.80494 1.41043 8.36719 2.84875 8.36719 4.59587C8.36719 6.343 9.80494 7.78075 11.5526 7.78075C13.2998 7.78075 14.7375 6.343 14.7375 4.59587V4.25837Z"
        fill="#32343A"
      />
      <path
        d="M12.4121 8.72156H11.1043C10.3826 9.74981 9.189 10.4023 7.88062 10.4023C5.72231 10.4023 3.9465 8.6265 3.9465 6.46819C3.9465 4.30988 5.72231 2.53406 7.88062 2.53406C7.992 2.53406 8.10337 2.53856 8.21419 2.54813V1.41806C8.09944 1.41019 7.98469 1.40625 7.86937 1.40625C5.09569 1.40625 2.8125 3.68887 2.8125 6.46312C2.8125 7.90256 3.42731 9.27506 4.50056 10.2341V17.1591L7.87556 14.9091L11.2506 17.1591V10.2448C11.7337 9.81394 12.1275 9.29588 12.4121 8.72156ZM10.1256 15.057L7.87556 13.5568L5.62556 15.057V10.9997C7.03969 11.7101 8.71031 11.7118 10.1256 11.0031V15.057Z"
        fill="#32343A"
      />
    </svg>
  );
};

export default WarrantyReactivation;
