import React, { useState } from 'react';
import './cart.scss';
import translate from '../../utils/translate';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import CloseButton from 'assets/svg/CloseButton.jsx';
import CartBottomSection from './CartBottomSection/CartBottomSection';
import {
  addToCart,
  clearCart,
  closeCartModal,
  openCartModal,
  removeFromCart,
  showError,
  updateCart
} from '../../actions/actions';
import { Spinner } from '../Spinners/Spinners';
import CartProductsList from './CartProductsList/CartProductsList';
import CartOrderResults from './CartOrderResults/CartOrderResults';
import exportDataToXLSX from '../../utils/exportDataToXLSX';

const CartModal = ({
  closeCartModal,
  clearCart,
  removeFromCart,
  isCartModalOpen,
  updateCart,
  cart,
  user
}) => {
  const [comments, setOrderComments] = useState('');
  const [orderResult, setOrderResult] = useState({});
  const [loading, setLoading] = useState(false);

  const isOrderComplete = Object.keys(orderResult).length !== 0;

  const quit = () => {
    closeCartModal();
    setTimeout(() => {
      setOrderResult({});
    }, 1000);
  };

  const handleChange = (event) => {
    setOrderComments(event.target.value);
  };

  const handleClearCart = () => {
    clearCart();
    closeCartModal();
  };

  const exportToXLSX = () => {
    const headers = [
      'Spare part index',
      'Spare part name',
      'Product',
      'Quantity',
      'How Many'
    ];

    const rows = cart.map((sparepart) => [
      sparepart.index,
      sparepart.description,
      sparepart.product_type.full_name + ' ' + sparepart.product_type.model,
      sparepart.count + ' ' + sparepart.unit,
      sparepart.count
    ]);

    exportDataToXLSX({ headers, rows }, 'aicON_request_for_spareparts.xlsx');
  };

  const handleSubmit = () => {
    setLoading(true);

    const requestCart = cart.map((part) => {
      return {
        set_index: part.index,
        count: part.count
      };
    });

    const requestBody = {
      order_items: requestCart,
      comments: comments
    };

    clearCart();

    axios
      .post('api/order/', requestBody)
      .then((response) => {
        setLoading(false);
        setOrderResult(response.data);
      })
      .catch((error) => showError(error));
  };

  return (
    <ReactModal
      closeTimeoutMS={500}
      appElement={document.body}
      isOpen={isCartModalOpen}
      className="new-cart-modal"
      onRequestClose={quit}
      shouldCloseOnOverlayClick={false}
      style={{
        content: {
          position: 'relative'
        }
      }}
    >
      <div className="modal-header">
        <div data-testid="close" className="close-modal-button" onClick={quit}>
          <CloseButton />
        </div>
      </div>

      <div className="cart-body">
        <div className="inner-body">
          <div className="claim">{translate('Your shopping list')}</div>

          {loading ? (
            <Spinner />
          ) : !isOrderComplete ? (
            <>
              <CartProductsList
                cart={cart}
                updateCart={updateCart}
                removeFromCart={removeFromCart}
                onClearCart={handleClearCart}
              />
              <CartBottomSection
                exportToXLSX={exportToXLSX}
                comments={comments}
                onChange={handleChange}
                user={user}
                onSubmit={handleSubmit}
              />
            </>
          ) : (
            <CartOrderResults orderResult={orderResult} />
          )}
        </div>
      </div>
    </ReactModal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      closeCartModal,
      openCartModal,
      addToCart,
      clearCart,
      removeFromCart,
      updateCart
    },
    dispatch
  )
});

const mapStateToProps = (state) => {
  return {
    isCartModalOpen: state.shoppingList.isCartModalOpen,
    lastMsg: state.appState.lastMsg,
    lastChanges: state.appState.lastChanges,
    cart: state.shoppingList.cart,
    user: state.user
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartModal);
