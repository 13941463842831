import React from 'react';
import './sparepartDataCard.scss';

const SparepartDataCard = ({ title, icon, children }) => (
  <div className="data-card--container">
    <div className="in-circle">{icon}</div>
    <div className="data-card--content">
      <div className="title"> {title} </div>
      <div className="data-card--item--container">{children}</div>
    </div>
  </div>
);

export default SparepartDataCard;
