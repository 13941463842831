import React from 'react';

const Issue = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 11.8125C8.83312 11.8125 8.66999 11.862 8.53124 11.9547C8.39248 12.0474 8.28434 12.1792 8.22048 12.3334C8.15662 12.4875 8.13991 12.6572 8.17246 12.8209C8.20502 12.9845 8.28538 13.1349 8.40338 13.2529C8.52138 13.3709 8.67172 13.4512 8.83539 13.4838C8.99906 13.5163 9.16871 13.4996 9.32289 13.4358C9.47706 13.3719 9.60884 13.2638 9.70155 13.125C9.79427 12.9863 9.84375 12.8231 9.84375 12.6562C9.84375 12.4325 9.75486 12.2179 9.59662 12.0596C9.43839 11.9014 9.22378 11.8125 9 11.8125Z"
        fill="#32343A"
      />
      <path
        d="M9.5625 4.5H8.4375V10.125H9.5625V4.5Z"
        fill="#32343A"
      />
      <path
        d="M12.9375 16.3125H5.06252C4.96421 16.3125 4.86761 16.2867 4.78236 16.2378C4.6971 16.1888 4.62617 16.1184 4.57663 16.0334L0.639134 9.28344C0.588946 9.19741 0.5625 9.0996 0.5625 9C0.5625 8.9004 0.588946 8.80259 0.639134 8.71656L4.57663 1.96656C4.62617 1.88164 4.6971 1.81119 4.78236 1.76222C4.86761 1.71326 4.96421 1.6875 5.06252 1.6875H12.9375C13.0358 1.6875 13.1324 1.71326 13.2177 1.76222C13.3029 1.81119 13.3739 1.88164 13.4234 1.96656L17.3609 8.71656C17.4111 8.80259 17.4375 8.9004 17.4375 9C17.4375 9.0996 17.4111 9.19741 17.3609 9.28344L13.4234 16.0334C13.3739 16.1184 13.3029 16.1888 13.2177 16.2378C13.1324 16.2867 13.0358 16.3125 12.9375 16.3125ZM5.38551 15.1875H12.6145L16.2238 9L12.6145 2.8125H5.38551L1.77623 9L5.38551 15.1875Z"
        fill="#32343A"
      />
    </svg>
  );
};

export default Issue;
