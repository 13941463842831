import React, { useCallback, useEffect, useRef, useState } from 'react';
import aicMapStyles from 'utils/googlemapstyles';
import { availableLocationTypes, googleApiUrl } from 'utils/const';
import translate from 'utils/translate';
import GoogleMapReact from 'google-map-react';
import 'react-datepicker/dist/react-datepicker.css';
import './../UniversalActionForm.scss';
import axios from 'axios';
import 'rc-datetime-picker/dist/picker.min.css';
import MapPin from 'assets/svg/mapPin';
import { FieldLabel } from './FieldLabel';
import { showError } from 'actions/actions';

const DEFAULT_CENTER = { lat: 51.2649, lng: 5.72965 };

export const LocationAction = ({ obj, errorMessage, setChosenLocation }) => {
  const mapRef = useRef();
  const [markerCenter, setMarkerCenter] = useState(DEFAULT_CENTER);
  const [inputValue, setInputValue] = useState('');
  const [lastLocations, setLastLocation] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [isShowLocations, setIsShowLocation] = useState(false);

  const getLocationByAddress = (address) => {
    axios
      .get('api/help/geolocation/' + address)
      .then((response) => {
        if (response.data.results.length) {
          const geometry = response.data.results[0].geometry.location;
          setMarkerCenter(geometry);
          mapRef.current.setZoom(15);
          mapRef.current.panTo(geometry);
          setChosenLocation(geometry);
        } else
          showError(translate('No locations found, please verify the address'));
      })
      .catch((err) => showError(err.message));
  };

  const getLastLocations = () => {
    axios
      .get('api/user/last_locations')
      .then((response) => {
        const userLocations = response.data[obj.name];
        setLastLocation(userLocations);
        setFilteredLocations(filterLocations(userLocations, inputValue));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getLastLocations();
  }, []);

  useEffect(() => {
    setFilteredLocations(filterLocations(lastLocations, inputValue));
  }, [inputValue]);

  const showAddress = (lat, lng) => {
    axios
      .get(`api/help/geolocation/${lat}, ${lng}`)
      .then((response) => {
        const newAddress = response.data.results.find(
            address => availableLocationTypes.includes(address.geometry.location_type)
        );
        setInputValue(newAddress?.formatted_address || `${lat.toFixed(3)}, ${lng.toFixed(3)}`)
      })
      .catch((error) => console.log(error.message));
  };

  useEffect(() => {
    const showAddressTimer = setTimeout(() => {
      if (mapRef.current) {
        const lat = mapRef.current.getCenter().lat();
        const lng = mapRef.current.getCenter().lng();
        showAddress(lat, lng);
      }
    }, 400);
    return () => {
      clearTimeout(showAddressTimer);
    };
  }, [markerCenter]);

  const setLocationHandler = (location) => {
    if (location) {
      setInputValue(location);
      getLocationByAddress(location);
    }
    setIsShowLocation(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!!inputValue.trim()) {
      getLocationByAddress(inputValue);
    } else showError(translate('Enter the address or use the map'));
    setIsShowLocation(false);
  };

  const filterLocations = useCallback((locations, filter) => {
    if (!lastLocations) return [];
    return locations.filter((location) => location.includes(filter));
  });

  return (
    <div
      className="input-container"
      key={obj.name}>
      <div className="brand-new-input">
        <div className="brand-new-input-label">
          <FieldLabel obj={obj} />
        </div>

        <form onSubmit={(e) => handleSubmit(e)}>
          <input
            type="text"
            onChange={(e) => setInputValue(e.target.value)}
            value={inputValue}
            className="form-control"
            onFocus={() => setIsShowLocation(true)}
          />
        </form>

        {!!filteredLocations.length && (
          <div className={`last-locations ${isShowLocations ? 'show-location' : '' }`}>
            {filteredLocations.map((item) => {
              return (
                <div
                  key={item}
                  className="location-item"
                  onClick={() => setLocationHandler(item)}>
                  {item}
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className="location-picker-container">
        <GoogleMapReact
          options={{
            styles: aicMapStyles,
            disableDefaultUI: true,
            minZoom: 1,
            maxZoom: 30
          }}
          defaultZoom={1}
          defaultCenter={DEFAULT_CENTER}
          bootstrapURLKeys={{ key: googleApiUrl }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }) => {
            mapRef.current = map;
          }}
          onChange={({ center }) => {
            setMarkerCenter(center);
            setChosenLocation(center);
          }}
        >
        </GoogleMapReact>
        <MapPin color="green"/>
      </div>
      {errorMessage}
    </div>
  );
};
