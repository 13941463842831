export function determineMapMarkerIcon(status) {
  let icon = 'gray';
  if (status.key === 'product_status_warning') {
    icon = 'yellow';
  } else if (status.key === 'product_status_error') {
    icon = 'red';
  } else if (status.key === 'product_status_ok') {
    icon = 'green';
  }
  return icon;
}
