import React from 'react';

const Question = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 0C11.2311 0 8.52431 0.821086 6.22202 2.35943C3.91973 3.89777 2.12532 6.08427 1.06569 8.64243C0.00606596 11.2006 -0.271181 14.0155 0.269012 16.7313C0.809205 19.447 2.14258 21.9416 4.10051 23.8995C6.05845 25.8574 8.55301 27.1908 11.2687 27.731C13.9845 28.2712 16.7994 27.9939 19.3576 26.9343C21.9157 25.8747 24.1022 24.0803 25.6406 21.778C27.1789 19.4757 28 16.7689 28 14C28 10.287 26.525 6.72601 23.8995 4.1005C21.274 1.475 17.713 0 14 0ZM14 26C11.6266 26 9.30655 25.2962 7.33316 23.9776C5.35977 22.6591 3.8217 20.7849 2.91345 18.5922C2.0052 16.3995 1.76756 13.9867 2.23058 11.6589C2.69361 9.33114 3.83649 7.19295 5.51472 5.51472C7.19296 3.83649 9.33115 2.6936 11.6589 2.23058C13.9867 1.76755 16.3995 2.00519 18.5922 2.91345C20.7849 3.8217 22.6591 5.35977 23.9776 7.33316C25.2962 9.30655 26 11.6266 26 14C26 17.1826 24.7357 20.2348 22.4853 22.4853C20.2348 24.7357 17.1826 26 14 26Z"
        fill="#32343A"
      />
      <path
        d="M14 23C14.8284 23 15.5 22.3284 15.5 21.5C15.5 20.6716 14.8284 20 14 20C13.1716 20 12.5 20.6716 12.5 21.5C12.5 22.3284 13.1716 23 14 23Z"
        fill="#32343A"
      />
      <path
        d="M15 6.00001H13.5C12.9087 5.99869 12.3229 6.11419 11.7764 6.33987C11.2298 6.56555 10.7332 6.89696 10.3151 7.31509C9.89696 7.73322 9.56555 8.22982 9.33987 8.77638C9.11419 9.32294 8.99869 9.90869 9.00001 10.5V11H11V10.5C11 9.83697 11.2634 9.20109 11.7322 8.73224C12.2011 8.2634 12.837 8.00001 13.5 8.00001H15C15.6631 8.00001 16.2989 8.2634 16.7678 8.73224C17.2366 9.20109 17.5 9.83697 17.5 10.5C17.5 11.1631 17.2366 11.7989 16.7678 12.2678C16.2989 12.7366 15.6631 13 15 13H13V17.5H15V15C16.1935 15 17.3381 14.5259 18.182 13.682C19.0259 12.8381 19.5 11.6935 19.5 10.5C19.5 9.30654 19.0259 8.16194 18.182 7.31803C17.3381 6.47412 16.1935 6.00001 15 6.00001Z"
        fill="#32343A"
      />
    </svg>
  );
};

export default Question;
