import React from 'react';
import translate from 'utils/translate';
import '../dashboard.scss';
import 'react-virtualized/styles.css';
import InfoBoldIcon from '../../../assets/svg/infobold';
import { v4 as uuidv4 } from 'uuid';

const ExpandableComponent = ({ data }) => {
  return (
    <div>
      <div className="collapsed-details">
        {data.service_history.length > 0 && (
          <div className="product-history">
            <div className="icon-column">
              <InfoBoldIcon className="fill-gray medium-svg" />{' '}
            </div>
            <div className="content-column">
              <div className="title first-line">
                {translate("Kit' service history")}
              </div>
              <table className="history-items">
                <thead>
                  <tr>
                    <th>{translate('date').toUpperCase()}</th>
                    <th>{translate('action').toUpperCase()}</th>
                    <th>{translate('details').toUpperCase()}</th>
                    <th>{translate('technician').toUpperCase()}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.service_history.map((item) => {
                    return (
                      <tr
                        className="history-item-row"
                        key={uuidv4()}>
                        <td className="history-item">{item.date}</td>
                        <td className="history-item">{item.title}</td>
                        <td className="history-item">
                          {translate(item.description)}
                        </td>
                        <td className="history-item">
                          {data.part && data.part.inserted_by}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpandableComponent;
