const initialState = {
  searchInput: ''
};

export const sparepartsSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'typePrompt':
      return {
        searchInput: action.payload
      };

    case 'clearPrompt':
      return {
        searchInput: ''
      };
    default:
      return state;
  }
};
