import React from 'react';
import translate from '../../../utils/translate';

const DateInputFilter = ({ contentFrom, contentTo }) => {
  return (
    <div className="date-block">
      <div className="date-block-row">
        <div className="date-block-input">{contentFrom}</div>
        <div className="date-block-input">{contentTo}</div>
      </div>
      <p className="filter-hint">
        {`${translate('comma separated')} 
                ${translate(
                  'Entering the year is obligatory, the month and day are optional'
                )}`}
      </p>
    </div>
  );
};

export default DateInputFilter;
