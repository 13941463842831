import MobileDetect from 'mobile-detect';
import React, { useEffect, useState } from 'react';
import './mobileOrWebDetector.scss';
import history from '../../history';
import translate from '../../utils/translate';
import { Spinner } from '../Spinners/Spinners';
import { useSelector } from 'react-redux';

const MobileOrWebDetector = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isContentReady, setIsContentReady] = useState(false);
  const href = window.location.href;
  const md = new MobileDetect(window.navigator.userAgent);

  const [QRHash, setQRHash] = useState('');
  const [partNumberIndex, setPartNumberIndex] = useState('');

  const translations = useSelector((state) => state.appState.translations[state.appState.preferences.language]);

  useEffect(() => {
    if (href.includes('id=')) {
      setQRHash(href.substring(href.indexOf('id=') + 3).split('}&')[0]);
    }
    else if (href.includes('?pn=')) {
      setPartNumberIndex(href.substring(href.indexOf('?pn=') + 4));
    }
  }, [href]);

  useEffect(() => {
    if (translations) {
      setIsContentReady(true);
    }
  }, [translations]);

  useEffect(() => {
    if (!isContentReady) return;

    if (md.mobile()) {
      setModalVisible(true);
    } else {
      continueDesktop();
    }
  }, [isContentReady, md]);

  const redirect = (url) => {
    history.push(url);
  };

  const openInApp = () => window.location.href = QRHash
    ? `https://boiler.onelink.me/Tl2s?af_web_dp=https%3A%2F%2Ftracker.aicon.myaic.eu&af_xp=custom&pid=boilerId&deep_link_value=boilerId&af_dp=aicon%3A%2F%2F&af_boilerId=${QRHash}}`
    : `https://spareparts.onelink.me/jOTn?af_xp=custom&pid=sparepart&deep_link_value=spareparts&deep_link_sub1=pn&af_dp=aicon%3A%2F%2F&af_part_number=${partNumberIndex}`;

  const continueDesktop = () => {
    setModalVisible(false);
    setIsContentReady(false);
    if (QRHash) {
      redirectAfterLogin('/product_info/' + QRHash);
      redirect('/product_info/' + QRHash);
    }
    else if (partNumberIndex) {
      redirectAfterLogin('/spareparts/details/' + partNumberIndex);
      redirect('/spareparts/details/' + partNumberIndex);
    }
  };

  const redirectAfterLogin = (redirectRoute) => {
    localStorage.setItem('redirectAfterLogin', redirectRoute);
    history.push(redirectRoute);
  };

  if (!isModalVisible) return null;

  return (
    <div className='detector-modal'>
      {!isContentReady
        ? <Spinner />
        : <>
          <h4>{translate('Open in AICon App?')}</h4>
          <p>{translate('If you don\'t have the app, we\'ll guide you to the store to download it.')}</p>
          <span
            onClick={continueDesktop}
            className='aic-btn aic-btn-outline'
            id='no-button'>
              {translate('No')}
            </span>
          <span
            onClick={openInApp}
            className='aic-btn detector-btn'
            id='yes-button'>
              {translate('Yes')}
            </span>
        </>}
    </div>
  );
};

export default MobileOrWebDetector;
