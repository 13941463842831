import React from 'react';
import './searchResultsTable.scss';
import SearchSparepartsRow from './SearchSparepartsRow/SearchSparepartsRow';
import SearchResultsTableHeader from './SearchResultsHeader/SearchResultsTableHeader';
import SearchResultsTableFooter from './SearchResultsFooter/SearchResultsTableFooter';
import { CSSTransition } from 'react-transition-group';
import { openCartModal, removeFromCart } from 'actions/actions';
import { useDispatch } from 'react-redux';
import { groupBySuitable } from 'utils/groupBySuitable';

const SearchResultsTable = ({ results }) => {
  const dispatch = useDispatch();

  const clearCartHandler = () => {
    if (!results) return;
    results.forEach((sparepart) => dispatch(removeFromCart(sparepart)));
  };

  const showCartHandler = () => dispatch(openCartModal());

  return (
    <CSSTransition
      in={Boolean(results.length)}
      timeout={10}
      classNames="recent-spareparts"
      unmountOnExit>
      <table className="table table-borderless">
        <SearchResultsTableHeader />
        <tbody className="table-row-with-separator">
          {groupBySuitable(results).map((sparepart, sIndex) => {
            return (
              <SearchSparepartsRow
                key={''.concat(sIndex)}
                revision={sparepart.suitable_for}
                sparepart={sparepart}
                isSet={sparepart.is_set}
                suitableFor={sparepart.suitable_for}
              />
            );
          })}
          <SearchResultsTableFooter
            clearCartHandler={clearCartHandler}
            showCartHandler={showCartHandler}
          />
        </tbody>
      </table>
    </CSSTransition>
  );
};

export default SearchResultsTable;
