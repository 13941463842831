import React, { useState } from 'react';
import translate from '../../utils/translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DebounceInput } from 'react-debounce-input';
import PasswordDifficultyBar from '../../components/PasswordDifficultyBar/PasswordDifficultyBar';
import './newlogin.scss';
import axios from 'axios';
import store from '../../store';
import history from '../../history';
import { connect } from 'react-redux';

const ChangePinToPassword = () => {
  const [passwordAndPinData, setPasswordAndPinData] = useState({
    CurrentPassword: '',
    NewPassword: '',
    ConfirmNewPassword: ''
  });

  const [pinEmpty, setPinEmpty] = useState(false);
  const [incorrectPin, setIncorrectPin] = useState(false);
  const [newPasswordsMatch, setNewPasswordsMatch] = useState(true);
  const [newPasswordEmpty, setNewPasswordEmpty] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordAndPinData((prevState) => {
      return {
        ...prevState,
        [name]: value
      };
    });

    setPinEmpty(false);
    setNewPasswordsMatch(true);
    setIncorrectPin(false);
    setNewPasswordEmpty(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!passwordAndPinData.CurrentPassword) {
      setPinEmpty(true);
    }

    if (
      passwordAndPinData.NewPassword !== passwordAndPinData.ConfirmNewPassword
    ) {
      setNewPasswordsMatch(false);
    }

    if (!passwordAndPinData.NewPassword) {
      setNewPasswordEmpty(true);
    }

    const formData = new FormData();
    for (let key in passwordAndPinData) {
      formData.append(key, passwordAndPinData[key]);
    }

    axios
      .post('/api/user/change_password/', formData)
      .then((response) => {
        store.dispatch({ type: 'password_changed' });
        setTimeout(() => history.push('/dashboard'));
      })
      .catch((error) => {
        if (error.response.status) {
          error.response.data.message === 'Wrong password'
            ? setIncorrectPin(true)
            : setIncorrectPin(false);
        }
      });
  };

  return (
    <div>
      <form
        autoComplete="off"
        noValidate={true}
        onSubmit={handleSubmit}>
        <div className="login-step">
          <div className="login-step-title">
            {translate('Set new password')}
          </div>
          <div className="login-step-login-form">
            <div className="login-welcome-text">
              {translate('change_pin_to_password_message')}
            </div>
          </div>
        </div>
        <div
          className="custom-input"
          style={{ marginBottom: '0rem' }}>
          {pinEmpty && (
            <FontAwesomeIcon
              icon="exclamation-triangle"
              size="sm"
              color="gray"
              className="warn-input-icon"
            />
          )}
          <input
            required
            type="password"
            className="inputText"
            value={passwordAndPinData.CurrentPassword}
            onChange={handleChange}
            name="CurrentPassword"
          />
          <FontAwesomeIcon
            icon="lock"
            size="sm"
            color="gray"
            className="input-icon"
          />
          <span className="floating-label">{translate('Current Pin')}</span>
        </div>
        {pinEmpty && (
          <div style={{ color: 'red' }}>
            {translate('Enter current password.')}
          </div>
        )}
        {incorrectPin && (
          <div style={{ color: 'red' }}>
            {translate('Enter correct password.')}
          </div>
        )}

        <div
          className="custom-input"
          style={{ marginBottom: '0rem' }}>
          <DebounceInput
            required
            type="password"
            className="inputText"
            value={passwordAndPinData.NewPassword}
            onChange={handleChange}
            name="NewPassword"
          />
          <FontAwesomeIcon
            icon="lock"
            size="sm"
            color="gray"
            className="input-icon"
          />
          <span className="floating-label">{translate('New Password')}</span>
        </div>
        {!newPasswordsMatch && (
          <div style={{ color: 'red' }}>
            {translate('Passwords has to be identical.')}
          </div>
        )}
        {newPasswordEmpty && (
          <div style={{ color: 'red' }}>
            {translate('This field cannot be empty. ')}
          </div>
        )}
        <PasswordDifficultyBar pass={passwordAndPinData.NewPassword} />

        <div className="custom-input">
          <input
            required
            type="password"
            className="inputText"
            value={passwordAndPinData.ConfirmNewPassword}
            onChange={handleChange}
            name="ConfirmNewPassword"
          />
          <FontAwesomeIcon
            icon="lock"
            size="sm"
            color="gray"
            className="input-icon"
          />
          <span className="floating-label">
            {translate('Confirm New Password')}
          </span>
        </div>

        <div style={{ height: '2rem' }}>
          <div className="login-welcome-buttons">
            <button
              className="contact-btn aic-btn"
              type="submit">
              {translate('Set new password')}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, null)(ChangePinToPassword);
