import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './../dashboard.scss';
import 'react-virtualized/styles.css';
import { getProductWithSpareparts, openCartModal } from 'actions/actions';
import translate from 'utils/translate';
import DataTable from 'react-data-table-component';
import customStyles from './productPartsTableStyles.js';
import ExpandableComponent from './expandableProductPartsRow';
import PartsSearch from './PartsSearch/PartsSearch';
import useDataColumns from '../../../utils/hooks/useDataColumns';

const ProductParts = ({ openCartModal, productWithSpareparts, match }) => {
  const [productParts, setProductParts] = useState([]);
  const [searchPhrase, setSearchPhrase] = useState('');

  useEffect(() => {
    if (productWithSpareparts) {
      setProductParts(
        filterItems(
          productWithSpareparts.parts.map((part, index) => {
            return { ...part, count: 0, tableIndex: index };
          })
        )
      );
    }
  }, [productWithSpareparts, searchPhrase]);

  useEffect(() => {
    if (match.params.serialNumber) {
      getProductWithSpareparts(match.params.serialNumber);
    }
  }, []);

  const handleInputChange = (event) => {
    setSearchPhrase(event.target.value);
  };

  const filterItems = (array) => {
    const phraseUpperCase = searchPhrase.toUpperCase();
    return array.filter((part) => {
      const isPhraseContainsName = part.part?.sparepart?.name
        ?.toUpperCase()
        .includes(phraseUpperCase);
      return (
        part.part_index.includes(phraseUpperCase) ||
        part.included_in?.some((sPart) => {
          return sPart.index.includes(phraseUpperCase);
        }) ||
        isPhraseContainsName
      );
    });
  };

  const clearSearchHandler = () => setSearchPhrase('');

  const columns = useDataColumns();

  if (!productWithSpareparts)
    return (
      <div className="loading-spinner">
        <div
          className="spinner-border text-warning"
          role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );

  return (
    <div className="product-parts-container">
      <PartsSearch
        searchPhrase={searchPhrase}
        handleChange={handleInputChange}
        clearSearchHandler={clearSearchHandler}
      />
      <div className="upper-pane">
        <div className="boiler-name-and-surname bolded">
          {productWithSpareparts.product_type_revision.family}{' '}
          {productWithSpareparts.product_type_revision.subfamily}
        </div>

        <div className="model-and-sn">
          <div className="model">
            {translate('Model')}:{' '}
            <div className="bolded">
              {productWithSpareparts.product_type_revision.model}
            </div>{' '}
          </div>
          <div className="serial-number">
            {translate('Serial number')}:{' '}
            <div className="bolded">{productWithSpareparts.serial_number}</div>
          </div>
        </div>
      </div>

      <div className="right-pane">
        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={productParts}
          fixedHeader={true}
          noHeader={true}
          responsive
          className="product-parts-table"
          expandableRows
          expandOnRowClicked
          expandableRowsComponent={ExpandableComponent}
          expandableRowDisabled={(part) => part.service_history.length === 0}
        />

        <div className="product-parts-table-footer">
          <div
            className="aic-small-btn full-size-btn"
            onClick={openCartModal}>
            {translate('Show your shopping list')}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ openCartModal }, dispatch)
  };
};

const mapStateToProps = (state) => {
  return {
    productWithSpareparts: state.spareparts.productWithSpareparts
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProductParts));
