import store from 'store';

export default function translate(phrase) {
  if (phrase) {
    let translations =
      store.getState().appState.translations[
        store.getState().appState.preferences.language
      ];
    if (translations && translations[phrase]) {
      return translations[phrase];
    } else {
      let uppercased = phrase.charAt(0).toUpperCase() + phrase.slice(1);
      return uppercased.replace('_', ' ');
    }
  } else {
    return '';
  }
}
