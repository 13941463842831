import React from 'react';
import './ResultsBySerialNumber.scss';
import history from '../../../../../history';
import { getProductTypeRevision } from 'actions/actions';
import translate from 'utils/translate';

import noImage from 'assets/img/no-image.jpeg';
import { boilerImages } from 'utils/boilerImages';

const ResultsBySerialNumber = ({ results, receivedImage }) => {
  const { family, model, product_index, subfamily, power, sparepart_groups } =
    results || {};

  const imageName = subfamily ? `${family}_${subfamily}` : family;

  return (
    <div className="results-by-sn-container">
      <div className="product-image-wrapper">
        <img
          className="product-image"
          alt="AIC Boiler"
          src={receivedImage || boilerImages[imageName] || noImage}
        />
      </div>

      <div className="product-description">
        <p className="column-title">Product information: </p>
        <ul>
          <li className="product-info-item">
            <span className="item-title">Family: </span>{' '}
            {/*todo: translations for item titles ?*/}
            <span className="item-info">
              {family} {subfamily}
            </span>
          </li>
          <li className="product-info-item">
            <span className="item-title">Model: </span>
            <span className="item-info">{model}</span>
          </li>
          <li className="product-info-item">
            <span className="item-title">Product index: </span>
            <span className="item-info">{product_index}</span>
          </li>
          <li className="product-info-item">
            <span className="item-title">Power: </span>
            <span className="item-info">{power} KW</span>
          </li>
        </ul>
      </div>

      <div className="groups-list">
        <p className="column-title">Choose group of spare parts</p>
        <ul>
          {
            !!sparepart_groups?.length ? (
              sparepart_groups.map((group) => {
                return (
                  <li
                    className="category-item"
                    key={group.name}
                    onClick={() => {
                      getProductTypeRevision(results, group.name, group);
                      history.push('/spareparts');
                    }}>
                    {group.name}
                  </li>
                );
              })
            ) : (
              <p className="no-groups-text">
                {translate(
                  `No groups available. \n Please search by part name/number`
                )}
              </p>
            ) //todo: add translation
          }
        </ul>
      </div>
    </div>
  );
};

export default ResultsBySerialNumber;
