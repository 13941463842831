import React from 'react';
import translate from '../../../utils/translate';
import CartModalRow from '../CartProductRow/CartModalRow';
import CartWarningModal from '../CartWarningModal/CartWarningModal';

const CartProductsList = ({
  cart,
  updateCart,
  onClearCart,
  removeFromCart
}) => {
  return (
    <div className="cart-table-container">
      <table>
        <thead>
          <tr>
            <th>{translate('Spare part')}</th>
            <th>
              {translate('Suitable for')}/{translate('Used in')}
            </th>
            <th>{translate('How many')}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {cart.map((sparepart) => (
            <CartModalRow
              sparepart={sparepart}
              updateCart={updateCart}
              removeFromCart={removeFromCart}
              key={sparepart.sparepart_id}
            />
          ))}
        </tbody>
      </table>

      <div className="below-cart-table-actions">
        <button
          type="button"
          className="aic-small-btn-outline"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          data-testid="clearCart1">
          {translate('Clear shopping list')}
        </button>
        <CartWarningModal onClearCart={onClearCart} />
      </div>
    </div>
  );
};

export default CartProductsList;
