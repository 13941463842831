import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ValidationError from '../ValidationError/NewValidationError';

const FormInput = ({
  error,
  touch,
  value,
  name,
  handleChange,
  label,
  required = true
}) => {
  return (
    <>
      <div className="custom-input">
        {error && touch && (
          <FontAwesomeIcon
            icon="exclamation-triangle"
            size="sm"
            color="gray"
            className="warn-input-icon"
          />
        )}
        <input
          type="text"
          className="inputText"
          value={value}
          onChange={handleChange}
          name={name}
          id={name}
          data-testid={name}
        />
        <FontAwesomeIcon
          icon="user"
          size="sm"
          color="gray"
          className="input-icon"
        />
        <span className="floating-label">
          {label}
          {required && '*'}
        </span>
      </div>
      {error && touch && (
        <div style={{ width: '100%' }}>
          <ValidationError text={error} />
        </div>
      )}
    </>
  );
};

export default FormInput;
