import React from 'react';

const Registration = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 18L12.41 19.41L15 16.83V29H17V16.83L19.59 19.41L21 18L16 13L11 18Z"
        fill="#32343A"
      />
      <path
        d="M23.5001 22.0001H23.0001V20.0001H23.5001C24.6936 20.0478 25.8571 19.6195 26.7348 18.8093C27.6125 17.9992 28.1324 16.8735 28.1801 15.6801C28.2278 14.4866 27.7995 13.323 26.9894 12.4454C26.1792 11.5677 25.0536 11.0478 23.8601 11.0001H23.0001L22.9001 10.1801C22.6782 8.49651 21.852 6.951 20.5752 5.83144C19.2984 4.71188 17.6582 4.09461 15.9601 4.09461C14.262 4.09461 12.6218 4.71188 11.345 5.83144C10.0682 6.951 9.24196 8.49651 9.0201 10.1801L9.0001 11.0001H8.1401C6.94663 11.0478 5.821 11.5677 5.01084 12.4454C4.20069 13.323 3.77236 14.4866 3.8201 15.6801C3.86784 16.8735 4.38773 17.9992 5.2654 18.8093C6.14307 19.6195 7.30663 20.0478 8.5001 20.0001H9.0001V22.0001H8.5001C6.89651 21.9899 5.35327 21.3873 4.16709 20.3082C2.98091 19.229 2.23552 17.7494 2.07423 16.1539C1.91295 14.5584 2.34715 12.9596 3.29335 11.6649C4.23956 10.3702 5.63098 9.47094 7.2001 9.14006C7.63181 7.12658 8.74094 5.32203 10.3424 4.02753C11.9439 2.73302 13.9409 2.02686 16.0001 2.02686C18.0593 2.02686 20.0563 2.73302 21.6578 4.02753C23.2593 5.32203 24.3684 7.12658 24.8001 9.14006C26.3692 9.47094 27.7606 10.3702 28.7069 11.6649C29.6531 12.9596 30.0873 14.5584 29.926 16.1539C29.7647 17.7494 29.0193 19.229 27.8331 20.3082C26.6469 21.3873 25.1037 21.9899 23.5001 22.0001Z"
        fill="#32343A"
      />
    </svg>
  );
};

export default Registration;
