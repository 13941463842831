import store from 'store';

export function b64encode(phrase) {
  return btoa(unescape(encodeURIComponent(phrase)));
}

export function extractBasePathFromUrl(url) {
  return url.substring(0, url.substring(1).indexOf('/') + 1 || undefined);
}

export function user_has_one_of_roles(roles = []) {
  if (!Array.isArray(roles)) {
    roles = [roles];
  }
  let user = store.getState().user;
  if (!user) {
    return false;
  }
  for (let i = 0; i < roles.length; i++) {
    if (user.roles.some((x) => x === roles[i])) return true;
  }
  return false;
}
