import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SparepartPage from './SparePartPage/SparepartPage';

const SparepartDetails = (props) => (
  <Switch>
    <Route
      path={`${props.match.url}/:sparepartId`}
      component={SparepartPage}
    />
  </Switch>
);

export default SparepartDetails;
