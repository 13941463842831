import React, { useState } from 'react';
import './cookies.scss';
import translate from '../../utils/translate';
import ReactModal from 'react-modal';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import store from '../../store';
import { getLegalDocuments } from '../../actions/actions';

const CookieDialog = ({ legalDocuments, openCookiesModal }) => {
  let initialCookies = {
    necessary: true,
    authentication: false,
    preferential: false
  };

  let permissionsFromCookies = Cookies.get('cookiePermissions');
  if (permissionsFromCookies) {
    initialCookies = JSON.parse(permissionsFromCookies);
  }

  const [cookiesPermissions, setCookiesPermission] = useState(initialCookies);
  const [openMoreCookieInfo, toggleMoreCookieInfo] = useState(false);

  const setChoice = (key) => {
    let copyOfPermissions = Object.assign({}, cookiesPermissions);
    copyOfPermissions[key] = !copyOfPermissions[key];
    setCookiesPermission(copyOfPermissions);
  };

  const saveCookies = () => {
    Cookies.set('cookiePermissions', JSON.stringify(cookiesPermissions));
  };

  const saveCookiesAndQuit = () => {
    saveCookies();
    quit();
  };

  const quit = () => {
    store.dispatch({ type: 'closeCookiesModal' });
  };

  const selectAll = () => {
    Object.keys(cookiesPermissions).forEach((key) => {
      cookiesPermissions[key] = true;
    });
    saveCookiesAndQuit();
  };

  if (!legalDocuments.privacy_policy && openCookiesModal) {
    getLegalDocuments();
  }

  return (
    <ReactModal
      closeTimeoutMS={500}
      appElement={document.body}
      isOpen={openCookiesModal}
      className="cookies-modal"
      overlayClassName="cookies-modal-overlay"
      onRequestClose={quit}
      shouldCloseOnOverlayClick={false}
      style={{
        content: {
          position: 'relative'
        }
      }}>
      <div>
        <div>
          <h3 className="orange">{translate('Cookie settings')} </h3>
          <span>
            {translate('cookies_modal_info')}
            <a
              className="document-link span-link link"
              href={
                legalDocuments.privacy_policy &&
                legalDocuments.privacy_policy.url
              }
              rel="noopener noreferrer"
              target="_blank">
              {translate('Privacy Policy')}
            </a>
          </span>
        </div>

        <div className="cookies-checkboxes">
          <div>
            <label className="customcheck">
              {translate('Necessary')}
              <input
                type="checkbox"
                checked="checked"
                disabled
              />
              <span className="checkmark"> </span>
            </label>
          </div>
          <div>
            <label className="customcheck">
              {translate('Authentication')}
              <input
                type="checkbox"
                checked={cookiesPermissions['authentication']}
                onClick={() => setChoice('authentication')}
                readOnly
              />
              <span className="checkmark"> </span>
            </label>
          </div>
          <div>
            <label className="customcheck">
              {translate('Preferential')}
              <input
                type="checkbox"
                checked={cookiesPermissions['preferential']}
                onClick={() => setChoice('preferential')}
                readOnly
              />
              <span className="checkmark"> </span>
            </label>
          </div>
        </div>

        <div
          className="link"
          onClick={() => toggleMoreCookieInfo(!openMoreCookieInfo)}>
          {!openMoreCookieInfo && (
            <span className="showDetails">{translate('Show details')}</span>
          )}
          {openMoreCookieInfo && (
            <span className="showDetails">{translate('Hide details')}</span>
          )}
        </div>
        {openMoreCookieInfo && (
          <div>
            <div>
              <h4 className="orange">{translate('Necessary')}</h4>
              <div>{translate('cookies_necessary')} </div>
            </div>
            <div>
              <h4 className="orange">{translate('Authentication')}</h4>
              <div>{translate('cookies_authentication')}</div>
            </div>
            <div>
              <h4 className="orange">{translate('Preferential')}</h4>
              <div>{translate('cookies_preferential')}</div>
            </div>
          </div>
        )}

        <div className="cookies-buttons">
          <button
            className="aic-btn-outline"
            onClick={saveCookiesAndQuit}>
            {translate('Accept choices')}
          </button>
          <button
            className="aic-btn"
            onClick={selectAll}>
            {translate('Choose all')}
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

const mapStateToProps = (state) => {
  return {
    legalDocuments: state.appState.legalDocuments,
    openCookiesModal: state.appState.openCookiesModal,
    lastMsg: state.appState.lastMsg
  };
};

export default connect(mapStateToProps, null)(CookieDialog);
