import React, { useEffect, useState } from 'react';
import Tooltip from 'react-tooltip-lite';
import translate from 'utils/translate';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const DashboardProductRow = ({
  product,
  clearedProductsList,
  handleSelectProduct,
  selectedAllProducts,
  selectedProduct,
  selectProduct
}) => {
  const {
    serial_number,
    product_type,
    location,
    warranty_info: {
      warranty_start_date,
      warranty_end_date,
      next_inspection_date
    },
    date_installed,
    date_launched,
    service_support,
    last_action,
    supporting_department_code
  } = product;

  const dashboardColumns = useSelector(
    (state) => state.dashboard.dashboardColumns
  );

  const [checked, setChecked] = useState(false);

  const handleSelectProductCheckbox = (e) => {
    setChecked(e.target.checked);
    handleSelectProduct(product, e.target.checked);
  };

  useEffect(() => {
    if (clearedProductsList) {
      setChecked(false);
    }
    if (selectedAllProducts) {
      setChecked(true);
    }
  }, [clearedProductsList, selectedAllProducts]);

  return (
    <div
      id={'user-product' + serial_number.split('.').join('')}
      className={selectedProduct === product ? 'selected-row tr' : 'tr'}
      onClick={() => selectProduct(product)}
    >
      {/* checkbox*/}
      {dashboardColumns[0].isVisible && (
        <div className={`td ${dashboardColumns[0].className}`}>
          <label className="customcheck chekbox-cell-center">
            <input
              type="checkbox"
              checked={checked}
              onChange={(e) => handleSelectProductCheckbox(e)}
            />
            <span className="checkmark"> </span>
          </label>
        </div>
      )}
      {/* '' => status  */}
      {dashboardColumns[1].isVisible && (
        <div className={`td ${dashboardColumns[1].className}`}>
          <div className="dot" style={{ background: product.status.color }} />
        </div>
      )}

      {/* model => family  */}
      {dashboardColumns[2].isVisible && (
        <div className={`td ${dashboardColumns[2].className}`}>
          {product_type.family} {product_type.subfamily}
        </div>
      )}

      {/* power  */}
      {dashboardColumns[3].isVisible && (
        <div className={`td ${dashboardColumns[3].className}`}>
          {product_type.value} {product_type.unit || ''}
        </div>
      )}

      {/* serial number */}
      {dashboardColumns[4].isVisible && (
        <div className={`td ${dashboardColumns[4].className}`}>
          <Tooltip
            content={
              <div className="dashboard-tooltip">
                <div className="dashboard-tooltip-content">
                  {translate('Go to product history')}
                </div>
              </div>
            }
            tagName="div"
            mouseOutDelay={200}
            arrowSize={8}
          >
            <Link to={'product_info/' + serial_number} className="aic-link">
              {serial_number}
            </Link>
          </Tooltip>
        </div>
      )}

      {/* index  */}
      {dashboardColumns[5].isVisible && (
        <div className={`td ${dashboardColumns[5].className}`}>
          {product_type.product_index}
        </div>
      )}

      {/* location  */}
      {dashboardColumns[6].isVisible && (
        <div className={`td ${dashboardColumns[6].className}`}>
          <Tooltip
            content={
              <div className="dashboard-tooltip">
                <div className="dashboard-tooltip-content">{location}</div>
              </div>
            }
            tagName="div"
            mouseOutDelay={200}
            arrowSize={8}
            className="text-elipsis"
          >
            <div>
              <p
                style={{
                  marginBottom: '0',
                  fontSize: '0.6rem'
                }}
              >
                {location && location.split(':')[0]}
              </p>
              <p className="text-elipsis mb-0">
                {location && location.split(':')[1]}
              </p>
            </div>
          </Tooltip>
        </div>
      )}

      {/* wty start => date warianty start */}
      {dashboardColumns[7].isVisible && (
        <div className={`td ${dashboardColumns[7].className}`}>
          {warranty_start_date}
        </div>
      )}

      {/* wty end => date warianty end */}
      {dashboardColumns[8].isVisible && (
        <div className={`td ${dashboardColumns[8].className}`}>
          {warranty_end_date}
        </div>
      )}

      {/* installation => date instalation */}
      {dashboardColumns[9].isVisible && (
        <div className={`td ${dashboardColumns[9].className}`}>
          {date_installed && date_installed.slice(0, 10)}
        </div>
      )}

      {/* launch => date */}
      {dashboardColumns[10].isVisible && (
        <div className={`td ${dashboardColumns[10].className}`}>
          {date_launched && date_launched.slice(0, 10)}
        </div>
      )}

      {/* next inspection => date next inspection */}
      {dashboardColumns[11].isVisible && (
        <div className={`td ${dashboardColumns[11].className}`}>
          {next_inspection_date}
        </div>
      )}

      {/* technician => name + surname */}
      {dashboardColumns[12].isVisible && (
        <div className={`td ${dashboardColumns[12].className}`}>
          {service_support && (
            <Tooltip
              content={
                <div className="dashboard-tooltip two-sections">
                  <div className="left-section">
                    <div className="in-circle">
                      {service_support &&
                        `${service_support.name[0]}${
                          service_support.name.split(' ')[1]
                            ? service_support.name.split(' ')[1][0]
                            : ''
                        }`}
                    </div>
                  </div>
                  <div className="right-section">
                    <div className="tooltip-title">
                      {`${service_support.name}`}
                    </div>
                    <div className="dashboard-tooltip-content">
                      {service_support.email}
                    </div>
                    <div className="dashboard-tooltip-content">
                      {service_support.phone_number}
                    </div>
                  </div>
                </div>
              }
              tagName="div"
              mouseOutDelay={200}
              arrowSize={8}
            >
              <div className="in-circle">
                {service_support &&
                  `${service_support.name[0]}${
                    service_support.name.split(' ')[1]
                      ? service_support.name.split(' ')[1][0]
                      : ''
                  }`}
              </div>
            </Tooltip>
          )}
        </div>
      )}

      {/* last action =>date last action */}
      {dashboardColumns[13].isVisible && (
        <div className={`td ${dashboardColumns[13].className}`}>
          {last_action && (
            <div className="last-action-cell-double">
              <div className="last-action-cell-text">
                {last_action.action_date &&
                  last_action.action_date.slice(0, 10)}
              </div>
              {last_action.username &&
                last_action.username.first_name &&
                last_action.username.last_name && (
                  <Tooltip
                    content={
                      <div className="dashboard-tooltip">
                        <div className="tooltip-title">
                          {translate(last_action.displayed_name)}
                        </div>
                        <div className="dashboard-tooltip-content">
                          by {last_action.username.first_name}{' '}
                          {last_action.username.last_name}
                        </div>
                      </div>
                    }
                    tagName="div"
                    mouseOutDelay={200}
                    arrowSize={8}
                  >
                    <div className="in-circle">
                      {last_action &&
                        last_action.username.first_name[0].toUpperCase() +
                          last_action.username.last_name[0].toUpperCase()}
                    </div>
                  </Tooltip>
                )}
            </div>
          )}
        </div>
      )}

      {/* dept => departament */}
      {dashboardColumns[14].isVisible && (
        <div className={`td ${dashboardColumns[14].className}`}>
          {supporting_department_code}
        </div>
      )}
    </div>
  );
};

export default DashboardProductRow;
