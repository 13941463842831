import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Link from '../../../../../assets/svg/link';
import translate from '../../../../../utils/translate';

const PartsOverview = () => {
  const history = useHistory();

  const [boilerId, setBoilerId] = useState();

  useEffect(() => {
    const boilerId = history.location.pathname.split('/')[2];
    setBoilerId(boilerId);
  }, []);

  const onPartsOverviewClickHandler = () => {
    history.push(`/dashboard/${boilerId}/parts`);
  };

  return (
    <>
      <div
        className="product-info-card-header"
        onClick={onPartsOverviewClickHandler}
        style={{ cursor: 'pointer' }}>
        <p>{translate('Parts Overview')}</p>
        <Link />
      </div>
    </>
  );
};

export default PartsOverview;
