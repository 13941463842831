import React from 'react';
import './searchResultsTitle.scss';
import InputSearch from 'assets/svg/inputSearch';
import { InputSpinner } from 'components/Spinners/Spinners';
import translate from 'utils/translate';

const SearchResultsTitle = ({
  searchPhrase,
  showSearchButton,
  isResultsLoaded,
  onScrollButtonClick
}) => {
  return (
    <div className="table-title">
      {translate('Results for')} {`"${searchPhrase}"`}
      {showSearchButton &&
        (isResultsLoaded ? (
          <div
            className="spareparts-scroll-button"
            onClick={() => onScrollButtonClick()}>
            <InputSearch />
          </div>
        ) : (
            <InputSpinner />
        ))}
    </div>
  );
};

export default SearchResultsTitle;
