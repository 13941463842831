import React from 'react';
import './../UniversalActionForm.scss';
import { FieldLabel } from './FieldLabel';
import { LastActionInfo } from '../LastActionInfo';

export const ActionInput = (props) => {
  const { obj, onChangeInput, inputValue, errorMessage } = props;
  return (
    <div
      className="input-container"
      key={obj.name}>
      <div className="brand-new-input">
        <div className="brand-new-input-label">
          <FieldLabel obj={obj} />
        </div>
        <input
          key={obj.name}
          autoCapitalize="none"
          autoCorrect="false"
          onChange={(e) => onChangeInput(e)}
          value={inputValue}
        />
      </div>
      <LastActionInfo last_value={obj.last_value} />
      {errorMessage}
    </div>
  );
};
