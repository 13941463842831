import React, { Component } from 'react';
import translate from 'utils/translate';
import './spareparts.scss';
import {
  choosePartFromSvg, getProductTypeRevision, openCartModal, showError
} from '../../../../actions/actions';
import { withRouter } from 'react-router-dom';
import SparepartsWelcomePage from '../SparepartsWelcomePage';
import { ReactSvgPanZoomLoader, SvgLoaderSelectElement } from 'react-svg-pan-zoom-loader';
import { AutoSizer } from 'react-virtualized';
import ViewerCanvas from './ViewerCanvas/ViewerCanvas';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SparepartsRow from './SparePartsRow/SparepartsRow';
import {
  HideProductHistoryCardSVG
} from '../../../../assets/svg/productActions/hideProductHistory';
import axios from 'axios';
import { Spinner } from '../../../../components/Spinners/Spinners';

class Spareparts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPart: undefined,
      loading: false
    };
    this.child = React.createRef();
    this.childRow = React.createRef();
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const paramGroup = params.get('groupName');
    const paramModel = params.get('model');

    if (paramGroup && paramModel) {
      this.setState({ loading: true })
      this.getSparepartInfo(paramModel, paramGroup);
    }
  }

  async getSparepartInfo(modelJson, groupJson) {
    const groupItem = JSON.parse(groupJson);
    const model = JSON.parse(modelJson);

    try {
      const res = await axios
        .get(`/api/product_type_revision/${model.id}/sparepart_group/${groupItem.name}`);

        const currGroup = Object.assign(groupItem, {
          spareparts: res.data.spareparts
        });

        getProductTypeRevision(model, currGroup.name, currGroup);
    } catch (e) {
      showError(e)
    }

    this.setState({ loading: false })
  }

  selectSparepartFile(file) {
    this.setState({ selectedSparepartFile: file });
  }

  targetPart(selector) {
    return document.querySelector(selector);
  }

  hoverPart(part) {
    part.classList.add('selected-svg-part');
    let coordinates = part.attributes;

    const x = parseFloat(coordinates?.cx.value);
    const y = parseFloat(coordinates?.cy.value);

    this.child.current.centerOnMap(x, y, 1);
  }

  highlightSparepartOnTable() {
    let activeTablePart = document.querySelector('.active-sparepart-on-table');
    if (activeTablePart) {
      activeTablePart.classList.remove('active-sparepart-on-table');
    }
  }

  scrollToSparepart(sparepart) {
    setTimeout(() => {
      this.highlightSparepartOnTable(sparepart);
      let container = document.querySelector('.spareparts-table-container');
      let scrollTo = document.querySelector('#tablepart' + sparepart.index);
      if (scrollTo) {
        container.scrollTop = scrollTo.offsetTop;
      }
    }, 300);
  }

  selectArrowFromTable(part) {
    let allSelectionsArrows = document.querySelectorAll(
      '.selected-at-tree-arrow'
    );
    if (allSelectionsArrows) {
      for (let i = 0; i < allSelectionsArrows.length; i++) {
        allSelectionsArrows[i].classList.remove('selected-at-tree-arrow');
      }
    }

    allSelectionsArrows = document.querySelectorAll(
      '#gsp' + part.index + ' .arrow, #gsp' + part.index + ' .extraarrow'
    );

    if (allSelectionsArrows) {
      for (let j = 0; j < allSelectionsArrows.length; j++) {
        allSelectionsArrows[j].classList.add('selected-at-tree-arrow');
      }
    }
  }

  selectSparepartFromTable(part, centerOnSvg) {
    let selectedPart = this.targetPart('#gsp' + part.index + ' .circle');
    this.selectArrowFromTable(part);
    this.highlightSparepartOnTable(part);
    let previousSelectedPart = this.targetPart('.selected-svg-part');
    if (previousSelectedPart) {
      previousSelectedPart.classList.remove('selected-svg-part');
    }
    if (centerOnSvg && selectedPart) this.hoverPart(selectedPart);

    let selectedSparepartsGroup = Object.assign(
      {},
      this.state.selectedSparepartsGroup
    );
    if (selectedSparepartsGroup.spareparts) {
      selectedSparepartsGroup.spareparts =
        selectedSparepartsGroup.spareparts.map((item) => {
          if (item.index === part.index)
            item.toggleDetails = !item.toggleDetails;
          return item;
        });
    }
    this.setState({
      selectedPart: part,
      selectedSparepartsGroup: selectedSparepartsGroup
    });
  }

  selectPartFromSvg(part) {
    this.scrollToSparepart(part);
    choosePartFromSvg(part);
    this.selectSparepartFromTable(part, false);
  }

  filterTableItem(mainpart) {
    return !mainpart.index;
  }

  componentDidUpdate(prevProps, prevState) {
    // assign count from cart to state spareparts or st to 0
    let temporarySelectedSparepartsGroup = Object.assign(
      {},
      this.props.currentGroup
    );

    if (
      prevProps.selectedSparepartsGroup !==
        this.props.selectedSparepartsGroup ||
      prevProps.cart !== this.props.cart
    ) {
      this.setState({
        enableTooltip: false,
        selectedSparepartsGroup: temporarySelectedSparepartsGroup
      });
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="spareparts-root spareparts-root-loading">
          <Spinner/>
        </div>
      )
    }

    if (!this.props.selectedSparepartsGroup) {
      return <SparepartsWelcomePage />;
    }
    return (
      <div className="spareparts-root">
        <div
          className="svg-and-table-container-right"
          style={
            this.props.selectedSparepartsGroup.url && {
              opacity: '1'
            }
          }
        >
          {this.props.selectedSparepartsGroup && (
            <div className="above-table">
              <div className="breadcrumb">
                <div className="breadcrumb-title">
                  <p>{translate('Parts list')}</p>
                </div>
                <div className="breadcrumb-data data-title">
                  <div style={{ paddingRight: '22px' }}>
                    {this.props.selectedSparepartsGroup &&
                      this.props.selectedSparepartsGroup.family}{' '}
                    {this.props.selectedSparepartsGroup.subfamily &&
                      this.props.selectedSparepartsGroup.subfamily}
                  </div>
                  <div style={{ paddingRight: '22px' }}>
                    {this.props.selectedSparepartsGroup &&
                      `${this.props.selectedSparepartsGroup.value} ${this.props.selectedSparepartsGroup.unit || ''}`}
                  </div>
                  <div>
                    {this.props.currentGroup && this.props.currentGroup.name}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="spareparts-table-container">
            {this.props.currentGroup.spareparts && (
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th>{translate('No.')}</th>
                    <th>{translate('Spare part')}</th>
                    <th>{translate('index')}</th>
                    <th>{translate('Add to shopping list')}</th>
                  </tr>
                </thead>
                <tbody className="table-row-with-separator">
                  {this.props.currentGroup.spareparts.map(
                    (sparepart, index) => {
                      if (sparepart.is_set) {
                        return (
                          <SparepartsRow
                            sparepart={sparepart}
                            key={index}
                            SparepartsSelectedPart={
                              this.props.sparepartsSelectedPart
                            }
                            selectSparepartFromTable={() =>
                              this.selectSparepartFromTable(sparepart, true)
                            }
                            revision={this.props.selectedSparepartsGroup}
                          />
                        );
                      }
                    }
                  )}
                </tbody>
              </table>
            )}
          </div>
          <div className="spareparts-table-footer">
            <div className="spareparts-table-footer-btns">
              <div
                className="aic-small-btn"
                onClick={this.props.openCartModal.bind(this)}
              >
                {translate('Show your shopping list')}
              </div>
            </div>
          </div>
        </div>
        {this.props.currentGroup.sparepart_file ? (
          <div
            className="svg-and-table-container-left"
            style={
              this.props.currentGroup.sparepart_file.url && {
                opacity: '1'
              }
            }
          >
            <div className="svg-container">
              {this.props.currentGroup.sparepart_file.url && (
                <AutoSizer>
                  {({ width, height }) =>
                    width === 0 || height === 0 ? null : (
                      <ReactSvgPanZoomLoader
                        src={this.props.currentGroup.sparepart_file.url}
                        proxy={
                          <>
                            {this.props.currentGroup.spareparts &&
                              this.props.currentGroup.spareparts.map(
                                (sparepart, index) => {
                                  let spid = '#gsp' + sparepart.index;
                                  return (
                                    <SvgLoaderSelectElement
                                      key={index}
                                      selector={spid}
                                      onClick={() => {
                                        this.selectPartFromSvg(sparepart);
                                      }}
                                    />
                                  );
                                }
                              )}
                          </>
                        }
                        render={(content) => {
                          return (
                            <ViewerCanvas
                              svgcontent={content}
                              group={this.props.currentGroup}
                              svgWidth={
                                this.props.currentGroup.sparepart_file.width
                              }
                              svgHeight={
                                this.props.currentGroup.sparepart_file.height
                              }
                              width={width}
                              height={height}
                              ref={this.child}
                            />
                          );
                        }}
                      />
                    )
                  }
                </AutoSizer>
              )}
            </div>
          </div>
        ) : (
          <div className="svg-not-available">
            <h1>{translate('No preview available')} :(</h1>
            <div>
              <HideProductHistoryCardSVG />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openCartModal
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    lastMsg: state.appState.lastMsg,
    cart: state.shoppingList.cart,
    user: state.user,
    enableTooltip: state.dashboard.enableTooltip,
    groupedProductTypes: state.spareparts.groupedProductTypes,
    selectedSparepartsGroup: state.spareparts.selectedSparepartsGroup,
    currentGroup: state.spareparts.currentGroup,
    sparepartsSelectedPart: state.spareparts.sparepartsSelectedPart
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Spareparts));
