import React from 'react';
import translate from '../../utils/translate';
import Info from '../../assets/svg/info';
import { Link } from 'react-router-dom';

const CookiesData = ({
  legalDocuments,
  showCookiesDetails,
  cookiesDetailsIsOpen,
  cookiesPermissions,
  setChoice
}) => {
  const { privacy_policy, terms_and_conditions } = legalDocuments;
  return (
    <div className="preferences-section tos-section">
      <h3 className="preferences-section-header">
        {translate('Cookie settings')}{' '}
      </h3>
      <span className="px-2">
        {translate('I have read the')}
        <Link
          className="document-link span-link"
          to="/dashboard/privacy_policy"
          rel="noopener noreferrer"
          target="_blank"
        >
          {' '}
          {translate('Privacy Policy')}{' '}
        </Link>
        {translate('and agree to the')}
        <a
          className="document-link span-link"
          href={terms_and_conditions && terms_and_conditions.url}
          rel="noopener noreferrer"
          target="_blank"
        >
          {' '}
          {translate('Terms and conditions')}{' '}
        </a>
      </span>
      <div className="document-link-container px-2">
        <div className="d-flex" onClick={showCookiesDetails}>
          <p>
            <i
              className={`arrow ${!cookiesDetailsIsOpen && 'arrow-rotate'}`}
            ></i>
          </p>
          <span className="document-link span-link padding-left0">
            {translate('Cookies details')}
          </span>
        </div>
        <div>
          {cookiesDetailsIsOpen && (
            <div className={`cookies-settings-container`}>
              <div>
                {translate('cookies_modal_info')}
                <Link
                  className="document-link span-link"
                  to="/dashboard/privacy_policy"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {' '}
                  {translate('Privacy Policy')}
                </Link>
                <p className="cookies-settings-container-p">
                  {translate('I accept following cookies:')}
                </p>
              </div>

              <div className="cookies-checkboxes-settings">
                <div className="w-100">
                  <label className="customcheck cookies-customcheck">
                    <span className="cookies-checkboxes-item-desc">
                      {translate('Necessary')}
                      <span className="cookies-checkboxes-inf-svg">
                        <Info />
                        <span className="preferences-toolTipText">
                          <p className="preferences-toolTipText-title">
                            {translate('Necessary')}
                          </p>
                          {translate('cookies_necessary')}
                          <div className="preferences-item-square"></div>
                        </span>
                      </span>
                    </span>
                    <input type="checkbox" checked="checked" disabled />
                    <span className="checkmark"> </span>
                  </label>
                </div>
                <div className="w-100">
                  <label className="customcheck cookies-customcheck">
                    <span className="cookies-checkboxes-item-desc">
                      {translate('Authentication')}
                      <span className="cookies-checkboxes-inf-svg">
                        <Info />
                        <span className="preferences-toolTipText">
                          <p className="preferences-toolTipText-title">
                            {translate('Authentication')}
                          </p>
                          {translate('cookies_authentication')}
                          <div className="preferences-item-square"></div>
                        </span>
                      </span>
                    </span>
                    <input
                      type="checkbox"
                      checked={cookiesPermissions['authentication']}
                      onClick={() => setChoice('authentication')}
                      readOnly
                    />
                    <span className="checkmark"> </span>
                  </label>
                </div>
                <div className="w-100">
                  <label className="customcheck cookies-customcheck">
                    <span className="cookies-checkboxes-item-desc">
                      {translate('Preferential')}
                      <span className="cookies-checkboxes-inf-svg">
                        <Info />
                        <span className="preferences-toolTipText">
                          <p className="preferences-toolTipText-title">
                            {translate('Preferential')}
                          </p>
                          {translate('cookies_preferential')}
                          <div className="preferences-item-square"></div>
                        </span>
                      </span>
                    </span>
                    <input
                      type="checkbox"
                      checked={cookiesPermissions['preferential']}
                      onClick={() => setChoice('preferential')}
                      readOnly
                    />
                    <span className="checkmark"> </span>
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CookiesData;
