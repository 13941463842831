import React from 'react';
import './Spinners.scss';

export const Spinner = () => {
  return (
    <div className="d-flex justify-content-center w-100 mt-5">
      <div
        className="spinner-border"
        role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export const Spinner2 = () => {
  return (
    <div
      className="spinner-border"
      role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export const HistorySpiner = () => {
  return (
    <div
      className="d-flex justify-content-center"
      style={{ width: '35vw' }}>
      <div
        className="spinner-border"
        role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export const InputSpinner = () => {
  return (
    <div className="input-spinner">
      <div
        className="spinner-border spinner-border-sm text-secondary"
        role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};
