import React from 'react';
import baseAIClogo from 'assets/svg/baseAIClogo.png';
import { ReactComponent as NavShop } from 'assets/svg/nav_shop.svg';
import { ReactComponent as NavUser } from 'assets/svg/nav_user.svg';
import { ReactComponent as NavLogout } from 'assets/svg/nav_logout.svg';
import './navbar.scss';
import { NavLink, withRouter } from 'react-router-dom';
import {
  getProductTypeRevision, openCartModal, togglePreferencesModal
} from '../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import translate from 'utils/translate';
import { UserRoles } from 'utils/const';
import history from '../../history';
import NavbarBreadcrumbs from './NavbarBreadcrumbs';

const Navbar = ({ onLogout }) => {

  const isDistributor = useSelector((state) =>
    state.user?.roles.includes(UserRoles.DISTRIBUTION)
  );
  const {cart} = useSelector(state => state.shoppingList);
  const {boiler, boilerId} = useSelector(state => state.productInfo);

  const dispatch = useDispatch();

 const openCart = () => {
   dispatch(openCartModal())
 }

  const getLoadedProductSN = () => {
    if (boiler) {
      return '/' + boiler.serial_number;
    } else {
      return `/${boilerId}`;
    }
  };

  const goToWithSavedProduct = (path) => {
    getProductTypeRevision();
    history.push(path);
  };

  return (
    <nav className="navbar-container">
      <div className="navbar-top">
        <div className="navbar-left">
          <figure className="navbar-brand-figure">
            <img
              src={baseAIClogo}
              alt="AIC logo"
            />
          </figure>

          {!isDistributor && (
            <NavLink
              to="/dashboard"
              className={(isActive) =>
                'navbar-left-text bolded' + (isActive ? ' active-tab' : '')
              }>
              {translate('All devices')}
            </NavLink>
          )}

          <NavLink
            to="/spareparts"
            className={(isActive) =>
              'navbar-left-text' + (isActive ? ' active-tab' : '')
            }>
            {translate('spareparts')}
          </NavLink>

          {boilerId && (
            <NavLink
              to={'/product_info' + getLoadedProductSN()}
              className={(isActive) =>
                'navbar-left-text' + (isActive ? ' active-tab' : '')
              }>
              {translate('Device history')}
            </NavLink>
          )}
        </div>

        <div className="navbar-right">
          <div
            onClick={openCart}
            className={cart.length === 0 ? 'disabled-cart' : ''}>
            <div
              id="cart"
              className="navbar-icon">
              <NavShop />
            </div>
            {cart.length > 0 && (
              <div className="cart-length">{cart.length}</div>
            )}
          </div>

          <div
            id="preferences"
            onClick={() => togglePreferencesModal(true)}
            className="navbar-icon">
            <NavUser />
          </div>

          <div
            id="logOut"
            onClick={onLogout}
            className="navbar-icon">
            <NavLogout />
          </div>
        </div>
      </div>

      <div className="navbar-bottom">
        <div className="navbar-bottom--content">
          <NavbarBreadcrumbs
            goTo={goToWithSavedProduct}
            isDistributor={isDistributor}
          />

          {window.location.pathname.includes('spareparts') && (
            <div className="navbar-right">
              <div
                className="navbar-btn"
                onClick={() => goToWithSavedProduct('/spareparts/categories')}>
                {translate('Categories')}
              </div>
              <div
                className="navbar-btn"
                onClick={() => goToWithSavedProduct('/spareparts/search')}>
                {translate('Search')}
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default withRouter(Navbar);
