import React from 'react';
import './svgstyles.scss';

const InputSearch = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="#32343A"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2956 19.5972C16.024 21.5008 12.6308 21.3864 10.495 19.2509C8.24604 17.0023 8.25045 13.3496 10.5023 11.0981C12.7527 8.84811 16.406 8.8437 18.655 11.0923C20.7908 13.2278 20.9052 16.6203 19.0014 18.8915L19.9206 19.8106L20.195 19.5363C20.195 19.5363 21.935 21.276 22.8969 22.2377C23.2363 22.5771 23.2391 23.1398 22.8925 23.4863L22.8911 23.4877C22.5446 23.8342 21.9817 23.8315 21.6423 23.4921L18.9404 20.7907L19.2148 20.5163L18.2956 19.5972ZM17.2335 12.5136C18.7045 13.9844 18.7015 16.3629 17.2347 17.8295C15.7665 19.2974 13.3876 19.3004 11.9165 17.8296C10.4455 16.3589 10.4485 13.9803 11.9167 12.5123C13.3835 11.0458 15.7624 11.0428 17.2335 12.5136Z"
        fill="#32343A"
      />
    </svg>
  );
};

export default InputSearch;
