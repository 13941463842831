import React from 'react';
import './ProducActionCardHeader.scss';
import VectorClose from 'assets/svg/vectorClose';
import VectorOpen from 'assets/svg/vectorOpen';
import { HideProductHistoryCardSVG } from 'assets/svg/productActions/hideProductHistory';
import translate from 'utils/translate';
import { useSelector } from 'react-redux';

const ProductActionCardHeader = ( { setHeight, action, height } ) => {
  const {
    username, hidden: isActionHidden, user_action_date, displayed_name, type
  } = action;
  const blockedHeight = useSelector(state => state.appState.isImagesLoading);

  const handleShowBody = () => {
    if (blockedHeight) return;
    setHeight(height === 0 ? 'auto' : 0)
  }

  return (
      <div
        aria-expanded={height !== 0}
        aria-controls="example-panel"
        onClick={handleShowBody}
        className={`product-history-timeline-card-header`}>
        <div
          className={!isActionHidden
            ? `product-history-timeline-card-header-info`
            : `product-history-timeline-card-header-info hidden-header`}>
          <span style={{ paddingBottom: '9px' }}>
            {user_action_date?.split('T')[0]}
          </span>
          <span style={{ paddingBottom: '8px' }}>
            {displayed_name && translate(type)}
          </span>
          <span>
            {(username?.service_company !== 'null' && username?.service_company) || `${username?.first_name} ${username?.last_name}`}
          </span>
        </div>

        {isActionHidden && <HideProductHistoryCardSVG />}

        <div className='product-history-timeline-card-header-svg'>
          {height === 'auto'
            ? <VectorClose />
            : <VectorOpen />}
        </div>

      </div>
  );
};
export default ProductActionCardHeader;
