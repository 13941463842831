import DocumentDownload from 'assets/svg/documentDownload';
import React from 'react';

const DocumentLink = ({ url, description }) => {
  return (
    <div className="product-info-card-documents-item">
      <a
        href={url}
        rel="noopener noreferrer"
        target="_blank"
        download>
        <DocumentDownload />
        {description}
      </a>
    </div>
  );
};

export default DocumentLink;
