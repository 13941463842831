export const groupBySuitable = (arr) => {
  let newArr = {};
  arr.forEach((obj) => {
    if (!newArr[obj.index]) {
      newArr[obj.index] = obj;
    } else if (
      !newArr[obj.index].suitable_for.length &&
      obj.suitable_for.length
    ) {
      newArr[obj.index] = obj;
    }
  });
  return Object.values(newArr).map((val) => val);
};
