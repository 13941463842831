import React, { useState } from 'react';
import './profile.scss';
import EditProfile from './EditProfile';
import ChangePassword from './ChangePassword';
import translate from '../../utils/translate';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { togglePreferencesModal } from 'actions/actions';
import DeleteAccount from './DeleteAccount';
import Close from '../../assets/svg/close';

const PreferencesDialog = (props) => {
  const [selectedIndex, changeContent] = useState(0);

  const quit = () => {
    togglePreferencesModal(false);
  };

  const isSelected = (index) => (selectedIndex === index ? 'selected' : '');

  return (
    <ReactModal
      closeTimeoutMS={500}
      appElement={document.body}
      isOpen={props.isPreferencesModalOpen}
      className="preferences-modal"
      overlayClassName="preferences-modal-dialog"
      onRequestClose={quit}
      shouldCloseOnOverlayClick={true}
      style={{
        content: {
          position: 'relative'
        }
      }}>
      <div className="modal-content preferences-modal-content">
        <div
          className="close-icon-container"
          onClick={quit}>
          <Close />
        </div>
        <div className="profile-menu preferences-padding">
          <div className="preferences-container">
            <div
              className={'preferences-menu-item ' + isSelected(0)}
              onClick={() => changeContent(0)}>
              {translate('Profile')}
              <div className="preferences-menu-item-square"></div>
            </div>
            <div className="d-flex flex-column gap-4">
              <div>
                <button
                  onClick={() => changeContent(1)}
                  className="preferences-menu-button">
                  {translate('Change password')}
                </button>
              </div>
              <div>
                <button
                  onClick={() => changeContent(2)}
                  className="preferences-menu-button menu-button-delete">
                  {translate('Delete account')}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="profile-menu-content justify-content-center">
          {selectedIndex === 0 && props.user && <EditProfile />}
          {selectedIndex === 1 && <ChangePassword />}
          {selectedIndex === 2 && <DeleteAccount goBack={changeContent} />}
        </div>
      </div>
    </ReactModal>
  );
};

const mapStateToProps = (state) => {
  return {
    isPreferencesModalOpen: state.appState.isPreferencesModalOpen,
    language: state.appState.preferences.language,
    user: state.user
  };
};

export default connect(mapStateToProps, undefined)(PreferencesDialog);
