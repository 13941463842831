import React from 'react';
import './sparePartsSearch.scss';
import InputSearch from 'assets/svg/inputSearch';
import { DebounceInput } from 'react-debounce-input';
import translate from 'utils/translate';
import CloseBlackButton from 'assets/svg/close';
import { InputSpinner } from 'components/Spinners/Spinners';
import { minSNLength } from 'utils/const';

const SparePartsSearch = ({
  inputRef,
  searchPhrase,
  handleChange,
  handleSearchBySN,
  isResultsLoaded,
  clearSearchHandler,
  searchBySNMode,
  toggleSearchMode,
  showShortNameError
}) => {
  return (
    <div className="find-parts-search-input">
      <div className="buttons-container">
        <label className="search-by-sn-switcher">
          <input
            type="checkbox"
            value={searchBySNMode}
            checked={searchBySNMode}
            onChange={toggleSearchMode}
            className="checkbox-wrapper custom-checkbox"
          />
          <div className="label-text">
            <span>{translate('Search by serial number')}</span>{' '}
            {/*todo:add translation*/}
          </div>
        </label>
      </div>

      <div className="spareparts-number-input">
        <InputSearch />
        <DebounceInput
          inputRef={inputRef}
          minLength={1}
          debounceTimeout={
            searchBySNMode && searchPhrase.length < minSNLength ? 100 : 400
          }
          type="text"
          value={searchPhrase}
          onChange={searchBySNMode ? handleSearchBySN : handleChange}
          placeholder={translate('Search...')}
          id="searchPhrase"
        />
        <div className="input-icon-box">
          {!!searchPhrase &&
            (isResultsLoaded ? (
              <div onClick={clearSearchHandler} style={{ cursor: 'pointer' }}>
                <CloseBlackButton />
              </div>
            ) : (
              <InputSpinner />
            ))}
        </div>
      </div>

      {searchBySNMode && showShortNameError && (
        <div className="error-message">
          <p>
            * {translate('Enter whole serial number and wait for result')}{' '}
            {/*todo:add translation*/}
          </p>
        </div>
      )}
    </div>
  );
};

export default SparePartsSearch;
