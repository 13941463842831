import React from 'react';
import translate from '../../../utils/translate';

const CartOrderResults = ({ orderResult }) => {
  return (
    <div className="order-results">
      <div className="order-info">{orderResult.info}</div>
      {orderResult.errors && (
        <div className="order-errors">
          {orderResult.errors.map((error, index) => {
            return (
              <div
                className="order-error"
                key={index}>
                {error}
              </div>
            );
          })}
        </div>
      )}

      <div className="order-result-items">
        <div className="cart-table-container">
          <table>
            <thead>
              <tr>
                <th>{translate('Spare part')}</th>
                <th></th>
                <th>{translate('How many')}</th>
              </tr>
            </thead>
            <tbody>
              {orderResult['ordered_items'].map((orderItem, i) => {
                const {
                  count,
                  sparepart: { index, description }
                } = orderItem;
                return (
                  <tr
                    className="light-row"
                    key={i}>
                    <td>{index}</td>
                    <td>{description}</td>
                    <td>{count}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CartOrderResults;
