import React from 'react';
import translate from '../../utils/translate';
import { UserRoles } from '../../utils/const';

const UserData = ({ user, handleChange, departments }) => {
  const {
    user_name,
    first_name,
    last_name,
    phone_number,
    department,
    roles,
    service_company
  } = user;
  return (
    <>
      <h3 className="preferences-section-header">
        {translate('Personal data')}
      </h3>
      <div className="preferences-container-fieldset">
        <fieldset className="preferences-fieldset">
          <legend>{translate('username')}</legend>
          <input
            readOnly={true}
            type="text"
            className="form-control my-input user-display"
            value={user_name}
            id="user_name"
            aria-label="user name"
            aria-describedby="button-addon2"
          />
        </fieldset>
      </div>
      <div className="preferences-container-fieldset">
        <fieldset className="preferences-fieldset">
          <legend>{translate('first_name')}*</legend>
          <input
            type="text"
            className="form-control my-input"
            value={first_name}
            onChange={handleChange}
            id="first_name"
            placeholder={translate('first_name')}
            aria-label="first name"
            aria-describedby="button-addon2"
          />
        </fieldset>

        <fieldset className="preferences-fieldset">
          <legend>{translate('last_name')}*</legend>
          <input
            type="text"
            className="form-control my-input"
            value={last_name}
            onChange={handleChange}
            id="last_name"
            placeholder={translate('first_name')}
            aria-label="last name"
            aria-describedby="button-addon2"
          />
        </fieldset>
      </div>

      <div className="preferences-container-fieldset">
        <fieldset className="preferences-fieldset">
          <legend>{translate('phone_number')}</legend>
          <input
            type="text"
            className="form-control my-input"
            value={phone_number}
            onChange={handleChange}
            id="phone_number"
            placeholder="Phone number"
            aria-label="phone number"
            aria-describedby="button-addon2"
          />
        </fieldset>

        <fieldset className="preferences-fieldset">
          <legend>{translate('Department')}</legend>
          {departments && (
            <div className="dropdown">
              <a
                className="btn user-menu my-custom-select dropdown-toggle w-100"
                href=" "
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                <span className="w-auto float-start">{department}</span>
              </a>
              <div
                className="dropdown-menu w-100 dropdown-menu-right"
                aria-labelledby="dropdownMenuLink">
                {departments}
              </div>
            </div>
          )}
        </fieldset>
      </div>
      {roles.includes(UserRoles.SERVICE) && (
        <div className="preferences-container-fieldset">
          <fieldset className="preferences-fieldset service-company">
            <legend>{translate('Service company')}</legend>
            <input
              type="text"
              className="form-control my-input"
              value={service_company || ''}
              onChange={handleChange}
              id="last_name"
              placeholder={translate('Not Found')}
              aria-label="last name"
              aria-describedby="button-addon2"
              disabled
            />
          </fieldset>
        </div>
      )}
    </>
  );
};

export default UserData;
