import axios from 'axios';
import history from '../history';

const userInitialState = null;

export const userReducer = (userState = userInitialState, action) => {
  switch (action.type) {
    case 'login':
      axios.defaults.headers.common['XSRF-TOKEN'] = action.value.csrf;
      const newUserState = action.value;
      return {
        ...newUserState
      };

    case 'logout':
      axios.defaults.headers.common['XSRF-TOKEN'] = '';
      // TODO switch between spareparts and dashboard
      localStorage.setItem('redirectAfterLogin', history.location.pathname);
      // history.push('');
      // Cookies.remove('iboiler_access_token');

      history.push('/dashboard/login');
      return null;

    case 'password_changed':
      return { ...userState, need_to_change_password: false };

    case 'user_edit':
      return { ...action.value };

    default:
      return userState;
  }
};
