import React from 'react';
import './searchResultsTableFooter.scss';
import translate from 'utils/translate';
const SearchResultsTableFooter = ({ clearCartHandler, showCartHandler }) => {
  return (
    <tr>
      <td>
        <div className="spareparts-table-footer">
          <div className="spareparts-table-footer-btns-container">
            <div className="spareparts-table-footer-btns">
              <div
                className="aic-small-btn-outline"
                onClick={clearCartHandler}>
                {translate('Clear all selections')}
              </div>
              <div
                className="aic-small-btn"
                onClick={showCartHandler}>
                {translate('Show your shopping list')}
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default SearchResultsTableFooter;
