import React from 'react';
import './svgstyles.scss';

const CloseButton = (c) => {
  let size = `${!c.size || c.size === '' ? '16' : c.size}`;
  let cls = `${!c.cls || c.cls === '' ? 'close-button-svg' : c.cls}`;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      className={cls}
      fill="white"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className="cross-line"
        d="M14.2581 0.288359L0.288513 14.2581L2.03036 15.9999L16 2.03022L14.2581 0.288359Z"
        fill="white"
      />
      <path
        className="cross-line"
        d="M-1.01084e-06 1.74173L13.9696 15.7114L15.7115 13.9696L1.74185 -0.000129118L-1.01084e-06 1.74173Z"
        fill="white"
      />
    </svg>
  );
};

export default CloseButton;
