import React from 'react';
import translate from '../../../../../../utils/translate';
import RequiredMarker from 'components/RequiredMarker/RequiredMarker';
import './../UniversalActionForm.scss';

export const FieldLabel = (props) => {
  return (
    <div className="label">
      {translate(props.obj?.displayed_name)}
      <RequiredMarker obj={props.obj} />
    </div>
  );
};
