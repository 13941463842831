import React from 'react';
import './svgstyles.scss';

const ThumbUpIcon = ({ active }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill={active ? '#0EAE4A' : ''}
      viewBox="0 0 24 24">
      <path
        stroke={active ? '#0EAE4A' : ''}
        strokeWidth="0.3"
        d="M5,11.5 L5,18.5 C5,18.7761424 5.22385763,19 5.5,19 L7.5,19 C7.77614237,19 8,18.7761424 8,18.5 L8,11.5 C8,11.2238576 7.77614237,11 7.5,11 L5.5,11 C5.22385763,11 5,11.2238576 5,11.5 Z M8.50000665,10.3819527 L9.38196601,8.61803399 C9.78840295,7.80516012 10,6.90882062 10,6 L10,5.75 C10,4.78350169 10.7835017,4 11.75,4 C12.7164869,4 13.5257292,4.73235419 13.6218982,5.69404463 L13.9524938,9 L17.2900249,9 C18.6707368,9 19.7900249,10.1192881 19.7900249,11.5 C19.7900249,11.5830744 19.7858841,11.6660972 19.7776179,11.7487593 L19.1776179,17.7487593 C19.0498174,19.0267638 17.9744035,20 16.6900249,20 L10.5,20 C9.82744055,20 9.21691089,19.7344189 8.76756301,19.3024088 C8.50158217,19.7216998 8.03329134,20 7.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,11.5 C4,10.6715729 4.67157288,10 5.5,10 L7.5,10 C7.88418043,10 8.23462761,10.1444292 8.50000665,10.3819527 Z M9,11.618034 L9,17.5 C9,18.3284271 9.67157288,19 10.5,19 L16.6900249,19 C17.460652,19 18.1059004,18.4160583 18.1825807,17.6492556 L18.7825807,11.6492556 C18.7875404,11.5996583 18.7900249,11.5498446 18.7900249,11.5 C18.7900249,10.6715729 18.118452,10 17.2900249,10 L13.5,10 C13.2431243,10 13.0280415,9.80535276 13.0024814,9.54975186 L12.6268611,5.79354835 C12.5818122,5.34305971 12.2027355,5 11.75,5 C11.3357864,5 11,5.33578644 11,5.75 L11,6 C11,7.06406571 10.7522579,8.11351828 10.2763932,9.06524758 L9,11.618034 Z"
      />
    </svg>
  );
};

export default ThumbUpIcon;
