import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export const makeTextHighlighted = (text, highlight) => {
  // Split text on highlight term, include term itself into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span>
      {parts.map((part) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span
            className="highlighted"
            key={uuidv4()}>
            {part}
          </span>
        ) : (
          part
        )
      )}
    </span>
  );
};
