import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import translate from '../../utils/translate';
import '../Login/login.scss';
import axios from 'axios';
import './UserConfirm.scss';
import { ReactComponent as Logotype } from '../../assets/svg/logosquare.svg';
import { showError } from '../../actions/actions';

const UserConfirm = ({ type, location }) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isError, setIsError] = useState(false);

  const { token } = useParams();

  const apiUrl =
    type === 'account' ? '/api/auth/confirm/' : '/api/auth/confirm_email/';

  useEffect(() => {
    if (type === 'confirmError') {
      setIsConfirmed(true);
      return;
    }

    axios
      .get(apiUrl + token)
      .then((response) => {
        setIsConfirmed(true);
      })
      .catch((error) => {
        showError(error);
        setIsError(true);
      });
  }, [apiUrl, token]);

  const logotypeStyle = {
    position: 'fixed',
    height: '10rem',
    width: '10rem',
    top: '0',
    margin: '1rem auto',
    display: 'block'
  };

  return (
    <div
      className=" vertical-center vertical-center-register"
      style={{ overflow: 'hidden' }}>
      <div className="container center-block register-container">
        <div
          className="logotype"
          style={logotypeStyle}>
          <Logotype />
        </div>

        {isConfirmed && !isError && (
          <div className="login-step">
            <div className="login-step-title">
              {type === 'account' && translate('User confirmed.')}
              {type === 'email' && translate('User email confirmed.')}
              {type === 'confirmError' && location.state?.title}
            </div>
            <div className="login-welcome-text">
              {type === 'email' &&
                translate(
                  'Now your account need to be confirmed by AIC Department.'
                )}
              {type === 'confirmError' && location.state?.text}
            </div>
            <div className="login-welcome-buttons">
              <Link to="/login">
                <button className="contact-btn aic-btn">
                  {' '}
                  {translate('Back to log in')}{' '}
                </button>
              </Link>
            </div>
          </div>
        )}

        {isError && (
          <div className="login-step">
            <div className="login-step-title">
              {translate('Something went wrong')}
            </div>
            <div className="login-welcome-text">
              {translate(
                'Some problems have occurred with your account activation. Please contact administrator.'
              )}
            </div>
            <div className="login-welcome-buttons">
              <Link to="/login">
                <button className="contact-btn aic-btn">
                  {' '}
                  {translate('Back to log in')}{' '}
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserConfirm;
