import React from 'react';

const VectorOpen = () => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 0.625099L11.625 6.2501L10.8375 7.0376L6 2.2001L1.1625 7.0376L0.375 6.2501L6 0.625099Z"
        fill="#32343A"
      />
    </svg>
  );
};

export default VectorOpen;
