import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import aicMapStyles from '../../../utils/googlemapstyles';
import { googleApiUrl } from 'utils/const';
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';
import { v4 as uuidv4 } from 'uuid';
import Marker from './Marker';
import { MAX_ZOOM } from 'utils/const';
import { useDispatch, useSelector } from 'react-redux';
import { mapLoaded, mapCenterView } from 'actions/actions.jsx';

const DEFAULT_CENTER = { lat: 54.4743543, lng: 18.469897 };

let initialBounds = undefined;

const MapWithMarkerClusterer = React.forwardRef((props, ref) => {
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [scrollLock, setScrollLock] = useState(true);
  const [tooltipsArray, setTooltipsArray] = useState(
    Array.from(Array(300), () => false)
  );

  // redux state
  const dispatch = useDispatch();
  const mapCenterViewFromRedux = useSelector(
    (state) => state.dashboard.mapCenterView
  );

  const toggleScrollLock = (flag) => {
    setScrollLock(flag);
  };

  const pointThePin = (e) => {
    props.handlePinClick(e);
  };

  const toggleTooltip = (i) => {
    let copyArray = [...tooltipsArray];
    copyArray[i] = !copyArray[i];
    setTooltipsArray(copyArray);
  };

  const resetTooltipsArray = () => {
    setTooltipsArray(Array.from(Array(300), () => false));
  };

  const points = props.markers.map((boiler) => ({
    tooltipOpen: false,
    properties: {
      cluster: false,
      boiler: boiler
    },
    geometry: {
      type: 'Point',
      coordinates: [parseFloat(boiler.longitude), parseFloat(boiler.latitude)]
    }
  }));

  // if pins not appeared - change radius or max zoom at utils/const.jsx
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 100, maxZoom: MAX_ZOOM }
  });

  const panZoomOnClusterClick = (cluster_id, lat, lng) => {
    const expansionZoom = Math.min(
      supercluster.getClusterExpansionZoom(cluster_id),
      MAX_ZOOM
    );
    if (zoom !== MAX_ZOOM) {
      mapRef.current.setZoom(expansionZoom);
      mapRef.current.panTo({ lat: lat, lng: lng });
    }
  };

  const getClusterElements = (cluster_id) => {
    return supercluster.getLeaves(cluster_id, 999);
  };

  useEffect(() => {
    // fit map bounds to all markers
    if (props.markers.length > 0 && !initialBounds && mapRef.current) {
      initialBounds = new window.google.maps.LatLngBounds();
      props.markers.forEach((marker) => {
        initialBounds.extend(
          new window.google.maps.LatLng(marker.latitude, marker.longitude)
        );
      });
      setTooltipsArray(new Array(props.markers.length).fill(false));
      mapRef.current.fitBounds(initialBounds);
    }
  }, [props.markers, initialBounds]);

  // to allow for using center map by parent
  useImperativeHandle(ref, () => ({
    centerOnMap(product) {
      mapRef.current.setZoom(MAX_ZOOM);
      mapRef.current.panTo({
        lat: product.latitude,
        lng: product.longitude
      });
    }
  }));

  return (
    <GoogleMapReact
      options={{
        styles: aicMapStyles,
        disableDefaultUI: true,
        minZoom: 1,
        maxZoom: MAX_ZOOM,
        scrollwheel: scrollLock
      }}
      defaultZoom={1}
      defaultCenter={DEFAULT_CENTER}
      bootstrapURLKeys={{ key: googleApiUrl }}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map }) => {
        mapRef.current = map;
        dispatch(mapLoaded(true)); // set map loaded true to redux
        mapCenterViewFromRedux &&
          mapRef.current.setCenter(mapCenterViewFromRedux?.coordinatesCenter); //set coordinates if is in redux
        mapCenterViewFromRedux &&
          mapRef.current.setZoom(mapCenterViewFromRedux?.zoom); //set zoom if is in redux

        // need to set non null bounds when its over 1k pins
        setBounds([
          -227.0083325, -43.22022351899159, 350.25729250000006,
          73.06463145778358
        ]);
      }}
      onChange={({ zoom, bnds }) => {
        // set new bounds for clustering function
        if (bnds)
          setBounds([bnds.nw.lng, bnds.se.lat, bnds.se.lng, bnds.nw.lat]);
        setZoom(zoom);

        // set map center view
        const coordinatesCenter = mapRef.current?.getCenter();
        coordinatesCenter &&
          dispatch(
            mapCenterView({
              zoom: zoom,
              coordinatesCenter: coordinatesCenter
            })
          );
      }}>
      {clusters.map((cluster, index) => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const { cluster: isCluster, point_count: pointCount } =
          cluster.properties;
        cluster.index = index;
        return (
          <Marker
            toggleTooltip={toggleTooltip}
            pointThePin={pointThePin}
            color={'red'}
            key={uuidv4()}
            lat={latitude || 0.0}
            lng={longitude || 0.0}
            zoom={zoom}
            isCluster={isCluster}
            pointCount={pointCount}
            cluster={cluster}
            tooltipOpen={tooltipsArray[index]}
            panZoomOnClusterClick={panZoomOnClusterClick}
            resetTooltipsArray={resetTooltipsArray}
            getClusterElements={getClusterElements}
            toggleScrollLock={toggleScrollLock}
          />
        );
      })}
    </GoogleMapReact>
  );
});

export default MapWithMarkerClusterer;
