import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Spareparts from './SparepartsWelcomePage/SpareParts/Spareparts';
import { fetchAllSpareparts, getUserLoadHistory } from '../../actions/actions';
import SparepartDetails from './SparePartsDetails/Details';
import SparepartCategoriesPage from './SparepartCategories/SparepartCategories';
import SparepartFind from './SparepartFind/SparepartFind';

class SparepartsSwitch extends Component {
  componentDidMount() {
    fetchAllSpareparts();
    getUserLoadHistory();
  }

  render() {
    return (
      <Switch>
        <Route
          path="/spareparts/details"
          component={SparepartDetails}
        />
        <Route
          path="/spareparts/categories"
          component={SparepartCategoriesPage}
        />
        <Route
          path="/spareparts/search"
          component={SparepartFind}
        />
        <Route
          path={`${this.props.match.url}/:family/:model/:revision/:group/:set_number`}
          component={Spareparts}
        />
        <Route
          path={`${this.props.match.url}/:family/:model/:revision/:group`}
          component={Spareparts}
        />
        <Route
          path={`${this.props.match.url}/:family/:model/:revision`}
          component={Spareparts}
        />
        <Route
          path={`${this.props.match.url}/:family/:model`}
          component={Spareparts}
        />
        <Route
          path={`${this.props.match.url}/:family`}
          component={Spareparts}
        />
        <Route
          path={`${this.props.match.url}`}
          component={Spareparts}
        />
      </Switch>
    );
  }
}

export default SparepartsSwitch;
