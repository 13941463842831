import React, { useEffect, useState } from 'react';
import './SparepartsForProductInfo.scss';
import translate from '../../../../../utils/translate';
import { Spinner2 } from '../../../../../components/Spinners/Spinners';
import { v4 as uuidv4 } from 'uuid';
import {
  getProductTypeRevision,
  getSparepartGroupsForRevision
} from '../../../../../actions/actions';
import history from '../../../../../history';

const SparepartsForProductInfo = ({ deviceInfo }) => {
  const [sparepartsGroups, setSparepartsGroups] = useState();

  useEffect(() => {
    if (deviceInfo) {
      getSparepartGroupsForRevision(deviceInfo.product_type.id).then(
        (response) => {
          if (response.sparepart_groups) {
            setSparepartsGroups(response.sparepart_groups);
          }
        }
      );
    }
  }, [deviceInfo]);

  const goToSparepartsGroup = (group) => {
    getProductTypeRevision(deviceInfo.product_type, group.name, group);
    history.push('/spareparts');
  };

  const renderLinks = () => {
    if (sparepartsGroups) {
      return sparepartsGroups.map((group) => {
        return (
          <div
            onClick={() => goToSparepartsGroup(group)}
            className="sparepart-group-line"
            key={uuidv4()}>
            {group.name}
          </div>
        );
      });
    }
    return null;
  };

  return (
    <>
      <div className="product-info-card-header">
        <p>{translate('Find Spareparts')}</p>
        {!sparepartsGroups && <Spinner2 />}
      </div>
      <div className="product-info-sparepart-groups">{renderLinks()}</div>
    </>
  );
};
export default SparepartsForProductInfo;
