import { changeImagesInBoilerHistory } from '../utils/changeImagesInBoilerHistory';

const initialProductInfoState = {
  boiler: undefined,
  actionList: []
};

export const productInfoReducer = (state = initialProductInfoState, action) => {
  switch (action.type) {
    case 'newProduct':
      return {
        ...state,
        boiler: action.boiler,
        error: undefined
      };

    case 'changeImages':
      return {
        ...state,
        boiler: changeImagesInBoilerHistory(state.boiler, action.value),
      };

    case 'setActionList':
      let selectableActionList = action.value.map((action, index) => {
        return { value: action.type, label: action.displayed_name };
      });
      return {
        ...state,
        actionList: action.value,
        selectableActionList: selectableActionList
      };

    case 'save_boiler_id':
      return {
        ...state,
        boilerId: action.value
      };

    default:
      return state;
  }
};
