import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProductParts from './ProductParts/ProductParts.jsx';
import MyProductsMap from './ProductsList/MyProductsMap.jsx';

const DashboardSwitch = (props) => {
  return (
    <Switch>
      <Route
        path={`${props.match.url}/:serialNumber/parts`}
        component={ProductParts}
      />
      <Route
        path="/"
        component={MyProductsMap}
      />
    </Switch>
  );
};

export default DashboardSwitch;
