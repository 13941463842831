import React from 'react';
import ErrorBoldIcon from '../../assets/svg/errorbold';
import translate from '../../utils/translate';
import { Link } from 'react-router-dom';

const SparepartByReplaced = ({
  sparepart: {
    replaced_by: { index, description }
  }
}) => {
  return (
    <div className="replaced_by">
      <div className="in-circle">
        <ErrorBoldIcon />
      </div>
      <div className="replaced_by-content">
        <div className="title"> {translate('Replaced by')}</div>
        <div className="replaced_by-item">
          <span>
            <Link
              className="replaced-by-link"
              to={`/spareparts/details/${index}`}>
              {description}
            </Link>{' '}
            ({index})
          </span>
        </div>
      </div>
    </div>
  );
};

export default SparepartByReplaced;
