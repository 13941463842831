import CloseButton from 'assets/svg/close';
import translate from 'utils/translate';
import {
  chipTheme,
  fieldsWithChips,
  fieldsWithoutMargins,
  statuses,
  theme
} from 'utils/const';
import React, { useEffect, useRef, useState } from 'react';
import { saveFilterAndGetProducts } from 'actions/actions.jsx';
import { useSelector } from 'react-redux';
import { datePlaceholder, userNamePlaceholder } from 'utils/const';
import { ReactComponent as CalendarIcon } from 'assets/svg/Calendar.svg';
import Chips from 'react-chips';

// calendar
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DateInputFilter from './DateInputFilter';

// form close filter view after add chips to formData
let isAllChipsAdded = true;

const TooltipFilter = ({
  toggleTip,
  filterRow,
  dashboardProductsFilters,
  allProducts
}) => {
  // redux
  const { departments, warehouses } = useSelector((state) => state.appState);
  const selectableActionList = useSelector(
    (state) => state.productInfo?.selectableActionList
  );

  //local states
  const [formData, setFormData] = useState({});

  // refs
  const chipsRef = useRef([]);

  // set initial data filters
  useEffect(() => {
    filterRow.formFields.forEach((formFielditem) => {
      const selectedProduscts = dashboardProductsFilters[formFielditem] ?? [];

      let helpArrayOfProducts = [];

      if (typeof selectedProduscts === 'string') {
        // for chips string change for array
        if (selectedProduscts?.length > 0) {
          helpArrayOfProducts = selectedProduscts
            .split(',')
            .map((product) => product.trim());
        }
      } else {
        // for checkboxes do nothing because is an array
        helpArrayOfProducts = selectedProduscts;
      }
      setFormData((prevState) => ({
        ...prevState,
        [formFielditem]: helpArrayOfProducts
      }));
    });
  }, []);

  const handleChangeInput = (e, item, formField) => {
    const copiedFormData = { ...formData };

    // for status, department
    if (e.target.type === 'checkbox') {
      copiedFormData[formField] = copiedFormData[formField] || [];

      if (e.target.checked) {
        copiedFormData[formField].push(item);
      } else {
        copiedFormData[formField] = copiedFormData[formField].filter(
          (name) => name.value !== item.value
        );
      }
    }
    // for text chips inputs
    if (e.target.type === 'text') {
      copiedFormData[formField] = item; // here is an array
    }
    setFormData({ ...copiedFormData });
  };

  const [visibleCalendarName, setVisibleCalendarName] = useState('');

  const setDayFromCalendar = (value, fieldName) => {
    // close calendar view
    setVisibleCalendarName('');

    // set date to input
    const copiedFormData = { ...formData };
    const chosenDate = [
      `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`
    ];
    copiedFormData[fieldName] = chosenDate;
    setFormData({ ...copiedFormData });
  };

  // form close filter view after add chips to formData
  useEffect(() => {
    if (!isAllChipsAdded) {
      isAllChipsAdded = true;
      acceptFilter();
    }
  }, [isAllChipsAdded]);
  // call when click OK
  const acceptFilter = () => {
    // only for chips => if is text in input but not made chips
    Object.values(chipsRef.current).forEach((chipsRefItem) => {
      if (chipsRefItem?.state.value) {
        chipsRefItem.addChip(chipsRefItem.state.value);
        isAllChipsAdded = false;
      }
    });
    if (!isAllChipsAdded) {
      return;
    }
    // close filter
    toggleTip();
    // close calendar view
    setVisibleCalendarName('');

    const formDataToReducer = {};
    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (fieldsWithChips.includes(key)) {
          formDataToReducer[key] = formData[key].join(', '); // for chips => array must be changed for string
        } else {
          formDataToReducer[key] = formData[key];
        }
      }
    }
    // dispatch action
    saveFilterAndGetProducts(formDataToReducer);
  };

  // call when click CANCEL
  const cancelFilter = () => {
    // close filter
    toggleTip();

    // close calendar view
    setVisibleCalendarName('');
  };

  const clearArrayField = (fieldName) => {
    const copyFormData = { ...formData };
    copyFormData[fieldName] = [];
    setFormData(copyFormData);
  };

  // filter for: Device, Power output, Serial number, Index, Technician
  const filterCell = (longName, formField, placeholder) => {
    const icon = longName.toLowerCase().includes('date') ? (
      <div
        className="filters-clear-button"
        onClick={() =>
          setVisibleCalendarName((prevState) => (prevState ? '' : longName))
        }
      >
        <CalendarIcon />
      </div>
    ) : (
      <div
        className="filters-clear-button"
        onClick={() => clearArrayField(formField)}
      >
        <CloseButton />
      </div>
    );

    return (
      <div
        className="filters-container-fieldset"
        style={{
          marginBottom:
            longName === visibleCalendarName &&
            !fieldsWithoutMargins.includes(longName)
              ? '150px'
              : '0'
        }}
      >
        <fieldset className="filters-fieldset">
          <legend>{translate(longName)}</legend>
          <Chips
            ref={(el) => (chipsRef.current[formField] = el)}
            placeholder={placeholder}
            value={formData[formField] || []}
            onChange={(chipsArray) =>
              handleChangeInput(
                { target: { type: 'text' } },
                chipsArray,
                formField
              )
            }
            theme={theme}
            chipTheme={chipTheme}
            createChipKeys={[' ', ',']} // separations to which make chips
          />
          {icon}
        </fieldset>
        {longName === visibleCalendarName && (
          <div className="filters__calendar_view">
            <Calendar
              defaultView="month"
              maxDetail="month"
              minDetail="month"
              onClickDay={(value, event) =>
                setDayFromCalendar(value, formField)
              }
              locale={
                JSON.parse(localStorage.getItem('preferences')).language
                  ? JSON.parse(localStorage.getItem('preferences')).language
                  : navigator.language
              }
            />
          </div>
        )}
      </div>
    );
  };

  // filter status, Location, LastAction, Department, Inspection
  const filterMutliCheckbox = (itemsArray, formField) => {
    return itemsArray.map((item, index) => (
      <div
        className="settings__filtersList_container_items"
        key={'status' + index}
      >
        <label className="settings__filtersList_label">
          <input
            data-cy={item}
            type="checkbox"
            value={item}
            checked={!!formData[formField]?.find((i) => i.value === item.value)}
            onChange={(e) => handleChangeInput(e, item, formField)}
            className="settings__filtersList_input"
          />

          {/* text for status  */}
          {filterRow.apiField === 'status' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <span className="dot" style={{ background: item.color }} />
              <span className="checkbox-text">{translate(item.label)}</span>
            </div>
          )}

          {/* text for location  */}
          {filterRow.apiField === 'location' && (
            <div className="checkbox-text">
              <p
                style={{
                  fontWeight: 'bold',
                  marginBottom: '0'
                }}
              >{`${item.name} [${item.department.code}]`}</p>
              <p style={{ marginBottom: '0' }}>{item.address}</p>
            </div>
          )}

          {/* text for lastAction  */}
          {filterRow.apiField === 'lastAction' && (
            <div className="checkbox-text">
              <p style={{ marginBottom: '0' }}>{translate(item.value)}</p>
            </div>
          )}

          {/* text for inspection  */}
          {filterRow.apiField === 'nextInspectionDate' && (
            <div className="checkbox-text">
              <p style={{ marginBottom: '0' }}>{translate(item.label)}</p>
            </div>
          )}

          {/* text for department  */}
          {filterRow.apiField === 'department' && (
            <div className="checkbox-text">
              <p
                style={{
                  fontWeight: 'bold',
                  marginBottom: '0'
                }}
              >{`${item.country} [${item.code}]`}</p>
              <p style={{ marginBottom: '0' }}>{item.address}</p>
            </div>
          )}
        </label>
      </div>
    ));
  };

  const hrDivider = (
    <div className="hr">
      <hr />
    </div>
  );

  // all filters
  const filtersByType = () => {
    switch (filterRow.type) {
      // filter for: Status
      case 'multicheckbox':
        return (
          <div className="settings__filtersList_container_items_status">
            {filterMutliCheckbox(statuses, 'status')}
          </div>
        );

      // filter for: department
      case 'dept':
        return (
          <div className="settings__filtersList_container_items_multiCheckBox">
            {filterMutliCheckbox(departments, 'department')}
          </div>
        );

      // filter for: Device, Power output, Serial number, Index, Technician
      case 'string':
        return (
          <div>
            {filterCell(
              filterRow.longName,
              filterRow.apiField,
              filterRow.placeholder
            )}
            <p className="filter-hint">
              {!(filterRow.hint === 'www_dashboard_power_hint') &&
                translate('comma separated')}{' '}
              {translate(filterRow.hint)}
            </p>
          </div>
        );

      // filter for: Location
      case 'location':
        return (
          <div className="date-filter">
            {filterCell(
              filterRow.name,
              filterRow.apiField,
              filterRow.placeholder
            )}
            <p className="filter-hint">{translate('comma separated')}</p>
            {hrDivider}
            <div className="settings__filtersList_container_items_multiCheckBox">
              {filterMutliCheckbox(filteredWarehouses, 'store')}
            </div>
          </div>
        );

      // filter for: Start of warranty, End of warranty, Installation, Commissioning
      case 'date':
        return (
          <DateInputFilter
            contentFrom={filterCell(
              'Date from',
              filterRow.apiField + 'From',
              filterRow.placeholder
            )}
            contentTo={filterCell(
              'Date to',
              filterRow.apiField + 'To',
              filterRow.placeholder
            )}
          />
        );

      // filter for: Last action
      case 'lastAction':
        return (
          <div className="date-filter">
            {filterCell(
              'Technician',
              'lastActionTechnician',
              userNamePlaceholder
            )}
            <DateInputFilter
              contentFrom={filterCell(
                'Date from',
                'lastActionDateFrom',
                datePlaceholder
              )}
              contentTo={filterCell(
                'Date to',
                'lastActionDateTo',
                datePlaceholder
              )}
            />
            {hrDivider}
            <div className="settings__filtersList_container_items_multiCheckBox">
              {filterMutliCheckbox(
                selectableActionList ?? [],
                'lastActionType'
              )}
            </div>
          </div>
        );

      // filter for: Inspection
      case 'inspection':
        return (
          <div className="inspection-filter">
            <DateInputFilter
              contentFrom={filterCell(
                'Last inspection date from',
                'lastInspectionDateFrom',
                datePlaceholder
              )}
              contentTo={filterCell(
                'Last inspection date to',
                'lastInspectionDateTo',
                datePlaceholder
              )}
            />
            <DateInputFilter
              contentFrom={filterCell(
                'Next inspection date from',
                'nextInspectionDateFrom',
                datePlaceholder
              )}
              contentTo={filterCell(
                'Next inspection date to',
                'nextInspectionDateTo',
                datePlaceholder
              )}
            />
            {hrDivider}
            <div className="inspectionOverdue_checkbox">
              {filterMutliCheckbox(
                [{ label: 'Inspection overdue', value: 'true' }],
                'inspectionOverdue'
              )}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const filteredWarehouses = warehouses.filter((i) =>
    allProducts?.some(
      (dashboardProduct) => dashboardProduct?.store.name === i.name
    )
  );

  // general JSX
  return (
    <div
      className="settings__filtersList_container"
      data-cy={`${filterRow.longName} container`}
    >
      {/* description  */}
      <p className="settings__filtersList_header">{`${translate(
        'Filter by'
      )}: ${translate(filterRow.longName)}`}</p>

      {hrDivider}

      {/* all filters  */}
      <div>{filtersByType()}</div>

      {hrDivider}

      {/* buttons */}
      <div className="right_btn_container">
        <div>
          <button className="right_btn" onClick={() => cancelFilter()}>
            {translate('Cancel')}
          </button>
        </div>
        <div>
          <button
            className="right_btn right_btn_dark"
            onClick={() => acceptFilter()}
          >
            {translate('Apply filters')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TooltipFilter;
