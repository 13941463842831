import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './../UniversalActionForm.scss';
import { DatetimePickerTrigger } from 'rc-datetime-picker';
import 'rc-datetime-picker/dist/picker.min.css';
import { FieldLabel } from './FieldLabel';

export const ActionDate = (props) => {
  const { obj, onChangeInput, inputValue, errorMessage, momentValue } = props;

  return (
    <div
      key={obj.name}
      className="input-container">
      <div className="brand-new-input">
        <div className="brand-new-input-label">
          <FieldLabel obj={obj} />
        </div>
        <DatetimePickerTrigger
          moment={momentValue}
          onChange={(date) => onChangeInput(date)}>
          <input
            type="text"
            className="time-input"
            value={inputValue.format('YYYY-MM-DD HH:mm')}
            readOnly
          />
        </DatetimePickerTrigger>
      </div>

      {errorMessage}
    </div>
  );
};
