import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProductDashboard from './ProductDashboard/ProductDashboard';
import './Traveller.css';

const Traveller = (props) => {
  return (
    <Switch>
      <Route
        path={`${props.match.url}/:boiler_id`}
        component={ProductDashboard}
      />
    </Switch>
  );
};

export default Traveller;
