import React from 'react';
import './validationError.scss';
import Fade from 'react-reveal/Fade';

const ValidationError = ({ text }) => (
  <div className="w-100">
    <Fade top collapse show>
      <small className="form-text error w-100">{text}</small>
    </Fade>
  </div>
);

export default ValidationError;
