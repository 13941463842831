import React, { useState } from 'react';
import './ProductPageHeader.scss';
import translate from '../../../../utils/translate';
import { useLoadingWhileUpdateBoilerInfo } from '../ProductDashboard';

const ProductPageHeader = () => {
  const [boilerInfo, setBoilerInfo] = useState();
  useLoadingWhileUpdateBoilerInfo(setBoilerInfo);

  return (
    <div className="header">
      <div className="header-title">
        <p>{translate('Product information')}</p>
      </div>
      {boilerInfo && boilerInfo.product_type ? (
        <div className="header-product-info">
          <p style={{ fontWeight: '600' }}>
            {boilerInfo.product_type.family}
            {boilerInfo.product_type.subfamily &&
              ` ${boilerInfo.product_type.subfamily}`}
          </p>
          <p style={{ padding: '0 1.5rem 0' }}>
            {translate('Power output')}: {boilerInfo.product_type.value} {boilerInfo.product_type.unit || ''}
          </p>
          <p>
            {translate('Serial number')}: {boilerInfo.serial_number}
          </p>
        </div>
      ) : null}
    </div>
  );
};
export default ProductPageHeader;
