import React, { useRef } from 'react';
import SearchResultsTable from './SearchResultsTable/SearchResultsTable';
import SearchResultsTitle from './SearchResultsTitle/SearchResultsTitle';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import ResultsBySerialNumber from './ResultsForSearchBySN/ResultsBySerialNumber';
import NoResultsFound from './NoResultsFound/NoResultsFound';

const SearchResults = ({
  searchPhrase,
  results,
  resultsBySN,
  showSearchButton,
  isResultsLoaded,
  onScrollButtonClick,
  searchBySNMode,
  receivedImage
}) => {
  const resultsFound = !!(
    searchPhrase.trim() &&
    ((!searchBySNMode && results.length) || (searchBySNMode && resultsBySN))
  );

  const withResultsRef = useRef(null);
  const withoutResultsRef = useRef(null);
  const nodeRef = resultsFound ? withResultsRef : withoutResultsRef;

  //these components should be passed as variables into CSSTransition "key" property
  const noResultsFound = (
    <NoResultsFound
      searchPhrase={searchPhrase}
      results={results}
      resultsBySN={resultsBySN}
      isResultsLoaded={isResultsLoaded}
      searchBySNMode={searchBySNMode}
      withoutResultsRef={withoutResultsRef}
    />
  );

  const withResultsBlock = (
    <div ref={withResultsRef}>
      {searchBySNMode ? (
        <ResultsBySerialNumber
          results={resultsBySN}
          searchPhrase={searchPhrase}
          receivedImage={receivedImage}
        />
      ) : (
        <>
          <SearchResultsTitle
            searchPhrase={searchPhrase}
            showSearchButton={showSearchButton}
            isResultsLoaded={isResultsLoaded}
            onScrollButtonClick={onScrollButtonClick}
          />
          <SearchResultsTable results={results} />
        </>
      )}
    </div>
  );

  return (
    <div className="search-results">
      <div className="cart-table-container">
        <SwitchTransition mode="in-out">
          <CSSTransition
            key={resultsFound ? withResultsBlock : noResultsFound}
            nodeRef={nodeRef}
            classNames="fade"
            addEndListener={(done) => {
              nodeRef.current.addEventListener('transitionend', done, false);
            }}>
            {resultsFound ? withResultsBlock : noResultsFound}
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  );
};

export default SearchResults;
