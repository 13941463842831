import React, { useEffect, useState } from 'react';
import './LiveData.scss';
import translate from '../../../../../utils/translate';
import {
  getIotSensors,
  registerIotDeviceRequest,
  toggleIotSensor
} from 'actions/actions';
import { Spinner2 } from '../../../../../components/Spinners/Spinners';
import Settings from 'assets/svg/Settings';
import { ActionInput } from '../../ProductHistory/AddAction/ActionTypes/ActionInput';
import CloseBlackButton from '../../../../../assets/svg/close';

const LiveData = (props) => {
  const [iotReadings, setIotReadings] = useState();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [listening, setListening] = useState(undefined);
  const [deviceToRegister, setDeviceToRegister] = useState('');
  const [deviceRegistered, setDeviceRegistered] = useState(false);

  let liveDataInterval;

  const readLiveData = () => {
    getIotSensors(props.serial_number).then((result) => {
      if (result && result.length === 0) {
        clearInterval(liveDataInterval);
      }
      setIotReadings(result);
    });
  };

  useEffect(() => {
    readLiveData();
    return () => {
      clearInterval(listening);
    };
  }, []);

  useEffect(() => {
    listenProductLiveData();
  }, [props.serial_number]);

  const toggleSettingsOpen = (flag) => {
    clearInterval(listening);
    if (flag) clearInterval(listening);
    if (!flag) listenProductLiveData();
    setIsSettingsOpen(flag);
  };

  const listenProductLiveData = () => {
    liveDataInterval = setInterval(() => {
      readLiveData();
    }, 10000);
    setListening(liveDataInterval);
  };

  const handleCheckbox = (e, id) => {
    toggleIotSensor(id, e.target.checked, props.serial_number).then(
      (result) => {
        setIotReadings(result);
      }
    );
  };

  const registerIotDevice = () => {
    registerIotDeviceRequest(props.serial_number, deviceToRegister).then(() =>
      setDeviceRegistered(true)
    );
  };

  const renderSensors = () => {
    if (iotReadings && iotReadings.length > 0) {
      return iotReadings.map((device) => {
        return (
          <div key={device.device_name}>
            {isSettingsOpen ? (
              <div className="device-name bolded">{device.device_name}</div>
            ) : device.sensors.some((sensor) => sensor.selected === true) &&
              iotReadings.length > 1 ? (
              <div className="device-name underlined">{device.device_name}</div>
            ) : null}

            {device.sensors.map((sensor) => {
              if (!sensor.selected && !isSettingsOpen) return null;

              return (
                <div
                  key={sensor.id}
                  className="sensor-row">
                  <div className="left-column">
                    {isSettingsOpen && (
                      <label className="customcheck">
                        <input
                          type="checkbox"
                          defaultChecked={sensor.selected}
                          onChange={(e) => handleCheckbox(e, sensor.id)}
                        />
                        <span className="checkmark"> </span>
                      </label>
                    )}

                    <div className="sensor-left-line">
                      <div
                        className={`sensor-name ${
                          !isSettingsOpen ? ' bolded' : ''
                        }`}>
                        {sensor.sensor_name}
                      </div>
                    </div>
                  </div>
                  <div className="right-column">
                    {!isSettingsOpen && (
                      <div className="measurement">
                        {sensor.measurement.text_value}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        );
      });
    }
    return null;
  };

  return (
    <>
      <div className="product-info-card-header">
        <p>{translate('Live data')}</p>
        {iotReadings && (
          <div
            className="settings-button"
            onClick={() => toggleSettingsOpen(!isSettingsOpen)}>
            {isSettingsOpen ? <CloseBlackButton /> : <Settings />}
          </div>
        )}

        {!iotReadings && <Spinner2 />}
      </div>
      <div className="product-info-card-documents">
        {isSettingsOpen && !deviceRegistered && (
          <div className="action-fields-container">
            <div className="action-fields-fieldslist">
              <ActionInput
                obj={{
                  displayed_name: 'register iot device',
                  name: 'iotDevice',
                  optional: true
                }}
                onChangeInput={(e) => setDeviceToRegister(e.target.value)}
                inputValue={deviceToRegister}
                errorMessage={''}
              />
            </div>

            <div className="register-iot-form">
              <button
                className="aic-btn-black"
                onClick={() => registerIotDevice()}>
                {translate('send')}
              </button>
            </div>
          </div>
        )}

        {isSettingsOpen && deviceRegistered && (
          <div className="request-status">{translate('request sent')}</div>
        )}

        {renderSensors()}
      </div>
    </>
  );
};

export default LiveData;
