export const changeImagesInBoilerHistory = (boiler, data) => {
  const changedHistory = boiler.history.map(historyItem => {
    if (historyItem.id !== data.actionId) return historyItem;

    return {
      ...historyItem,
      files: data.images
    }
  });

  return {
    ...boiler,
    history: changedHistory
  }
}
