import * as yup from 'yup';
import translate from './translate';

export const registerSchema = yup.object().shape({
  email: yup
    .string()
    .required(translate('This field cannot be empty.'))
    .email(translate('Invalid email address. ')),
  firstName: yup.string().required(translate('This field cannot be empty.')),
  lastName: yup.string().required(translate('This field cannot be empty.')),
  isUser: yup.boolean(),
  isItaly: yup.boolean(),
  password: yup.string().required(translate('This field cannot be empty.')),
  confirmPassword: yup.boolean().oneOf([true], 'not acceptable password'),
  phoneNumber: yup.string().required(translate('This field cannot be empty.')),
  role: yup.object().required(translate('You have to choose your role')),
  acceptTermsAndConditions: yup
    .boolean()
    .oneOf([true], translate('Please accept Terms and Conditions. Try again')),
  department: yup.object().when('isUser', {
    is: false,
    then: yup
      .object()
      .required(translate('You have to choose your department.'))
  }),
  taxNumber: yup.string().when('isItaly', {
    is: true,
    then: yup.string().required(translate('This field cannot be empty.'))
  })
});
