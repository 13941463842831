import React from 'react';

const Commissioning = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6563 3.22876L12.0938 4.20188C13.2733 4.88293 14.1953 5.93416 14.7165 7.19256C15.2378 8.45096 15.3292 9.84619 14.9767 11.1619C14.6241 12.4775 13.8473 13.6401 12.7667 14.4693C11.6861 15.2985 10.3621 15.7479 9 15.7479C7.63792 15.7479 6.3139 15.2985 5.23329 14.4693C4.15268 13.6401 3.37587 12.4775 3.02334 11.1619C2.6708 9.84619 2.76225 8.45096 3.2835 7.19256C3.80474 5.93416 4.72666 4.88293 5.90625 4.20188L5.34375 3.22876C3.94968 4.03363 2.86015 5.276 2.24413 6.7632C1.62812 8.2504 1.52004 9.8993 1.93667 11.4542C2.3533 13.0091 3.27135 14.383 4.54844 15.363C5.82552 16.3429 7.39027 16.8741 9 16.8741C10.6097 16.8741 12.1745 16.3429 13.4516 15.363C14.7287 14.383 15.6467 13.0091 16.0633 11.4542C16.48 9.8993 16.3719 8.2504 15.7559 6.7632C15.1399 5.276 14.0503 4.03363 12.6563 3.22876Z"
        fill="#32343A"
      />
      <path
        d="M9.5625 1.125H8.4375V9H9.5625V1.125Z"
        fill="#32343A"
      />
    </svg>
  );
};

export default Commissioning;
