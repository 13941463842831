import ExcelJS from 'exceljs';

const exportDataToXLSX = (data, fileName) => {
  // create a new workbook
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');
  // define the header row
  const header = data.headers;
  worksheet.addRow(header);

  // add data rows
  data.rows.forEach((row) => {
    worksheet.addRow(row);
  });

  // create a buffer from the workbook
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = window.URL.createObjectURL(blob);

    // Create a download link and trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();

    // Release the object URL
    window.URL.revokeObjectURL(url);
  });
};

export default exportDataToXLSX;
