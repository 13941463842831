import axios from 'axios';
import store from 'store';
import history from '../history';

export const setRedirectAfterLogin = (path) => {
  return store.dispatch({ type: 'setRedirectAfterLogin', value: path });
};

export const toggleTooltip = (flag) => {
  return store.dispatch({ type: 'enableTooltip', value: flag });
};

export const togglePreferencesModal = (flag) => {
  return store.dispatch({
    type: 'togglePreferencesModal',
    value: flag
  });
};

export const showError = (error) => {
  store.dispatch({
    type: 'showMsg',
    value: { msg: error }
  });
};

export const closeCartModal = () => ({
  type: 'closeCartModal'
});

export const openCartModal = () => ({
  type: 'openCartModal'
});

export const addToCart = (part, revision) => ({
  type: 'addToCart',
  value: { part, revision }
});

export const clearCart = () => ({
  type: 'clearCart'
});

export const removeFromCart = (part) => ({
  type: 'removeFromCart',
  value: part
});

export const updateCart = (partToChange, countDiff) => ({
  type: 'updateCart',
  value: { partToChange, countDiff }
});

export const toggleImagesLoading = (flag) => store.dispatch({ type: 'toggleImagesLoading', value: flag })

export const setBoiler = (boiler) => store.dispatch({ type: 'newProduct', boiler });

export const setSpareparts = (spareparts) => {
  return store.dispatch({ type: 'setSpareparts', value: spareparts });
};

export const setProductWithSpareparts = (product) => {
  return store.dispatch({ type: 'setProductWithSpareparts', value: product });
};

export const selectItemOnTree = (item) => {
  return store.dispatch({ type: 'selectItemOnTree', value: item });
};

export const choosePartFromSvg = (item) => {
  return store.dispatch({ type: 'choosePartFromSvg', value: item });
};

export const setLanguage = (newLanguage) => {
  return {
    type: 'setLanguage',
    value: newLanguage
  };
};

export const showMsg = (type, msg) => {
  let msg_obj = {
    type: 'showMsg',
    value: {
      msg: msg,
      type: type
    }
  };
  store.dispatch(msg_obj);
};

export const getDepartments = () => {
  if (store.getState().appState.departments.length === 0) {
    axios
      .get('api/department', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        store.dispatch({
          type: 'storeDepartments',
          value: response.data
        });
      })
      .catch((error) => showError(error));
  }
};

export const getWarehouses = () => {
  if (store.getState().appState.warehouses.length === 0) {
    axios
      .get('api/warehouse', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        store.dispatch({
          type: 'storeWarehouses',
          value: response.data
        });
      })
      .catch((error) => showError(error));
  }
};

export const getActionList = (actionGroupId = 'BOILER') => {
  let actionList = store.getState().productInfo.actionList;
  if (
    actionList.length === 0 ||
    (actionList.length > 0 &&
      actionList.Locale !== store.getState().appState.preferences.language)
  ) {
    axios
      .get('api/help/actions/', {})
      .then((response) => {
        store.dispatch({
          type: 'setActionList',
          value: response.data.ActionGroups[actionGroupId].Actions
        });
      })
      .catch((error) => showError(error));
  }
};

export const getTranslations = () => {
  axios({ url: '/api/help/translate/' })
    .then((response) =>
      store.dispatch({ type: 'setLocaleDict', value: response.data })
    )
    .catch((error) => showError(error));
};

export const getLanguages = () => {
  axios({ url: '/api/help/languages/' })
    .then((response) => {
      store.dispatch({ type: 'setLanguages', value: response.data });
    })
    .catch((error) => showError(error));
};

export const changeLanguageAndGetLegalDocuments = async (language_code) => {
  await changeLanguage(language_code);
  await getLegalDocuments();
  console.log('done');
};

export const changeLanguage = (language_code) => {
  const config = {
    headers: {
      iBoilerForceLanguage: language_code
    },
    url: 'api/help/translate/'
  };

  axios(config)
    .then((response) => {
      store.dispatch({ type: 'setLocaleDict', value: response.data });
      store.dispatch({ type: 'setLanguage', value: language_code });
    })
    .catch((error) => showError(error));
};

export const getLegalDocuments = () => {
  axios({ url: '/api/legal_documents/' })
    .then((response) => {
      store.dispatch({
        type: 'storeLegalDocuments',
        value: response.data
      });
    })
    .catch((error) => showError(error));
};

export const clearDashboardProducts = () => {
  store.dispatch({ type: 'clearDashboardProducts' });
};

export const logout = () => {
  store.dispatch({ type: 'password_changed' });
  store.dispatch({ type: 'logout' });
  clearDashboardProducts();
  store.dispatch(clearCart());

  axios({
    url: '/api/auth/logout',
    method: 'delete'
  })
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const loadProductHistory = (boilerId) => {
  axios({ url: 'api/product/' + boilerId })
    .then((response) => {
      setBoiler(response.data);
      toggleImagesLoading(false)
    })
    .catch((error) => {
      showError(error);
      history.push('/dashboard');
    });
};

export const getProductTypeRevision = (model, group, currentGroup) => {
  store.dispatch({
    type: 'selectItemOnTree',
    value: {
      revision: model,
      group: group,
      currentGroup: currentGroup
    }
  });
};

export const getUserLoadHistory = () => {
  if (store.getState().spareparts.userLoadHistory.length > 0) return;
  axios({ url: '/api/user/my_load_history' })
    .then((response) => {
      store.dispatch({
        type: 'setUserLoadHistory',
        value: response.data
      });
    })
    .catch((error) => showError(error));
};

export const fetchAllSpareparts = () => {
  if (store.getState().spareparts.groupedProductTypes.length > 0) return;
  axios({ url: 'api/product_type/spareparts' })
    .then((response) => setSpareparts(response.data))
    .catch((error) => showError(error));
};

export const getProductWithSpareparts = (sn) => {
  axios({ url: 'api/product/spareparts/' + sn })
    .then((response) => setProductWithSpareparts(response.data))
    .catch((error) => showError(error));
};

export const toggleLoadingDashboardProducts = (flag) => {
  store.dispatch({ type: 'setLoadingDashboardProducts', value: flag });
};

export const toggleLoadingDashboardProductsWithoutFilters = (flag) => {
  store.dispatch({
    type: 'setLoadingDashboardProductsWithoutFilters',
    value: flag
  });
};

export const setDashboardProducts = (products) => {
  store.dispatch({ type: 'storeDashboardProducts', value: products });
};

export const setDashboardProductsWithoutFilters = (products) => {
  store.dispatch({
    type: 'storeDashboardProductsWithoutFilters',
    value: products
  });
};

export const saveFilterAndGetProducts = (filter) => {
  if (Object.keys(filter).length) {
    store.dispatch({ type: 'addDashboardProductFilter', value: filter });
    fetchDashboardProducts(parseDashboardFilters());
  } else {
    fetchDashboardProductsWithoutFilters();
  }
};

const parseDashboardFilters = () => {
  let filters = Object.assign(
    {},
    store.getState().dashboard.dashboardProductsFilters
  );
  Object.keys(filters).forEach((key) => {
    if (Array.isArray(filters[key])) {
      filters[key] = filters[key]
        .map((item) => {
          return !!item.value ? item.value : item;
        })
        .join(',');
    }
  });
  return filters;
};

export const fetchDashboardProducts = (params) => {
  axios({
    url: 'api/user/dashboard/products',
    params: params,
    method: 'get'
  })
    .then((response) => {
      setDashboardProducts(response.data);
    })
    .catch((error) => {
      showError(error);
      toggleLoadingDashboardProducts(false);
    });
};

export const fetchDashboardProductsWithoutFilters = () => {
  axios({
    url: 'api/user/dashboard/products',
    method: 'get'
  })
    .then((response) => {
      setDashboardProducts(response.data);
    })
    .catch((error) => {
      showError(error);
      toggleLoadingDashboardProducts(false);
    });
};

export const saveBoilerId = (id) => {
  return store.dispatch({ type: 'save_boiler_id', value: id });
};

export const closePartDetails = () => {
  return store.dispatch({ type: 'close_parts_details' });
};

export const extendWarranty = (token) => {
  store.dispatch({ type: 'try_to_extend_warranty' });
  axios
    .post(`api/product/action/warranty/accept/${token}`)
    .then((response) => {
      store.dispatch({
        type: 'warranty_extension_success',
        payload: response.data
      });
    })
    .catch((error) => {
      store.dispatch({
        type: 'warranty_extension_error',
        payload: error
      });
      showError(error);
    });
};

export const serviceSupportIntercept = (token) => {
  store.dispatch({ type: 'try_to_service_support_intercept' });
  axios
    .post(`api/product/service_support_intercept/accept/${token}`)
    .then((response) => {
      store.dispatch({
        type: 'service_support_intercept_success',
        payload: response.data
      });
    })
    .catch((error) => {
      store.dispatch({
        type: 'service_support_intercept_error',
        payload: error
      });
      showError(error);
    });
};

export const partListDescription = (description) => {
  return store.dispatch({ type: 'partListDescription', value: description });
};

export const changeColumnVisibility = (data) => {
  return store.dispatch({ type: 'changeColumnVisibility', value: data });
};

export const mapPosition = (data) => {
  return store.dispatch({ type: 'mapPosition', value: data });
};

export const mapLoaded = (data) => {
  return store.dispatch({ type: 'mapLoaded', value: data });
};

export const mapHeightSize = (data) => {
  return store.dispatch({ type: 'mapHeightSize', value: data });
};

export const mapCenterView = (data) => {
  return store.dispatch({ type: 'mapCenterView', value: data });
};

export const getIotSensors = async (serialNumber) => {
  return await new Promise((resolve) => {
    axios
      .get(`api/product/${serialNumber}/iot_sensors`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        showError(error);
        resolve([]);
      });
  });
};

export const toggleIotSensor = async (id, checked, serial_number) => {
  return await new Promise((resolve) => {
    axios
      .post(`api/product/${serial_number}/iot_sensors`, {
        id: id,
        selected: checked
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.warn(error);
      });
  });
};

export const registerIotDeviceRequest = async (
  serial_number,
  deviceToRegister
) => {
  return await new Promise((resolve) => {
    axios
      .post(`api/product/${serial_number}/iot_registration_request`, {
        canceled: false,
        web_server_serial_number: deviceToRegister
      })
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        console.warn(error);
      });
  });
};

export const getSparepartGroupsForRevision = async (
  product_type_revision_id
) => {
  return await new Promise((resolve) => {
    axios
      .get(`api/product_type_revision/${product_type_revision_id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => showError(error));
  });
};

export const editUserProfile = (userData) => ({
  type: 'user_edit',
  value: userData
});

// Spareparts categories path reducer

export const addToPath = (path) => {
  return store.dispatch({ type: 'addToPath', payload: path });
};

// Spareparts search reducer

export const changeSearchValue = (value) => {
  return store.dispatch({ type: 'typePrompt', payload: value });
};

export const clearSearchValue = () => {
  return store.dispatch({ type: 'clearPrompt' });
};
