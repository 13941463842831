import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import MapPin from '../../../assets/svg/mapPin';
import MapPinWithText from '../../../assets/svg/mapPinWithText';
import { determineMapMarkerIcon } from 'utils/map';
import history from '../../../history';
import { v4 as uuidv4 } from 'uuid';
import { MAX_ZOOM } from 'utils/const';

const Marker = (props) => {

  const {
    toggleTooltip,
    pointThePin,
    lat,
    lng,
    zoom,
    isCluster,
    pointCount,
    cluster,
    tooltipOpen,
    panZoomOnClusterClick,
    resetTooltipsArray,
    getClusterElements,
    toggleScrollLock
  } = props;

  const ref = useRef();

  function handleClickOutside(event) {
    if (event.target.tagName.toLowerCase() === 'a') {
      history.push(
        'product_info/' + event.target.href.split('product_info/')[1]
      );
    }
    if (!ref.current || (ref.current && !ref.current.contains(event.target))) {
      resetTooltipsArray();
      toggleScrollLock(true);
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }

  const clickPin = (e) => {
    if (!isCluster) {
      document.addEventListener('mousedown', handleClickOutside);
      toggleTooltip(cluster.index);
      pointThePin(cluster.properties.boiler);
    } else {
      panZoomOnClusterClick(cluster.id, lat, lng);
      if (zoom === MAX_ZOOM) {
        document.addEventListener('mousedown', handleClickOutside);
        toggleScrollLock(false);
        toggleTooltip(cluster.index);
      }
    }
  };

  const pinContent = () => {
    if (isCluster) {
      return (
        <div className="whole-pin">
          <MapPinWithText
            key="2"
            className="custom-aic-icon-mini"
            color={'green'}
            number={pointCount}
          />
          {tooltipOpen && (
            <div className="map-tooltip">
              <div className="tooltip-list">
                {getClusterElements(cluster.id).map((element) => {
                  if (!element.isCluster) {
                    return (
                      <div
                        className="tooltip-list-item"
                        key={uuidv4()}>
                        <div className="status-dot td status-cell">
                          <div
                            className="dot"
                            style={{
                              background: element.properties.boiler.status.color
                            }}
                          />
                        </div>
                        <Link
                          to={
                            'product_info/' +
                            element.properties.boiler.serial_number
                          }
                          className="bolded link">
                          {element.properties.boiler.serial_number}
                        </Link>
                      </div>
                    );
                  }
                })}
              </div>
              <div className="tooltip-arrow" />
            </div>
          )}
        </div>
      );
    } else {
      return (
        <>
          <MapPin
              color={determineMapMarkerIcon(
                  cluster.properties.boiler.status
              )}
          />
          {tooltipOpen && (
            <div className="map-tooltip">
              <div className="bolded link">
                <Link
                  to={
                    'product_info/' +
                    cluster.properties.boiler.serial_number
                  }
                  className="aic-link">
                  {cluster.properties.boiler.serial_number}
                </Link>
              </div>
              <div className="tooltip-location">
                {cluster.properties.boiler.location}
              </div>
              <div className="tooltip-arrow" />
            </div>
          )}
        </>
      );
    }
  };

  return (
    <div
      ref={ref}
      onClick={(e) => {
        clickPin(e);
      }}
      style={{
        cursor: 'pointer'
      }}
      className="test-pin">
      {pinContent()}
    </div>
  );
};

export default Marker;
