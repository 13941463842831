import translate from '../../../../../utils/translate';
import React from 'react';

export const LastActionInfo = (props) => {
  if (props.last_value) {
    return (
      <div className="last-value">
        <div>{translate('In previous action')}:</div>{' '}
        <div
          className="bolded"
          style={{ padding: '0px 8px' }}>
          {props.last_value.displayed_value}
        </div>{' '}
        {translate('by')} {props.last_value.author}
      </div>
    );
  } else return null;
};
