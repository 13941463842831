import React from 'react';
import './searchResultsTableHeader.scss';
import translate from '../../../../../../utils/translate';

const SearchResultsTableHeader = () => {
  return (
    <thead>
      <tr>
        <th>{translate('Spare part')}</th>
        <th>{translate('index')}</th>
        <th>
          {translate('Suitable for')}/{translate('Used in')}
        </th>
        <th>{translate('Add to shopping list')}</th>
      </tr>
    </thead>
  );
};

export default SearchResultsTableHeader;
