const dashboardInitialState = {
  enableTooltip: false,
  loadingDashboardProducts: true,
  dashboardProducts: {},
  dashboardProductsFilters: {},
  mapLoaded: false,
  mapHeightSize: 1,
  dashboardColumns: [
    {
      id: 0,
      isVisible: true,
      isDisabled: true,
      minWidth: '40px',
      width: '40px',
      maxWidth: '40px',
      name: '',
      longName: 'Check list',
      type: 'checkbox',
      apiField: '',
      values: '',
      className: 'chekbox_cell',
      formFields: ['']
    },
    {
      id: 1,
      isVisible: true,
      isDisabled: true,
      minWidth: '70px',
      width: '70px',
      maxWidth: '70px',
      name: '',
      longName: 'Status',
      type: 'multicheckbox',
      apiField: 'status',
      values: 'statuses',
      className: 'status_cell',
      formFields: ['status']
    },
    {
      id: 2,
      isVisible: true,
      isDisabled: true,
      minWidth: '100px',
      width: '100px',
      maxWidth: '100px',
      name: 'Device',
      longName: 'Device',
      type: 'string',
      apiField: 'name',
      values: '',
      className: 'family_cell',
      placeholder: 'eg. Nesta,',
      formFields: ['name']
    },
    {
      id: 3,
      isVisible: true,
      isDisabled: true,
      minWidth: '100px',
      width: '100px',
      maxWidth: '100px',
      name: 'Power output',
      longName: 'Power output',
      type: 'string',
      apiField: 'value',
      values: '',
      className: 'power_cell',
      placeholder: 'eg 120,',
      hint: 'www_dashboard_power_hint',
      formFields: ['value']
    },
    {
      id: 4,
      isVisible: true,
      isDisabled: true,
      minWidth: '150px',
      width: '150px',
      maxWidth: '150px',
      name: 'Serial number',
      longName: 'Serial number',
      type: 'string',
      apiField: 'sn',
      values: '',
      className: 'sn_cell',
      placeholder: 'eg W5.F-066923,',
      formFields: ['sn']
    },
    {
      id: 5,
      isVisible: true,
      isDisabled: false,
      minWidth: '130px',
      width: '130px',
      maxWidth: '130px',
      name: 'Index',
      longName: 'Index',
      type: 'string',
      apiField: 'index',
      values: '',
      className: 'index_cell',
      placeholder: 'eg NSC120.00,',
      formFields: ['index']
    },
    {
      id: 6,
      isVisible: true,
      isDisabled: false,
      minWidth: '120px',
      width: '200px',
      maxWidth: '250px',
      name: 'Location',
      longName: 'Location',
      type: 'location',
      apiField: 'location',
      values: '',
      className: 'location_cell',
      placeholder: 'eg London,',
      formFields: ['location', 'store']
    },
    {
      id: 7,
      isVisible: true,
      isDisabled: false,
      minWidth: '140px',
      width: '140px',
      maxWidth: '140px',
      name: 'Start of warranty',
      longName: 'Start of warranty',
      type: 'date',
      apiField: 'wtyStart',
      values: '',
      className: 'date_cell',
      placeholder: 'YYYY-MM-DD',
      formFields: ['wtyStartFrom', 'wtyStartTo']
    },
    {
      id: 8,
      isVisible: true,
      isDisabled: false,
      minWidth: '140px',
      width: '140px',
      maxWidth: '140px',
      name: 'End of warranty',
      longName: 'End of warranty',
      type: 'date',
      apiField: 'wtyEnd',
      values: '',
      className: 'date_cell',
      placeholder: 'YYYY-MM-DD',
      formFields: ['wtyEndFrom', 'wtyEndTo']
    },
    {
      id: 9,
      isVisible: true,
      isDisabled: false,
      minWidth: '140px',
      width: '140px',
      maxWidth: '140px',
      name: 'Installation',
      longName: 'Installation',
      type: 'date',
      apiField: 'installDate',
      values: '',
      className: 'date_cell',
      placeholder: 'YYYY-MM-DD',
      formFields: ['installDateFrom', 'installDateTo']
    },
    {
      id: 10,
      isVisible: true,
      isDisabled: false,
      minWidth: '140px',
      width: '140px',
      maxWidth: '140px',
      name: 'Commissioning',
      longName: 'Commissioning',
      type: 'date',
      apiField: 'launchDate',
      values: '',
      className: 'date_cell',
      placeholder: 'YYYY-MM-DD',
      formFields: ['launchDateFrom', 'launchDateTo']
    },
    {
      id: 11,
      isVisible: true,
      isDisabled: false,
      minWidth: '140px',
      width: '140px',
      maxWidth: '140px',
      name: 'Next inspection',
      longName: 'Inspection',
      type: 'inspection',
      apiField: 'nextInspectionDate',
      values: '',
      className: 'date_cell',
      formFields: [
        'nextInspectionDateFrom',
        'nextInspectionDateTo',
        'lastInspectionDateFrom',
        'lastInspectionDateTo',
        'inspectionOverdue'
      ]
    },

    {
      id: 12,
      isVisible: true,
      isDisabled: false,
      minWidth: '120px',
      width: '120px',
      maxWidth: '120px',
      name: 'Technician',
      longName: 'Technician',
      type: 'string',
      apiField: 'technician',
      values: '',
      className: 'service_support_cell',
      placeholder: 'eg. John Wayne,',
      formFields: ['technician']
    },
    {
      id: 13,
      isVisible: true,
      isDisabled: false,
      minWidth: '140px',
      width: '140px',
      maxWidth: '140px',
      name: 'Last action',
      longName: 'Last action',
      type: 'lastAction',
      apiField: 'lastAction',
      values: '',
      className: 'last_action_cell',
      formFields: [
        'lastActionDateFrom',
        'lastActionDateTo',
        'lastActionType',
        'lastActionTechnician'
      ]
    },
    {
      id: 14,
      isVisible: true,
      isDisabled: false,
      minWidth: '120px',
      width: '120px',
      maxWidth: '120px',
      name: 'Department',
      longName: 'Department',
      type: 'dept',
      apiField: 'department',
      values: 'departments',
      className: 'department_cell',
      formFields: ['department']
    }
  ]
};

export const dashboardReducer = (state = dashboardInitialState, action) => {
  switch (action.type) {
    case 'toggleTooltip':
      return {
        ...state,
        enableTooltip: action.value
      };

    case 'setLoadingDashboardProducts':
      return {
        ...state,
        loadingDashboardProducts: action.value
      };

    case 'setLoadingDashboardProductsWithoutFilters':
      return {
        ...state,
        loadingDashboardProductsWithoutFilters: action.value
      };

    case 'clearDashboardProducts':
      return {
        ...state,
        dashboardProducts: {}
      };

    case 'storeDashboardProducts':
      let dashboardProducts = {
        ...action.value,
        allProducts: action.value.my_devices
          .concat(action.value.service_supported_devices)
          .concat(action.value.leader_department_supporting_devices)
      };
      return {
        ...state,
        loadingDashboardProducts: false,
        dashboardProducts: dashboardProducts
      };

    case 'storeDashboardProductsWithoutFilters':
      let dashboardProductsWithoutFilters = {
        ...action.value,
        allProducts: action.value.my_devices
          .concat(action.value.service_supported_devices)
          .concat(action.value.leader_department_supporting_devices)
      };
      return {
        ...state,
        loadingDashboardProductsWithoutFilters: false,
        dashboardProductsWithoutFilters: dashboardProductsWithoutFilters
      };

    case 'addDashboardProductFilter':
      let dashboardFilters = Object.assign({}, state.dashboardProductsFilters);
      for (let key in action.value) {
        if (action.value.hasOwnProperty(key)) {
          dashboardFilters[key] = action.value[key];
        }
      }
      return {
        ...state,
        loadingDashboardProducts: true,
        dashboardProductsFilters: dashboardFilters
      };
    case 'changeColumnVisibility':
      const dashboardColumnsArray = [...state.dashboardColumns];
      const indexOfCol = dashboardColumnsArray.findIndex(
        (col) => col.longName === action.value.name
      );
      dashboardColumnsArray[indexOfCol].isVisible = action.value.isChecked;
      return {
        ...state,
        dashboardColumns: dashboardColumnsArray
      };
    case 'mapPosition':
      return {
        ...state,
        mapPosition: action.value
      };
    case 'mapLoaded':
      return {
        ...state,
        mapLoaded: action.value
      };
    case 'mapHeightSize':
      return {
        ...state,
        mapHeightSize: action.value
      };
    case 'mapCenterView':
      return {
        ...state,
        mapCenterView: action.value
      };
    default:
      return state;
  }
};
