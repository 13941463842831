import React, { useRef, useState } from 'react';
import translate from '../../utils/translate';
import { BiImageAdd } from 'react-icons/bi';
import { HistorySpiner } from '../Spinners/Spinners';
import TravellerImage from './TravellerImage/TravellerImage';
import TravellerUploader from './TravellerUploader/TravellerUploader';
import { FiEdit } from 'react-icons/fi';
import axios from 'axios';
import { loadProductHistory, showError, toggleImagesLoading } from '../../actions/actions';
import { useSelector } from 'react-redux';
import { yieldImageUploading } from '../../utils/yeldImageUploading';
import { useLocation } from 'react-router-dom';
import store from '../../store';

const TravellerPhoto = ({ actionInfo, files }) => {
  const isLoading = useSelector(state => state.appState.isImagesLoading);
  const location = useLocation();
  const boilerId = location.pathname.split('/').at(-1)

  const [images, setImages] = useState(files);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [isPhotoUploading, setIsPhotoUploading] = useState(false);

  const paramRef = useRef(null);

  const openImageUploader = () => setIsPhotoUploading(true);

  const closeImageUploader = () => {
    setImages(prevState => (prevState.filter(img => !img.file)))
    setIsPhotoUploading(false);
  };

  const handleDropPhoto = (photo) => setImages(photo);

  const handleImageUpload = async () => {
    if (images.some(el => !el?.file_cat)) {
      return showError(translate('Define category for new images'))
    }

    toggleImagesLoading(true);
    paramRef.current.scrollIntoView({ behavior: 'smooth', block: "start"})

    if (imagesToDelete.length) await removeImages();

    if (images.some(image => image?.file)) await yieldImageUploading(images, actionInfo.id);

    await loadProductHistory(boilerId)
    setIsPhotoUploading(false);
    setImagesToDelete([]);
  };

  const handleImageRemove = (image) => {
    setImagesToDelete(prevState => [...prevState, image])
  }

  const handleImageReturn = (image) => {
    setImagesToDelete(prevImages => prevImages.filter(item => item.file_url !== image.file_url))
  }

  const removeImages = async () => {
    for (let i = 0; i < imagesToDelete.length; i++) {
      const pictureItem = imagesToDelete[i];
      const lastIdx = imagesToDelete.length - 1;
      const data = JSON.stringify(pictureItem)

      try {
        const res = await axios.delete('api/product/delete_file/' + actionInfo.id, { data })
        if (i === lastIdx && res.status === 200) {
          store.dispatch({
            type: 'showMsg', value: {
              msg: translate("Images deleted"), type: 'success'
            }
          });
        }
      } catch (e) {
        showError(e);
        if (i !== lastIdx) continue;
        else break;
      }
    }
  };

  const uploaderBlock = (
    <TravellerUploader
      onClose={closeImageUploader}
      onDrop={handleDropPhoto}
      onUpload={handleImageUpload}
      onRemove={handleImageRemove}
      onReturnRemove={handleImageReturn}
      files={images}
      imagesToDelete={imagesToDelete}
      actionType={actionInfo.type}
    />
  );

  const imageBlock = images?.map(image =>
    <TravellerImage
      key={image["file_url"]}
      image={image["file_url"]}
      title={image["file_cat"]}
    />
  );

  const addImageIcon = (
    <BiImageAdd
      onClick={openImageUploader}
      className="history-action-add-image"
    />
  );

  const loadingSpinner = (
    <div style={{ width: '100%', padding: '1rem', display: 'grid', placeItems: 'center' }}>
      <HistorySpiner />
    </div>
  )

  const blockToRender = isLoading ? (
    loadingSpinner
  ) : isPhotoUploading ? (
    uploaderBlock
  ) : images.length ? (
    imageBlock
  ) : (
    addImageIcon
  );

  return (
    <div className="history-action text-center" ref={paramRef}>
      <div className='history-action--title'>
        <p
          className="history-action-name">
          {translate('service_file_attachment')}
        </p>

        {images.length > 0 && !isPhotoUploading && <FiEdit
          onClick={openImageUploader}
          className='history-action-edit-image'
        />}
      </div>
      <div className='history-action--body'>
        {blockToRender}
      </div>
    </div>
  );
};

export default TravellerPhoto;
