import React from 'react';
import Tooltip from 'react-tooltip-lite';
import translate from '../../utils/translate';
import './SuitableForCell.scss';
import { v4 as uuidv4 } from 'uuid';

const suitableForRow = (rev) => {
  return (
    <div
      className="suitable-for-row"
      key={uuidv4()}>
      <div className="family">
        {rev.family} {rev.subfamily} {rev.model}
      </div>
      <div className="index">({rev.product_index})</div>
    </div>
  );
};

const SuitableForCell = ({ sparepart }) => {
  if (!sparepart.suitable_for) return null;
  if (sparepart.suitable_for.length < 3) {
    return sparepart.suitable_for.map((rev) => {
      return suitableForRow(rev);
    });
  }
  if (sparepart.suitable_for.length >= 3) {
    return (
      <div className="suitable-for-more-row">
        <div>{suitableForRow(sparepart.suitable_for[0])}</div>
        <Tooltip
          content={
            <div className="spareparts-search-tooltip-content">
              {sparepart.suitable_for
                .sort(({ power: b }, { power: a }) => b - a)
                .map((rev) => {
                  return suitableForRow(rev);
                })}
            </div>
          }
          arrowSize={4}
          tagName="div"
          direction="auto"
          className="spareparts-search-tooltip"
          mouseOutDelay={20}>
          {translate('See all')} {sparepart.suitable_for.length}{' '}
          {translate('products')}
        </Tooltip>
      </div>
    );
  }
};

export default SuitableForCell;
