import React from 'react';
import history from '../../../../../history';

const gotoProductParts = (product) => {
  history.push('/dashboard/' + product.serial_number + '/parts');
};

const RecentItem = ({ product }) => {
  return (
    <div
      className="recent-item"
      onClick={() => gotoProductParts(product)}>
      {product.family} {product.subfamily}
      <div className="vertical-separtor"></div>
      {product.model}
      <div className="vertical-separtor"></div>
      <span className="model-index">{product.serial_number}</span>
    </div>
  );
};

export default RecentItem;
