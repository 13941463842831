import React from 'react';

const InfoBoldIcon = (c) => {
  let cls = `custom-aic-icon  ${c === {} ? '' : c.className}`;
  return (
    <svg
      className={cls}
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7649 42.4756C33.3629 42.4756 42.7649 33.0736 42.7649 21.4756C42.7649 9.87761 33.3629 0.475586 21.7649 0.475586C10.1669 0.475586 0.764893 9.87761 0.764893 21.4756C0.764893 33.0736 10.1669 42.4756 21.7649 42.4756ZM19.4836 17.4756V31.4756H24.0149V17.4756H19.4836ZM19.9211 10.46C19.4732 10.8975 19.2493 11.4548 19.2493 12.1318C19.2493 12.8193 19.4784 13.3818 19.9368 13.8193C20.4055 14.2568 21.0201 14.4756 21.7805 14.4756C22.5409 14.4756 23.1503 14.2568 23.6086 13.8193C24.0774 13.3818 24.3118 12.8193 24.3118 12.1318C24.3118 11.4548 24.0826 10.8975 23.6243 10.46C23.1659 10.0225 22.5514 9.80371 21.7805 9.80371C20.9993 9.80371 20.3795 10.0225 19.9211 10.46Z"
        fill="#32343A"
      />
    </svg>
  );
};

export default InfoBoldIcon;
