import React, { useState, useEffect, useRef } from 'react';
import TooltipFilter from './TooltipFilter';
import { ReactComponent as Filter_list } from 'assets/svg/filter_list.svg';
import translate from 'utils/translate';
import { useSelector } from 'react-redux';
import { mapHeightSize } from 'actions/actions';

const DashboardTooltip = ({
  filterRow,
  checked,
  handleChange,
  openFilterName,
  setOpenFilterName,
  filterWindowWidth,
  filterWindowOffsetLeft,
  mapPreviousSize,
  allProducts
}) => {
  // useState
  const [isFilterActive, setIsFilterActive] = useState(0);
  const [isTooltipClosing, setIsTooltipClosing] = useState(false);

  // refs
  const myRef = useRef();
  const buttonRef = useRef(null);

  // redux state
  const dashboardProductsFilters = useSelector(
    (state) => state.dashboard.dashboardProductsFilters
  );
  //check if currently filter is open
  const isFilterOpen = filterRow.longName === openFilterName;

  const closeFilterSoftly = () => {
    setIsTooltipClosing(true);
    //return map to previous size when closing filters
    if (mapPreviousSize > 0) {
      mapHeightSize(mapPreviousSize);
    }
    setTimeout(() => {
      setOpenFilterName('');
      setIsTooltipClosing(false);
    }, 1000); // timeout should be equal to transition duration

    //clearing timeout will cause incorrect work of function, so it was removed
  };

  // set is filter active
  useEffect(() => {
    // get dashboard Products Filters
    const filtersArrayOfOneFilter = filterRow.formFields;
    let numberOfFilters = 0;

    filtersArrayOfOneFilter.forEach((i) => {
      let dashboardProductsFiltersArray = dashboardProductsFilters[i];

      if (!dashboardProductsFiltersArray) {
        return;
      }

      // if string => check if something is added, replace coma to space and replace multiple spaces for only one
      if (typeof dashboardProductsFiltersArray === 'string') {
        dashboardProductsFiltersArray = dashboardProductsFiltersArray
          .trim()
          .replace(/,/g, ' ')
          .replace(/ +(?= )/g, '')
          .split(' ');
      }

      // if only one empty element of array
      if (
        dashboardProductsFiltersArray?.length === 1 &&
        dashboardProductsFiltersArray[0].length === 0
      ) {
        dashboardProductsFiltersArray = [];
      }

      numberOfFilters += dashboardProductsFiltersArray?.length;
    });

    setIsFilterActive(numberOfFilters);
  }, [dashboardProductsFilters]);

  return (
    <div className={`td ${filterRow.className}`}>
      {/* description */}
      <div
        className={`filter-name ${isFilterActive ? 'filter-name-active' : ''}`}
      >
        {translate(filterRow.name)}
      </div>

      {/* description for Status (multicheckbox) => only dot  */}
      {filterRow.type === 'multicheckbox' && (
        <div className="dot" style={{ background: 'gray' }} ref={myRef} />
      )}

      {/* description for Check lisk (checkbox) => only checkbox */}
      {filterRow.type === 'checkbox' && (
        <label className="customcheck chekbox-cell-center">
          <input
            type="checkbox"
            checked={checked.length}
            onChange={(e) => handleChange(e)}
          />
          <span className="checkmark"> </span>
        </label>
      )}

      {/* filter icon  */}
      {filterRow.type !== 'checkbox' && (
        <div
          data-cy={filterRow.longName}
          className={`header-filter-icon ${
            isFilterActive ? 'header-filter-icon-active' : ''
          } ${isFilterOpen ? 'header-filter-icon-open' : ''}`}
          onClick={() =>
            isFilterOpen
              ? closeFilterSoftly()
              : setOpenFilterName(filterRow.longName)
          }
          ref={buttonRef}
        >
          <Filter_list />
          <span className="header-filter-text">
            {isFilterActive ? isFilterActive : ''}
          </span>
        </div>
      )}

      <div
        style={{
          width: filterWindowWidth + 'px',
          left: filterWindowOffsetLeft + 'px'
        }}
        className={`settings__filtersList__rows ${
          isFilterOpen && !isTooltipClosing
            ? 'settings__filtersList_visible'
            : ''
        }`}
      >
        {isFilterOpen && (
          <TooltipFilter
            toggleTip={closeFilterSoftly}
            filterRow={filterRow}
            dashboardProductsFilters={dashboardProductsFilters}
            allProducts={allProducts}
          />
        )}
      </div>
    </div>
  );
};

export default DashboardTooltip;
