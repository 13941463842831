import React from 'react';
import translate from 'utils/translate';
import RecentItem from './RecentItem';

const AvailableSerialNumbersWithSearch = ({
  searchPhrase,
  userLoadHistory,
  availableSNRef
}) => {
  const matchFound = (product) =>
    product?.serial_number.includes(searchPhrase.toUpperCase());

  const isSNMatched =
    searchPhrase && userLoadHistory.some((prod) => matchFound(prod));

  return (
    <div ref={availableSNRef}>
      {isSNMatched && (
        <div className="recent-products">
          <div className="recent-title">
            {translate('Available serial numbers')}:
          </div>
          <div className="recent-items">
            {userLoadHistory.map((product, i) => {
              if (searchPhrase && matchFound(product)) {
                return (
                  <RecentItem
                    key={i}
                    product={product}
                  />
                );
              }
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default AvailableSerialNumbersWithSearch;
