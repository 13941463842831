import React from 'react';
import translate from 'utils/translate';
import { useSelector } from 'react-redux';
import './sparepartsWelcomePage.scss';
import sparepartsImage from '../../../assets/img/sparepartsBG.png';
import { Link } from 'react-router-dom';

const SparepartsWelcomePage = () => {
  const user = useSelector((state) => state.user);
  const language = useSelector((state) => state.appState);

  return (
    <div className="welcome-spareparts">
      <div className="welcome-spareparts-container">
        <div className="welcome-spareparts-menu">
          <div className="welcome-spareparts-card">
            <div className="welcome-spareparts-card-left-inside">
              <div className="welcome-spareparts-header">
                <span>
                  {translate('Welcome back to Spare Parts')}, {user.first_name}
                </span>
              </div>
              <div className="welcome-spareparts-message">
                <span>
                  {translate(
                    'You can browse all available spare parts and their technical drawings.'
                  )}
                </span>
              </div>
              <div className="spareparts-hello-buttons">
                <Link to="/spareparts/categories">
                  <button className="contact-btn aic-btn">
                    {translate('Categories')}
                  </button>
                </Link>
                <Link to="/spareparts/search">
                  <button className="contact-btn aic-btn">
                    {translate('Search')}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="welcome-spareparts-photo-container">
          <div
            className="welcome-spareparts-photo-container-image"
            style={{
              backgroundImage: `url(${sparepartsImage})`,
              position: 'fixed'
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SparepartsWelcomePage;
