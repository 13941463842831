import React, { useImperativeHandle, useState, useRef, useEffect } from 'react';
import '../spareparts.scss';

import {
  INITIAL_VALUE,
  ReactSVGPanZoom,
  zoomOnViewerCenter
} from 'react-svg-pan-zoom';

const ViewerCanvas = React.forwardRef((props, ref) => {
  const [tool, setTool] = useState('auto');
  const [value, setValue] = useState(INITIAL_VALUE);

  const Viewer = useRef(null);

  useImperativeHandle(ref, () => ({
    centerOnMap(x, y) {
      // Desired center of the viewer
      const viewerCenterX = value.viewerWidth / 2;
      const viewerCenterY = value.viewerHeight / 2;

      // Compute the point's position in the viewer's coordinate system
      const pointInViewerX = (x / value.SVGWidth) * value.viewerWidth;
      const pointInViewerY = (y / value.SVGHeight) * value.viewerHeight;

      // Compute the translation needed to place the desired point at the center of the viewer
      const desiredE = viewerCenterX - pointInViewerX * value.a;
      const desiredF = viewerCenterY - pointInViewerY * value.d;

      setValue((prevValue) => ({
        ...prevValue,
        e: desiredE,
        f: desiredF
      }));
    }
  }));

  useEffect(() => {
    //useEffect for svg circles to properly fill in color after click
    const moveSelectedClassToParentG = () => {
      document
        .querySelectorAll('polyline.selected-at-tree-arrow')
        .forEach(function (polyline) {
          let parentGroup = polyline.closest('g');
          if (parentGroup) {
            parentGroup.classList.add('selected-at-tree-arrow');
          }
        });
    };

    // Call the function to move the class
    moveSelectedClassToParentG();
  }, [props.svgcontent]);

  const changeTool = (nextTool) => {
    setTool(nextTool);
  };

  const changeValue = (nextValue) => {
    setValue(nextValue);
  };

  const fitToSvg = () => {
    const viewerWidth = props.width;
    const viewerHeight = props.height;

    // Calculate the scale factors for width and height
    const scaleX = viewerWidth / svgWidth;
    const scaleY = viewerHeight / svgHeight;

    // Choose the smaller scale to fit the SVG within the viewer
    const scale = Math.min(scaleX, scaleY);

    // Calculate the scaled dimensions of the SVG
    const scaledSvgWidth = svgWidth * scale;
    const scaledSvgHeight = svgHeight * scale;

    // Calculate the translations to center the scaled SVG within the viewer
    const translateX = (viewerWidth - scaledSvgWidth) / 2;
    const adjustment = 250;
    const translateY = (viewerHeight - scaledSvgHeight) / 2 - adjustment;

    setValue({
      ...value,
      scale,
      e: translateX,
      f: translateY
    });
  };

  const zoomIn = () => {
    setValue(zoomOnViewerCenter(value, 1.2));
  };

  const zoomOut = () => {
    setValue(zoomOnViewerCenter(value, 0.8));
  };

  let svgHeight = props.svgHeight || 1123.5197;
  let svgWidth = props.svgWidth || 1588.4;
  return (
    <div className="viewer-canvas">
      <div className="svg-controls">
        <div className="svg-control-btn" onClick={() => zoomIn()}>
          +
        </div>
        <div className="svg-control-btn" onClick={() => zoomOut()}>
          -
        </div>
        <div className="svg-control-btn" onClick={() => fitToSvg()}>
          &#8634;
        </div>
      </div>

      <ReactSVGPanZoom
        width={props.width}
        height={props.height}
        background={'red'}
        detectAutoPan={false}
        ref={Viewer}
        tool={tool}
        onChangeTool={(tool) => changeTool(tool)}
        value={value}
        onChangeValue={(value) => changeValue(value)}
        miniatureProps={{ position: 'none' }}
        toolbarProps={{ position: 'none' }}
      >
        <svg viewBox={`0 0 ${svgWidth} ${svgHeight}`}>{props.svgcontent}</svg>
      </ReactSVGPanZoom>
    </div>
  );
});

export default ViewerCanvas;
