import React from 'react';

const MapPinWithText = (c) => {
  let numberoffset = c.number > 9 ? 28 : 32;
  if (c.number > 9) numberoffset = c.number > 99 ? 25 : 28;
  return (
    <div>
      <svg
        width="44"
        height="42"
        viewBox="0 0 48 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g>
          <g
            opacity="0.4"
            filter="url(#filter0_f)">
            <path
              d="M24.1357 32.9753C17.5689 33.3932 10.2393 29.6613 10.9423 26.2579C11.6453 22.8544 19.8538 22.3321 25.7175 25.3176C25.9052 25.4132 26.086 25.5101 26.26 25.6081C26.3385 25.6387 26.4142 25.6724 26.4864 25.7091L36.8653 30.9936C37.7743 31.4565 37.6381 32.1161 36.6201 32.1809L24.1357 32.9753Z"
              fill="blue"
            />
          </g>
          <g
            opacity="0.75"
            filter="url(#filter1_f)">
            <path
              d="M27.4018 31.4708C22.4092 31.21 16.55 29.0366 16.8553 27.5588C17.1606 26.081 23.4013 26.4071 28.0887 28.1457C28.2388 28.2014 28.3836 28.2572 28.5233 28.313C28.5854 28.3321 28.6456 28.3523 28.7033 28.3737L37.0001 31.4512C37.7267 31.7208 37.6676 32.0072 36.8936 31.9667L27.4018 31.4708Z"
              fill="blue"
            />
          </g>
          <g
            opacity="0.6"
            filter="url(#filter2_f)">
            <path
              d="M32.263 31.8737C29.5571 31.6435 26.3375 30.5676 26.4678 29.937C26.598 29.3065 29.9804 29.5942 32.556 30.455C32.6385 30.4826 32.7181 30.51 32.795 30.5374C32.829 30.5471 32.8621 30.5573 32.8938 30.5679L37.4528 32.0914C37.8521 32.2249 37.8268 32.3471 37.4073 32.3114L32.263 31.8737Z"
              fill="blue"
            />
          </g>
        </g>
        <path
          d="M46.0058 16.872C50.3351 9.37332 44.9235 0 36.2648 0C27.6061 0 22.1944 9.37332 26.5237 16.872C26.6624 17.1121 26.8076 17.3444 26.9591 17.5692C26.9941 17.6673 27.0383 17.763 27.0915 17.8553L34.7546 31.1281C35.4257 32.2906 37.1038 32.2906 37.775 31.1281L46.0058 16.872Z"
          fill="#ff5722"
        />
        <text
          x={numberoffset}
          y="18"
          fontFamily="Verdana"
          fontSize="12"
          fill="white">
          {c.number < 1000 ? c.number : '1k+'}
        </text>
      </svg>
    </div>
  );
};

export default MapPinWithText;
