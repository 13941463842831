import React, { useState } from 'react';
import translate from 'utils/translate';
import UniversalActionForm from './UniversalActionForm';
import { useSelector } from 'react-redux';
import './AddAction.scss';
import { v4 as uuidv4 } from 'uuid';
import { actions, UserRoles } from 'utils/const';

const AddActionList = (props) => {
  const [actionType, setActionType] = useState(null);
  const { currentProduct, setOpenModal, setEditingAction } = props;
  const isManager = useSelector((state) =>
    state.user?.roles.includes(UserRoles.MANAGER)
  );
  const isService = useSelector((state) =>
    state.user?.roles.includes(UserRoles.SERVICE)
  );

  const actionList = useSelector((state) => state.productInfo.actionList);

  const selectActionType = (type) => {
    setActionType(type);
    setEditingAction(type);
  };

  if (!actionType) {
    return (
      <div className="add-action-container">
        <div className="actions-list-title">{translate('Action type')}</div>
        {actionList.length > 0 && (
          <div className="actions-list">
            {actionList.map((action) => {
              return (
                <div
                  className="actions-list-item bolded"
                  key={uuidv4()}
                  onClick={() => selectActionType(action.type)}
                  data-cy={action.type}>
                  <div className="in-circle">
                    {actions[action.type] && actions[action.type]()}
                  </div>
                  <div className="actions-list-item-header">
                    {action.displayed_name}
                  </div>
                </div>
              );
            })}
          </div>
        )}

        <div className="actions-list-bottom-section">
          <button
            className="aic-btn-outline"
            onClick={setOpenModal}>
            {translate('Cancel')}
          </button>
        </div>
      </div>
    );
  } else {
    const newActionTypeObj = actionList.find(
      (action) => action.type === actionType
    );
    return (
      <div className="add-action-container">
        <UniversalActionForm
          key={uuidv4()}
          isManager={isManager}
          isService={isService}
          actionType={newActionTypeObj.type}
          productBarcode={currentProduct.serial_number}
          actionList={actionList}
          currentProduct={currentProduct}
          setOpenModal={setOpenModal}
        />
      </div>
    );
  }
};

export default AddActionList;
